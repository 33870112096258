import { JsonRpcProvider } from "@ethersproject/providers";
import { rpcUrls } from "../constants/chain";

export const getDefaultRPCProvider = (chainId: number): JsonRpcProvider => {
  if (!chainId) return null;
  const provider = new JsonRpcProvider(rpcUrls[chainId]);
  return provider;
};

export const getJsonProvider = (networkId: number) => {
  // const provider = new JsonRpcProvider(rpcUrls[networkId]);
  // const token = await getJwt();
  // const token = "eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCJ9.eyJpYXQiOjE2NzI2Mzk0NjQsImV4cCI6MTY3Mjg5ODY2NH0.FSMHMLEsWAygoPLfaTyyYppcquy-J3UyQzpcie7PqBplyAxj86BFHEfYKmOi5E-WWgBiM4bUBWkt2r_nN50NKlAQLWgS0_4rvIn5MGNmT7LFUaCWZT3kuEQf3ADh3YpAKPawTaztHd22fGVYlB5gRmLQoRFPAN2kjW24VSMfbPtTbgNqwV67Jtl4MFVPTmmdujlmcLfy4YAgtDcLmKm_YNTuyXY3YkFaVdJmbX1RD5TmIQWxGfc4DHZ72xFsPUAwwdz4IU1LMMiFVlDf-5NjsQy6Pess2WxIXz938TnvIBLsMX1B58aUo8y6g-kyUkpp7WohRqfciICZ-6bAshs2tw";
  const token = localStorage.getItem("DAPPS_JWT");
  // console.log("local storage jwt: ", token);
  const provider = new JsonRpcProvider({
    url: rpcUrls[networkId],
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });
  return provider;
};

// async function getJwt() {
//   try {
//     const cachedJwt = localStorage.getItem("DAPPS_JWT");
//     if(cachedJwt) {
//       const decodedJwt: any = jwtDecode(cachedJwt);
//       console.log("cached jwt decoded: ", decodedJwt);
//       if(decodedJwt.exp > new Date().getTime() / 1000)
//         return cachedJwt;
//     }

//     // const res = await setuHttp.get("/api/getJwt");
//     // console.log("new jwt: ", res.data.token);
//     // localStorage.setItem("DAPPS_JWT", res.data.token);
//     // return res.data.token;
//   } catch (error) {
//     console.log("Error in getJwt(): ", error);
//   }
// }
