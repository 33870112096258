import { gql } from "@apollo/client";

interface RegisteredApps {
  id: string
  dappId: string
  dappAdmin: string
  dappName: string
  dappCount: number
  networks: number[]
  // categories: [String!]
  // tags: [String!]
  // isVerified: Boolean!
  // subscriberCount: Int!
}

export const REGISTERED_DAPPS_PAGINATED_WITH_COUNT = gql`
  query dapps($offset: Int, $limit: Int) {
    # registeredDapps(first: 1000, orderBy: timestamp, orderDirection: desc) {
    registeredDappsWithCount(
      offset: $offset,
      limit: $limit
    ) {
      registeredApps {
        id
        dappId
        dappAdmin
        dappName
        dappCount
        networks
        categories
        tags
        isVerified
        subscriberCount
      }
      count
    }
  }
`;

export const REGISTERED_DAPPS_BY_CATEGORY = gql`
  query dapps($categories: [String!], $offset: Int, $limit: Int) {
    # registeredDapps(first: 1000, orderBy: timestamp, orderDirection: desc) {
    registeredDappsWithCount(
      where: { categories: $categories },
      offset: $offset,
      limit: $limit
    ) {
      registeredApps {
        id
        dappId
        dappAdmin
        dappName
        dappCount
        networks
        categories
        tags
        isVerified
        subscriberCount
      }
      count
    }
  }
`;

export const REGISTERED_DAPPS_PAGINATED = gql`
  query dapps($offset: Int, $limit: Int) {
    # registeredDapps(first: 1000, orderBy: timestamp, orderDirection: desc) {
    registeredDapps(
      offset: $offset,
      limit: $limit
    ) {
      id
      dappId
      dappAdmin
      dappCount
      dappName
      networks
      subscriberCount
    }
  }
`;

export const REGISTERED_DAPPS = gql`
  query dapps {
    # registeredDapps(first: 1000, orderBy: timestamp, orderDirection: desc) {
    registeredDapps {
      id
      dappId
      dappAdmin
      dappCount
      dappName
      networks
    }
  }
`;

export const ADMIN_REGISTERED_DAPPS = gql`
  query dapps($dappAdmin: String!) {
    registeredDapps(
      # dappAdmin: $dappAdmin
      where: { dappAdmin: $dappAdmin }
    ) # orderBy: timestamp
    # orderDirection: desc
    {
      id
      dappId
      dappAdmin
      dappCount
      dappName
      networks
    }
  }
`;

export const GET_DAPP = gql`
  query dapps($dappId: String!) {
    registeredDapps(
      where: { dappId: $dappId }
    ) {
      id
      dappId
      dappAdmin
      dappCount
      dappName
      networks
    }
  }
`;

// export const ADMIN_REGISTERED_DAPPS1 = gql`
//   query dapps($dappAdmin: Bytes!) {
//     registeredDapps(
//       where: { dappAdmin: $dappAdmin }
//       # orderBy: dappCount
//       # orderDirection: desc
//     ) {
//       id
//       dappId
//       dappAdmin
//       dappCount
//       dappName
//       networks
//     }
//   }
// `;

export const DAPP_SUBSCRIBERS = gql`
  query dapps($dappId: String!) {
    dappSubscribers(where: { dappId: $dappId }, orderBy: timestamp) {
      id
      dappId
      account
      subscriberCount
      timestamp
    }
  }
`;

export const SUBSCRIBED_DAPPS = gql`
  query dapps($account: String!) {
    dappSubscribers(where: { account: $account }) {
      dappId
      account
    }
  }
`;

export const DAPP_NOTIFICATIONS = gql`
  query dappNotifs($toAccount: String!) {
    dappNotifications(
      where: { toAccount: $toAccount }
      orderBy: timestamp
      orderDirection: desc
    ) {
      dappId
      toAccount
      message
      buttonName
      cta
      isEncrypted
    }
  }
`;

export const DAPP_ROLES = gql`
  query dapps($dappId: String!) {
    dappAdmins(where: { dappId: $dappId }) {
      dappId
      dappAdmin
      admin
      role
    }
  }
`;

export const DAPP_ROLES_BY_USER = gql`
  query dapps($admin: String!) {
    dappAdmins(where: { admin: $admin }) {
      dappId
      dappAdmin
      admin
      role
    }
  }
`;

export const REGISTERED_DOMAINS = gql`
  query dapps($account: String!) {
    registeredDomains(where: { account: $account }) {
      id
      domain
      account
      dappId
      name
      tld
    }
  }
`;

export const MESSAGE_SENTS = gql`
  query MyQuery {
    messageSents {
      from
      id
      isEncrypted
      messageId
      textMessageReceiver
      textMessageSender
      timestamp
      to
    }
  }
`;

export const WHITELISTED_USERS = gql`
  query MyQuery($from: Bytes!) {
    whitelistedUsers(where: { from: $from }) {
      from
      id
      to
    }
  }
`;
export const LAST_MESSAGE_FROM_SENDER = gql`
  query MyQuery($from: Bytes!, $to: Bytes!) {
    messageSents(
      where: { to: $to, from: $from }
      orderBy: timestamp
      orderDirection: desc
      first: 1
    ) {
      from
      id
      isEncrypted
      messageId
      textMessageReceiver
      timestamp
      to
    }
  }
`;
export const TOTAL_MESSAGE_FROM_SENDER = gql`
  query MyQuery {
    messageSents {
      from
      to
      totalCount
      timestamp
      textMessageSender
      textMessageReceiver
      isEncrypted
      messageId
      id
    }
  }
`;

export const FROM_MESSAGE_QUERY = gql`
  query MyQuery($from: Bytes!) {
    messageSents(where: { from: $from }) {
      from
      id
      isEncrypted
      messageId
      textMessageReceiver
      timestamp
      to
    }
  }
`;

export const TO_MESSAGE_QUERY = gql`
  query MyQuery($to: Bytes!) {
    messageSents(where: { to: $to }) {
      from
      id
      isEncrypted
      messageId
      textMessageReceiver
      timestamp
      to
    }
  }
`;
