import React, { useContext, useState, useEffect } from "react";
import BridgeContext from "./Context";

interface Props {
  children: any;
}

const AuthenticationState = ({ children }: Props) => {
  const [step, setStep] = React.useState(0);
  const [isNetworkUser, setNetwokUser] = React.useState(false);
  const [pvtKey, setPvtKey] = useState();
  const [isRepeatUser, setIsRepeatUser] = useState();
  const [notificationList] = useState([]);
  const [description, setDescription] = useState();
  const [CTALabel, setLabel] = useState();
  const [Url, setUrl] = useState();
  const [tabValue, setTabValue] = useState("");
  const [CodetabValue, setCodeTabValue] = useState("");
  const [isSubscribed, setIsSubscribed] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [open, setOpen] = useState(false);
  const [isHellobar, setHellobar] = useState(false);
  const [isDomain, setDomain] = useState(false);
  const [isSocialLogin, setIsSocialLogin] = useState<boolean>(false);
  const [domainNft, setDomainNft] = useState({
    name: "",
    url: "",
  });

  const [partnerTab, setPartnerTab] = useState("Businesses");

  const UpdateStep = () => {
    setStep((prev) => prev + 1);
  };

  useEffect(() => {
    if (localStorage.getItem("googleAuth")) {
      setIsSocialLogin(true);
    } else {
      setIsSocialLogin(false);
    }
  }, [localStorage]);

  const [transactions, setTransactions] = useState([]);
  return (
    <>
      <BridgeContext.Provider
        value={{
          step,
          isNetworkUser,
          UpdateStep,
          notificationList,
          transactions,
          setTransactions,
          description,
          CTALabel,
          Url,
          partnerTab,
          setPartnerTab,
          tabValue,
          setTabValue,
          setStep,
          setDescription,
          setLabel,
          setUrl,
          setNetwokUser,
          pvtKey,
          setPvtKey,
          isRepeatUser,
          setIsRepeatUser,
          isSubscribed,
          setIsSubscribed,
          setIsOpen,
          isOpen,
          setOpen,
          open,
          isHellobar,
          setHellobar,
          isDomain,
          setDomain,
          CodetabValue,
          setCodeTabValue,
          domainNft,
          setDomainNft,
          isSocialLogin,
          setIsSocialLogin,
        }}
      >
        {children}
      </BridgeContext.Provider>
    </>
  );
};
export default AuthenticationState;
