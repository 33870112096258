import { injected, walletconnect, coinbaseWallet } from "../connectors";
import { AbstractConnector } from "@web3-react/abstract-connector";
// import metaLogo from "../assets/images/meta.svg";
// import walletLogo from "../assets/images/wallet.svg";
// import coinbaseLogo from "../assets/images/coinbasewallet.png";
import hashpacklogo from "../assets/images/hashpackk.png";
import metaLogo from "../assets/connector/metamask.png";
import walletLogo from "../assets/connector/wallte.png";

interface Wallet {
  [connetorName: string]: {
    id: string;
    name: string;
    logoUri: string;
    connector: AbstractConnector;
    isMobileSupported: boolean;
    depiction: string;
  };
}

export const wallets: Wallet = {
  METAMASK: {
    id: "1",
    name: "Metamask",
    logoUri: metaLogo,
    connector: injected,
    isMobileSupported: false,
    depiction: "Chrome Extension Trusted By 10 Million Users.",
  },
  WALLETCONNECT: {
    id: "2",
    name: "Wallet Connect",
    logoUri: walletLogo,
    connector: walletconnect,
    isMobileSupported: true,
    depiction: "Using Trust Wallet & Rainbow Wallet",
  },
  // COINBASE: {
  //   name: 'Coinbase Wallet',
  //   logoUri: coinbaseLogo,
  //   connector: coinbaseWallet,
  //   isMobileSupported: true,
  //   depiction: 'Using Trust Wallet & Rainbow Wallet',
  // },
  // HASHPACK: {
  //   id: "3",
  //   name: "Hashpack Wallet",
  //   logoUri: hashpacklogo,
  //   connector: null,
  //   isMobileSupported: true,
  //   depiction: "Using Trust Wallet & Rainbow Wallet",
  // },
};

export const ZERO_ADDRESS = "0x0000000000000000000000000000000000000000";

export const googleAuthAPIKey = String(process.env.REACT_APP_GOOGLE_AUTH_KEY);
