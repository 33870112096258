import { createReducer } from "@reduxjs/toolkit";
import {
  setPopUpOpen,
  setAppChainId,
  setPopUpClose,
  addRecentSearch,
  setOneToOneMessage,
  updateOneToOneSentMessage,
  setSpamProtection,
  setUserInputMessage,
  setDappsData,
} from "./actions";
import { Message, UserMessages } from "types/message";
import { isEmpty, concat } from "lodash";
import { BigNumber } from "ethers";

export enum PopUpTypes {
  WALLET,
  TRANSACTION,

  NETWORK,
  STAKE,
  UNSTAKE,
}

export interface ActionScreenInterface {
  action: "STAKE" | "UNSTAKE" | "DIRECT_FARM" | null;
  searchKey: string;
}
interface ApplicationState {
  openPopUp: PopUpTypes | null;
}

const applicationState: ApplicationState = {
  openPopUp: null,
};

export interface SearchResult {
  appName: string;
  appId: string;
  chainId: number;
  logoUrl: string;
}

export interface SearchResults {
  searchResults: SearchResult[];
}

export const application = createReducer<ApplicationState>(
  applicationState,
  (builder) => {
    builder.addCase(setPopUpOpen, (state, { payload: { openPopUp } }) => {
      console.log("insideReducer", openPopUp, state);
      return {
        ...state,
        openPopUp,
      };
    }),
      builder.addCase(setPopUpClose, (state, { payload: { openPopUp } }) => {
        return {
          ...state,
          openPopUp,
        };
      });
  }
);

interface User {
  appChainId: number;
}

const userState: User = {
  appChainId: 137,
};

export interface SearchResults {
  searchResults: SearchResult[];
}

const initialRecentSearchState: SearchResults = {
  searchResults: null,
};

export const user = createReducer<User>(userState, (builder) => {
  builder.addCase(setAppChainId, (state, { payload: { appChainId } }) => {
    return {
      ...state,
      appChainId,
    };
  });
});

export const searchResult = createReducer<SearchResults>(
  initialRecentSearchState,
  (builder) => {
    builder.addCase(addRecentSearch, (state, { payload: { searchResult } }) => {
      console.log("searchResult", searchResult);
      let oldSearchResults = state?.searchResults;
      oldSearchResults = oldSearchResults?.filter(
        (el) => el?.appId !== searchResult?.appId
      );
      state.searchResults = oldSearchResults;
      return state;
    });
  }
);

export interface OneToOneMessages {
  messages: UserMessages[];
  newRequests: UserMessages[];
}

// const messagesInitialState: OneToOneMessages = {
//   messages: null,
//   newRequests: null,
// };

const messagesInitialState: Message[] = null;

export const messages = createReducer<Message[]>(
  messagesInitialState,
  (builder) => {
    builder.addCase(setOneToOneMessage, (state, { payload }) => {
      state = payload;
      return state;
    });
    builder.addCase(updateOneToOneSentMessage, (state, { payload }) => {
      if (isEmpty(payload)) return;
      const messagesState = state;
      state = concat(messagesState, payload);
      // const userIndex = state.messages?.findIndex(
      //   (el) =>
      //     el?.recieverAddress?.toLowerCase() === messages?.recieverAddress
      // );

      // if (userIndex === -1) {
      //   state.messages.push(messages);
      // } else {
      //   state.messages?.[userIndex]?.messages?.concat(messages?.messages);
      // }
      return state;
    });
  }
);

// export const messages = createReducer<OneToOneMessages>(
//   messagesInitialState,
//   (builder) => {
//     builder.addCase(
//       setOneToOneMessage,
//       (state, { payload: { messages, newRequests } }) => {
//         state.messages = messages;
//         state.newRequests = newRequests;
//         return state;
//       }
//     );
//     builder.addCase(
//       updateOneToOneSentMessage,
//       (state, { payload: { messages } }) => {
//         if (isEmpty(messages)) return;
//         const userIndex = state.messages?.findIndex(
//           (el) =>
//             el?.recieverAddress?.toLowerCase() === messages?.recieverAddress
//         );

//         if (userIndex === -1) {
//           state.messages.push(messages);
//         } else {
//           state.messages?.[userIndex]?.messages?.concat(messages?.messages);
//         }
//         return state;
//       }
//     );
//   }
// );

export interface Tokens {
  [tokenAddres: string]: {
    address: string;
    amount: BigNumber;
  };
}

export interface SpamProtection {
  status: boolean;
  tokens: Tokens;
}

const initialSpamProtectionState = {
  status: false,
  tokens: null,
};

export const spamProtection = createReducer<SpamProtection>(
  initialSpamProtectionState,
  (builder) => {
    builder.addCase(
      setSpamProtection,
      (state, { payload: { spamProtection } }) => {
        state.status = spamProtection?.status;
        state.tokens = spamProtection?.tokens;
        return state;
      }
    );
  }
);

export interface TokenData {
  address: string;
  symbol: string;
  amount: number;
  checked: boolean;
}

export interface UserInputMessage {
  messageContent?: string;
  userAddress?: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  currentContentAsHTML?: any;
}

const messageInputInitialState = {
  messageContent: null,
  userAddress: null,
  currentContentAsHTML: null,
};

export const inputMessage = createReducer<UserInputMessage>(
  messageInputInitialState,
  (builder) => {
    builder.addCase(
      setUserInputMessage,
      (state, { payload: { userInputs } }) => {
        state = userInputs;
        return state;
      }
    );
  }
);

export interface Dapps {
  id: string;
  appId: string;
  appIcon: string;
  appAdmin: string;
  appCount: string;
  appName: string;
  chainId: number;
  isSubscribed: any[];
  subscriberCount: any[];
}

export interface DappsList {
  dappsList: Dapps[];
}
export const dappsInitialState = {
  dappsList: null,
};

export const all_dapps = createReducer<DappsList>(
  dappsInitialState,
  (builder) => {
    builder.addCase(setDappsData, (state, { payload: { dappsList } }) => {
      state.dappsList = dappsList;
      return state;
    });
  }
);
