import DappsContext from "AuthenticationContext/Context";
import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { HashLink } from "react-router-hash-link";
import Explore from "../../assets/sidebrIcon/explore.png";
import Message from "../../assets/sidebrIcon/message.png";
import Home from "../../assets/sidebrIcon/home.png";
import MyApps from "../../assets/sidebrIcon/myApps.png";
import MySubscriber from "../../assets/sidebrIcon/mySubscriber.png";
import SubScribe from "../../assets/sidebrIcon/subsribe.png";
import {
  SideBarRoot,
  SidebarItems,
  SidebarItemsRoot,
  TextWrap,
  UploadDiv,
} from "./SidebarWrapper.style";

const Sidebar = () => {
  const [isActive, setActive] = React.useState();
  const contextData = useContext(DappsContext);

  const Navigate = useNavigate();
  const openUploadDapps = () => {
    Navigate("/subscription");
    setActive(null);
  };

  const ClickActive = (index) => {
    if (index == 3) {
      contextData.setTabValue("mydapps");
    }
    if (index == 4) {
      contextData.setTabValue("subscriptions");
    }
    setActive(index);
    console.log("contextData", contextData);
  };
  const SidebarData = [
    {
      name: "Home",
      icon: Home,
      path: "/",
      active: true,
    },
    {
      name: "Explore",
      icon: Explore,
      //  path: "/explore",
      path: "/#category",
      active: true,
    },
    // {
    //   name: "Trending",
    //   icon: Trade,
    //   path: "/trending",
    //   active: true,
    // },

    // {
    //   name: "Top Rated",
    //   icon: Top,
    //   active: true,
    // },
    {
      name: "Trending",
      path: "/trending",
      icon: SubScribe,
      active: true,
    },
    {
      name: "My apps",
      icon: MyApps,
      path: "/profile/admin",
      active: contextData.pvtKey ? true : false,
    },
    {
      name: "My Subscribed",
      icon: MySubscriber,
      path: "/profile/admin",
      active: contextData.pvtKey ? true : false,
    },
    {
      name: "Messenger",
      icon: Message,
      path: "/compose-message",
      active: true,
    },
  ];
  return (
    <SideBarRoot>
      <SidebarItemsRoot>
        {SidebarData.map((sd, index) => {
          return (
            <HashLink
              to={sd.path}
              style={{ textDecoration: "none", width: "100%" }}
              smooth
              key={index}
            >
              {sd.active ? (
                <SidebarItems
                  onClick={() => ClickActive(index)}
                  isActive={index == isActive}
                >
                  <TextWrap>
                    <img src={sd.icon} width={25} />
                    {sd.name}
                  </TextWrap>
                </SidebarItems>
              ) : null}
            </HashLink>
          );
        })}
      </SidebarItemsRoot>
      <UploadDiv onClick={openUploadDapps}>Upload Dapp</UploadDiv>
    </SideBarRoot>
  );
};
export default Sidebar;
