import {
  Dapps,
  OneToOneMessages,
  PopUpTypes,
  SpamProtection,
  Tokens,
  UserInputMessage,
} from "./reducer";
import { AppState, AppDispatch } from "./index";
import { useCallback, useContext, useEffect, useMemo } from "react";
import { isEmpty, concat } from "lodash";
import {
  setPopUpClose,
  setPopUpOpen,
  setAppChainId,
  setOneToOneMessage,
  updateOneToOneSentMessage,
  setSpamProtection,
  setUserInputMessage,
  setDappsData,
} from "./actions";
import { useSelector, useDispatch } from "react-redux";
import {
  BSC_CHAIN,
  POLYGON_CHAIN,
  ETH_CHAIN,
  AVAX_CHAIN,
  ARBITRUM_CHAIN,
  OPTIMISM_CHAIN,
  FANTOM_CHAIN,
  chainConfig,
  SHARDEUM_CHAIN,
  FIRECHAIN_CHAIN,
} from "../constants/chain";
import { InjectedConnector } from "@web3-react/injected-connector";
import { hexlify, hexStripZeros } from "ethers/lib/utils";
import { useWeb3React } from "@web3-react/core";

import { NetworkDetails, networks } from "../constants/chain";
import { Message, UserMessages } from "types/message";
import { fetchMessages } from "utilities/message/fetchMessages";
import { useMessagingContract } from "hooks/useContract";
import {
  messagingContractAddress,
  subscriptionModuleAddress,
  unifarmAccountsAddress,
} from "constants/dappsContract";
import {
  getDBGraphqlClient,
  getGraphqlClient,
  graphSupportedChains,
} from "utilities/graphql";
import { REGISTERED_DAPPS } from "utilities/graphql/query";
import { getJsonProvider } from "utilities/provider";
import unifarmAccountsABI from "constants/abi/UnifarmAccountsUpgradeable";
import { getContract } from "utilities/getContract";
import { Wallet } from "ethers";
import DappsContext from "../AuthenticationContext/Context";
import subscriptionModuleABI from "constants/abi/SubscriptionModule";
import { formatMessages } from "utilities/message/formatMessages";

export const useSetPopUp = (popUp: PopUpTypes) => {
  console.log(popUp, "popUppppppp");
  const dispatch = useDispatch<AppDispatch>();
  //   dispatch(
  //   setPopUpOpen({
  //     openPopUp: popUp,
  //   })
  // );
  return useCallback(() => {
    console.log("insidecallbackkkk");
    dispatch(
      setPopUpOpen({
        openPopUp: popUp,
      })
    );
  }, [dispatch, popUp]);
};

export const useOpenWalletPopUp = () => {
  console.log("walletttttttttttt");
  return useSetPopUp(PopUpTypes.WALLET);
};

export const usePopupStatus = (popUp: PopUpTypes): boolean => {
  const openedPopUp: PopUpTypes = useSelector((state: AppState) => {
    return state.application.openPopUp;
  });
  return useMemo(() => {
    return openedPopUp === popUp;
  }, [popUp, openedPopUp]);
};

export const useClosePopup = () => {
  const dispatch = useDispatch<AppDispatch>();
  return useCallback(() => {
    dispatch(
      setPopUpClose({
        openPopUp: null,
      })
    );
  }, [dispatch]);
};

export const useChangeAppChainId = () => {
  const dispatch = useDispatch<AppDispatch>();
  // const { connector, library} = useWeb3React();
  const { connector, library, chainId } = useWeb3React();
  const { appChainId } = useApplicationUserState();
  return useCallback(
    async (newAppChainId: number, noSwitch?: boolean) => {
      const isMetaMask = window.ethereum && window.ethereum.isMetaMask;

      try {
        if (connector instanceof InjectedConnector && isMetaMask) {
          // const ethereum = window.ethereum;
          if (newAppChainId === BSC_CHAIN && !noSwitch) {
            await library.provider.request({
              method: "wallet_switchEthereumChain",
              params: [{ chainId: hexlify(BSC_CHAIN) }],
            });
          } else if (newAppChainId === POLYGON_CHAIN && !noSwitch) {
            await library.provider.request({
              method: "wallet_switchEthereumChain",
              params: [
                {
                  chainId: hexStripZeros(hexlify(POLYGON_CHAIN)),
                },
              ],
            });
          } else if (newAppChainId === ETH_CHAIN && !noSwitch) {
            await library.provider.request({
              method: "wallet_switchEthereumChain",
              params: [{ chainId: hexStripZeros(hexlify(ETH_CHAIN)) }],
            });
          } else if (newAppChainId === AVAX_CHAIN && !noSwitch) {
            await library.provider.request({
              method: "wallet_switchEthereumChain",
              params: [{ chainId: hexStripZeros(hexlify(AVAX_CHAIN)) }],
            });
          } else if (newAppChainId === ARBITRUM_CHAIN && !noSwitch) {
            await library.provider.request({
              method: "wallet_switchEthereumChain",
              params: [{ chainId: hexStripZeros(hexlify(ARBITRUM_CHAIN)) }],
            });
          } else if (newAppChainId === OPTIMISM_CHAIN && !noSwitch) {
            await library.provider.request({
              method: "wallet_switchEthereumChain",
              params: [{ chainId: hexStripZeros(hexlify(OPTIMISM_CHAIN)) }],
            });
          } else if (newAppChainId === FANTOM_CHAIN && !noSwitch) {
            await library.provider.request({
              method: "wallet_switchEthereumChain",
              params: [{ chainId: hexStripZeros(hexlify(FANTOM_CHAIN)) }],
            });
          } else if (newAppChainId === SHARDEUM_CHAIN && !noSwitch) {
            await library.provider.request({
              method: "wallet_switchEthereumChain",
              params: [{ chainId: hexStripZeros(hexlify(SHARDEUM_CHAIN)) }],
            });
          } else if (newAppChainId === FIRECHAIN_CHAIN && !noSwitch) {
            await library.provider.request({
              method: "wallet_switchEthereumChain",
              params: [{ chainId: hexStripZeros(hexlify(FIRECHAIN_CHAIN)) }],
            });
          }
        }
        dispatch(
          setAppChainId({
            appChainId: newAppChainId,
          })
        );
        return true;
      } catch (err) {
        if (err.code === 4902) {
          const ethereum = window?.ethereum;
          await ethereum.request({
            method: "wallet_addEthereumChain",
            params: chainConfig[newAppChainId],
          });
        }
        console.log("Error in useChangeAppChainId(): ", err.message);
        return false;
      }
    },
    [dispatch, connector]
  );
};

export const useApplicationUserState = () => {
  return useSelector((state: AppState) => state.user);
};

export const useOpenNetworkPopUp = () => {
  return useSetPopUp(PopUpTypes.NETWORK);
};

export const useActiveNetwork = (): NetworkDetails => {
  const { appChainId } = useApplicationUserState();
  return useMemo(() => {
    return networks[appChainId];
  }, [appChainId]);
};

export const useSetOneToOneMessage = () => {
  const dispatch = useDispatch<AppDispatch>();
  const { chainId, account } = useWeb3React();
  const { appChainId } = useApplicationUserState();
  const dappsContext = useContext(DappsContext);

  useEffect(() => {
    if (!dappsContext.pvtKey) return;
    try {
      const wallet = new Wallet(dappsContext?.pvtKey);
      const fetch = async () => {
        // fetch messages from graph
        const secondryWalletmessages = await fetchMessages(
          appChainId,
          wallet.address
        );

        const primaryWalletMessage = await fetchMessages(appChainId, account);

        const allNewMessage = concat(
          secondryWalletmessages,
          primaryWalletMessage
        );

        // dispatch acttion
        if (!isEmpty(allNewMessage)) {
          dispatch(setOneToOneMessage(allNewMessage));
        }
      };
      if (chainId === 137 && wallet.address) {
        fetch();
      }
    } catch (err) {
      if (err instanceof Error) {
        console.log("UseSetOneToOneMessage Failed", err?.message);
      }
    }
  }, [appChainId, dappsContext?.pvtKey, dispatch]);
};

export const useMessages = (): OneToOneMessages => {
  const allMessages = useSelector((state: AppState) => state.messages);
  const { account } = useWeb3React();
  const dappsContext = useContext(DappsContext);

  let messages: UserMessages[] = [];
  let newRequests: UserMessages[] = [];

  // if (account) {
  //   const primaryWalletMessages = formatMessages(allMessages, account);
  //   messages = concat(primaryWalletMessages?.messages, messages);
  //   newRequests = concat(primaryWalletMessages?.newRequests, newRequests);
  // }

  // if (dappsContext.pvtKey) {
  //   const wallet = new Wallet(dappsContext?.pvtKey);
  //   const secondaryWalletMessages = formatMessages(
  //     allMessages,
  //     wallet?.address
  //   );
  //   messages = concat(secondaryWalletMessages?.messages, messages);
  //   newRequests = concat(secondaryWalletMessages?.newRequests, newRequests);
  // }

  if (dappsContext?.pvtKey && account) {
    const wallet = new Wallet(dappsContext?.pvtKey);
    const secondaryWalletMessages = formatMessages(
      allMessages,
      account,
      wallet?.address
    );
    messages = concat(secondaryWalletMessages?.messages, messages);
    newRequests = concat(secondaryWalletMessages?.newRequests, newRequests);
  }

  // check if message is empty
  const notEmptyMessages = messages?.map((el) => {
    if (!isEmpty(el)) {
      return el;
    }
  });
  const notEmptyRequests = newRequests?.map((el) => {
    if (!isEmpty(el)) {
      return el;
    }
  });
  return { messages: notEmptyMessages, newRequests: notEmptyRequests };
};

export const useUpdateOneToOneMessage = () => {
  const dispatch = useDispatch<AppDispatch>();

  return useCallback(
    (messages: Message[]) => {
      dispatch(updateOneToOneSentMessage(messages));
    },
    [dispatch]
  );
};

export const useSetSpamProtection = () => {
  const dispatch = useDispatch<AppDispatch>();
  const { chainId, account } = useWeb3React();

  const contractAddress = messagingContractAddress(chainId);

  const messagingContractInstance = useMessagingContract(contractAddress);

  useEffect(() => {
    const fetchSpamProtection = async () => {
      try {
        const [isSpamProtecting, userSpamTokens] = await Promise.all([
          messagingContractInstance.isSpamProtecting(account),
          messagingContractInstance.getUserSpamTokens(account),
        ]);

        const tokens: Tokens = {};

        userSpamTokens?.map((el) => {
          tokens[el?.address] = {
            address: el[0],
            amount: el[1],
          };
        });

        if (isSpamProtecting) {
          dispatch(
            setSpamProtection({
              spamProtection: {
                status: isSpamProtecting,
                tokens: !isEmpty(tokens) ? tokens : {},
              },
            })
          );
        } else {
          dispatch(
            setSpamProtection({
              spamProtection: {
                status: isSpamProtecting,
                tokens: {},
              },
            })
          );
        }
      } catch (err) {
        if (err instanceof Error) {
          console.log("UseSetSpamProtection Failed....", err?.message);
        }
      }
    };

    if (chainId && account) {
      fetchSpamProtection();
    }
  }, [chainId, account]);
};

export const useSpamProtection = (): SpamProtection => {
  return useSelector((state: AppState) => state.spamProtection);
};

export const useSetUserInputMessages = () => {
  const dispatch = useDispatch<AppDispatch>();

  return useCallback(
    (userInputs: UserInputMessage) => {
      dispatch(
        setUserInputMessage({
          userInputs,
        })
      );
    },
    [dispatch]
  );
};

export const useUserInputMessages = () => {
  return useSelector((state: AppState) => state.inputMessage);
};

// export const useSetSelectedSpamProtectionTokens = () => {
//   const dispatch = useDispatch<AppDispatch>();

//   return useCallback(
//     (selectedSpamProtectionToken: TokenData[]) => {
//       dispatch(
//         setSelectedSpamProtectionToken({
//           selectedSpamProtectionToken,
//         })
//       );
//     },
//     [dispatch]
//   );
// };

// export const useSelectedSpamProtectionToken = () => {
//   return useSelector((state: AppState) => state.selectedSpamProtectionToken);
// };

// export const useAddRecentSearchAdd = () => {
//   const dispatch = useDispatch();
//   return useCallback(
//     (newSearch: SearchResult) => {
//       dispatch(addRecentSearch({ searchResult: newSearch }));
//     },
//     [dispatch]
//   );
// };

// export const useRecentSearch = (): SearchResults => {
//   return useSelector((state: AppState) => state.searchResult);
// };

export const useSetDapps = () => {
  const dispatch = useDispatch<AppDispatch>();
  const { account } = useWeb3React();

  useEffect(() => {
    async function getGraphData() {
      try {
        const client = getDBGraphqlClient();

        const res = await client.query({
          query: REGISTERED_DAPPS,
        });
        const registeredDapps = res.data.registeredDapps;

        const dappsPromises = [],
          subscriberCountPromises = [],
          isSubscribedPromises = [],
          networks = [];
        for (let i = 0; i < registeredDapps.length; i++) {
          const dapp = registeredDapps[i];
          networks.push(dapp.networks);
          // console.log("dapp: ", dapp.dappId);
          const provider = getJsonProvider(parseInt(dapp.networks[0]));
          const subscriptionModuleInstance = getContract(
            subscriptionModuleAddress(parseInt(dapp.networks[0])),
            subscriptionModuleABI.abi,
            provider
          );

          const dappData = subscriptionModuleInstance.getDapp(dapp.dappId);
          dappsPromises.push(dappData);

          // const subscriberCount = subscriptionModuleInstance.subscriberCount(
          //   dapp.dappId
          // );
          // subscriberCountPromises.push(subscriberCount);

          // let isSubscribed;
          // if (account)
          //   isSubscribed = subscriptionModuleInstance.isSubscribed(
          //     dapp.dappId,
          //     0, // list
          //     account
          //   );
          // else isSubscribed = new Promise((resolve, reject) => resolve(false));
          // isSubscribedPromises.push(isSubscribed);
        }

        const resolvedDappsData = await Promise.all(dappsPromises);
        // const resolvedSubscriberCount = await Promise.all(
        //   subscriberCountPromises
        // );
        // const resolvedIsSubscribed = await Promise.all(isSubscribedPromises);

        const dappsList = [];
        for (let i = 0; i < resolvedDappsData.length; i++) {
          let dapp = resolvedDappsData[i];
          dapp = {
            ...dapp,
            chainId: networks[i][0],
            // isSubscribed: resolvedIsSubscribed[i],
            // subscriberCount: resolvedSubscriberCount[i],
            networks: networks[i],
          };
          dappsList.push(dapp);
        }

        if (!isEmpty(dappsList)) {
          dispatch(
            setDappsData({
              dappsList,
            })
          );
        }
      } catch (err) {
        if (err instanceof Error) {
          console.log("UseSetDapps Failed ", err?.message);
        }
      }
    }

    getGraphData();
  }, [dispatch, account]);
};

export const useDapps = () => {
  return useSelector((state: AppState) => state.all_dapps);
};
