import { MediaQuery } from "const/MediaQuery";
import styled from "styled-components";

export const SideBarWrapper = styled.div``;
export const ContentWrap = styled.div`
  display: flex;
  margin-top: 2rem;
`;
export const SideBarRoot = styled.div`
  // width: 230px;
  width: 92%;
  height: auto;
  padding-bottom: 1.3rem;
  background: #ffffff;
  /* Font/Secondary */

  border: 1px solid #dcdede;
  border-radius: 0px 25px 25px 25px;
  @media (max-width: 430px) {
    border: unset;
    width: 100%;
    height: 100%;
  }
  @media (${MediaQuery.Ipad}) {
    border: unset;
    width: 100%;
    height: 100%;
  }
`;
interface isPorps {
  isActive: boolean;
}

export const SidebarItems = styled.div<isPorps>`
  height: 55px;
  display: flex;
  align-items: center;
  width: 100%;
  background: ${(props) =>
    props?.isActive
      ? "linear-gradient(270deg,rgba(74, 248, 255, 0.7) -30.69%, rgba(255, 255, 255, 0) 68.97%)"
      : null};
  border-radius: ${(props) => (props?.isActive ? "0px 20px 0px 0px" : null)};
  color: ${(props) => (props?.isActive ? "#30BCC2" : "#231F20")};
  font-weight: ${(props) => (props?.isActive ? 600 : 500)};
  @media (max-width: 430px) {
    font-weight: ${(props) => (props?.isActive ? 600 : 600)};
  }
  @media (${MediaQuery.Ipad}) {
    font-weight: ${(props) => (props?.isActive ? 600 : 600)};
  }
  :hover {
    background: linear-gradient(
      270deg,
      rgba(74, 248, 255, 0.7) -30.69%,
      rgba(255, 255, 255, 0) 68.97%
    );
    border-radius: 0px 20px 0px 0px;
    cursor: pointer;
  }
`;

export const SidebarItemsRoot = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
`;
export const TextWrap = styled.div`
  font-family: "Montserrat";
  font-style: normal;
  display: flex;
  align-items: center;
  column-gap: 9px;
  font-size: 16px;

  margin-left: 2rem;
  @media (max-width: 430px) {
    margin-left: 0rem;
  }
  @media (max-width: 430px) {
    height: 40px;
    font-weight: 600;
    color: #231f20;
    margin-left: 0rem;
  }
  @media (${MediaQuery.Ipad}) {
    height: 40px;
    font-weight: 600;
    color: #231f20;
    margin-left: 0rem;
  }
`;
export const UploadDiv = styled.div`
  text-align: left;
  padding-left: 2rem;
  margin-top: 1rem;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  color: #30bcc2;
  :hover {
    cursor: pointer;
  }
  @media (max-width: 430px) {
    padding-left: 0rem;
  }
  @media (${MediaQuery.Ipad}) {
    padding-left: 0rem;
  }
`;

export const ImageDiv = styled.img`
  @media (${MediaQuery.Ipad2}) {
    display: none;
  }
`;
export const StickyDiv = styled.div`
  position: sticky;
  top: 100px;
`;
