import React, { useEffect, useState } from "react";
import { ConfigDesc, IdWrapper, ItemsWrapper } from "./config.style";
import CheckIcon from "@mui/icons-material/Check";
import EditIcon from "@mui/icons-material/Edit";
import {
  BannerWrapper,
  ConfigButton,
  ConfigTitle,
  ContentWrapper,
  MainWarpper,
} from "./config.style";
import { useIpad, useMobile } from "const/MuiMediaQuery";
import { IconButton } from "@mui/material";
import { useWeb3React } from "@web3-react/core";
import { getContract } from "utilities/getContract";
import { walletRegistryAddress } from "constants/dappsContract";
import walletRegistryABI from "../../constants/abi/WalletRegistry";

interface isProps {
  img: string;
  title: string;
  desc: string;
  enableClick: () => void;
  isActive: boolean;
  chat_id?: string;
  isWhatsapp?: boolean;
  editClick?: (id: string) => any;
}
export const NotificationItems = ({
  img,
  title,
  desc,
  enableClick,
  isActive,
  editClick,
  chat_id,
  isWhatsapp,
}: isProps) => {
  const mobile = useMobile();
  const ipad = useIpad();

  console.log(chat_id, "Chat Id Props");

  // const { account, chainId, library } = useWeb3React();
  // const [chatId, setChatId] = useState("");

  // useEffect(() => {
  //   async function getChatId() {
  //     const unifarmAccountsInstance = getContract(
  //       unifarmAccountsAddress(chainId),
  //       unifarmAccountsABI.abi,
  //       library,
  //       account
  //     );
  //     const chat_id = await unifarmAccountsInstance.telegramChatID(account);
  //     setChatId(chat_id);
  //   }
  //   getChatId();
  // }, [account]);

  return (
    <ItemsWrapper isActive={isActive}>
      <MainWarpper>
        <ContentWrapper>
          <img src={img} width={mobile ? 40 : 50} />
          <ConfigTitle>
            {title}
            {ipad ? (
              <>
                {isActive && (
                  <CheckIcon style={{ color: "#44A55A", fontSize: 35 }} />
                )}
              </>
            ) : null}
          </ConfigTitle>
        </ContentWrapper>
        {isActive ? (
          ipad ? null : (
            <CheckIcon style={{ color: "#44A55A", fontSize: 35 }} />
          )
        ) : (
          <ConfigButton onClick={enableClick}>Enable</ConfigButton>
        )}
      </MainWarpper>
      {isActive ? (
        <IdWrapper>
          <div style={{ display: "flex", alignItems: "center", columnGap: 8 }}>
            <ConfigTitle>
              {isWhatsapp ? "WhatsApp Id:" : "Chat Id:"}
            </ConfigTitle>
            <ConfigDesc>{chat_id}</ConfigDesc>
          </div>
          {/* <IconButton onClick={editClick} style={{ color: "black" }}> */}
          <IconButton
            onClick={() => {
              editClick(chat_id);
            }}
            style={{ color: "black" }}
          >
            <EditIcon />
          </IconButton>
        </IdWrapper>
      ) : (
        <ConfigDesc>{desc}</ConfigDesc>
      )}
    </ItemsWrapper>
  );
};
