import React, { useState, useContext, useEffect } from "react";
import {
  BannerWrapper,
  BoatButton,
  CongrestText,
  HomeText,
  ItemsNotiWrapper,
  ModalContent,
  ModalWrapper,
  ModalWrapper2,
  SubmitButton,
  WrapperText,
} from "./config.style";
import { useWeb3React } from "@web3-react/core";
import { NotificationItems } from "./NotificationItems";
import NotyfictionImg from "assets/images/telegram.png";
import Whatsapp from "assets/images/whatsapp.png";
import BrowserImage from "assets/images/notiBrowser.png";
import CustomizedDialogs from "components/Modal";
import NotificationProgress from "components/Profile/Shared/ProegresBar";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { TgInput } from "components/Homepage/Homepage.style";
import notification from "assets/images/notificationBanner.png";
import notificationMobile from "assets/images/mobileNotification.png";
import notificationIpad from "assets/images/notificationIpad.png";
import { useIpad, useMobile } from "const/MuiMediaQuery";
import DappsContext from "../../AuthenticationContext/Context";
import { gaslessTxn } from "hooks/useGasless";
import { walletRegistryAddress } from "constants/dappsContract";
import walletRegistryABI from "../../constants/abi/WalletRegistry";
import { getContract } from "utilities/getContract";
import { getJsonProvider } from "utilities/provider";
import { ClipLoader } from "react-spinners";
import Success from "components/Snackbar/Success";
import { gaslessTxnHermes } from "hooks/useHermes";
import { WhatsappModal } from "./WhatsappModal";
import { utils } from "ethers";
import { getPvtKeyGlobal_useEffect1, getPvtKeyGlobal_useEffect2 } from "utilities/getPvtKeyGlobal";

const NotificationConfig = () => {
  const dappContext = useContext(DappsContext);
  const { account, chainId, library } = useWeb3React();
  const [isOpen, setIsOpen] = useState(false);
  const [step, setStep] = useState(1);
  const [isCongrest, setisCongrest] = useState(false);
  const [active, setActive] = useState(false);
  const [whatsAppActive, setWhatsAppActive] = useState(false);
  const [id, setId] = useState<string>();
  const [editOpen, setIsEditOpen] = useState(false);
  const [isUpdate, setUpdate] = useState(false);
  const [openWhatsApp, setOpenWhatsApp] = useState(false);
  const [updateWhatsAppId, setUpdateWhatsAppId] = useState(false);
  const [defaultChatId, setDefaultChatId] = useState<string>();
  const [defaultWhatsAppId, setDefaultWhatsAppId] = useState<string>();
  const [isSuccessUpdate, setIsSuccessUpdate] = useState(false);
  const [isEnable, setEnable] = useState(false);
  const [isChatId, setIsChatId] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const Mobile = useMobile();
  const Ipad = useIpad();
  const mobile = useMobile();


  useEffect(() => {
    // alert("1")

    // getPvtKeyGlobal_useEffect1(account, chainId, dappContext)
    /*
    // CHECKING CONDITION FOR DIFFERENT CHAINs --------------------------------
    const local_wallet_address = localStorage.getItem("wallet_Address");
    const local_chainId = localStorage.getItem("chain_id");
    const real_wallet_address = account;
    const real_chainId = chainId;

    if (
      String(local_wallet_address).toLowerCase() !==
        String(real_wallet_address).toLowerCase() ||
      String(real_chainId).toLowerCase() !== String(local_chainId).toLowerCase()
    ) {
      // alert("CONDITION false");
      localStorage.removeItem("PT_MATRIX_STRING");
      localStorage.removeItem("P_MATRIX_STRING");
    }
    // CHECKING CONDITION FOR DIFFERENT CHAINs ----------------------------------
    if (dappContext.pvtKey === null) {
      if (
        String(localStorage.getItem("PT_MATRIX_STRING")).length > 0 &&
        String(localStorage.getItem("PT_MATRIX_STRING")) !== "null"
      ) {
        const PT: any = localStorage.getItem("PT_MATRIX_STRING");
        const T: any = localStorage.getItem("P_MATRIX_STRING");
        let P: any = getPRIVATE_Key_String(PT, T);
        P = "0x" + P;
        dappContext.setPvtKey(P);
      }
    }
    */
  }, [dappContext.pvtKey, account, chainId]);
  useEffect(() => {
    // alert("2")
    // getPvtKeyGlobal_useEffect2(dappContext)
    /*
    if (dappContext.pvtKey === null) {
      if (
        String(localStorage.getItem("PT_MATRIX_STRING")).length > 0 &&
        String(localStorage.getItem("PT_MATRIX_STRING")) !== "null"
      ) {
        const PT: any = localStorage.getItem("PT_MATRIX_STRING");

        const T: any = localStorage.getItem("P_MATRIX_STRING");

        let P: any = getPRIVATE_Key_String(PT, T);
        P = "0x" + P;
        dappContext.setPvtKey(P);
      }
    }
    */
  }, []);

  // async function getChatId() {
  //   const walletRegistryInstance = getContract(
  //     walletRegistryAddress(chainId),
  //     walletRegistryABI.abi,
  //     library,
  //     account
  //   );

  //   const chat_id = await walletRegistryInstance.telegramChatID(account);
  //   if (chat_id) {
  //     setDefaultChatId(chat_id);
  //     setActive(true);
  //   }
  // }
  useEffect(() => {
    async function getChatId() {
      const walletRegistryInstance = getContract(
        walletRegistryAddress(chainId),
        walletRegistryABI.abi,
        library,
        account
      );
  
      const chat_id = await walletRegistryInstance.telegramChatID(account);
      if (chat_id) {
        setDefaultChatId(chat_id);
        setActive(true);
      }
    }
    getChatId();
  }, [account]);


  // async function getWhatsAppId() {
  //   console.log("eventID", defaultWhatsAppId);
  //   const walletRegistryInstance = getContract(
  //     walletRegistryAddress(chainId),
  //     walletRegistryABI.abi,
  //     library,
  //     account
  //   );

  //   const whatsAppId = await walletRegistryInstance.whatsAppId(account);
  //   if (whatsAppId) {
  //     console.log(whatsAppId, "whatsAppId");
  //     const utf8Encoded2 = utils.arrayify(whatsAppId);

  //     // Decode the byte array as a UTF-8 encoded string
  //     const whatsappNumber = utils.toUtf8String(utf8Encoded2);
  //     setDefaultWhatsAppId(whatsappNumber);
  //     setWhatsAppActive(true);
  //   }
  // }
  useEffect(() => {

    async function getWhatsAppId() {
      console.log("eventID", defaultWhatsAppId);
      const walletRegistryInstance = getContract(
        walletRegistryAddress(chainId),
        walletRegistryABI.abi,
        library,
        account
      );
  
      const whatsAppId = await walletRegistryInstance.whatsAppId(account);
      if (whatsAppId) {
        console.log(whatsAppId, "whatsAppId");
        const utf8Encoded2 = utils.arrayify(whatsAppId);
  
        // Decode the byte array as a UTF-8 encoded string
        const whatsappNumber = utils.toUtf8String(utf8Encoded2);
        setDefaultWhatsAppId(whatsappNumber);
        setWhatsAppActive(true);
      }
    }
    getWhatsAppId();
  }, [account, openWhatsApp]);

  const OpenConfig = () => {
    setIsOpen(true);
    setStep(1);
    setisCongrest(false);
  };

  const handleWhatsappClick = () => {
    setOpenWhatsApp(true);
    setUpdateWhatsAppId(false);
  };

  const OpenUpdate = (id: string) => {
    // alert(id);
    setId(id);

    setIsEditOpen(true);
  };
  const AddBoat = () => {
    setStep(2);
  };

  const handleChange = (e) => {
    setId(e.target.value);
  };

  const OpenWhatsAppUpdate = (e) => {
    setUpdateWhatsAppId(true);
    setOpenWhatsApp(true);
  };

  const Submit = async () => {
    if (id === undefined) {
      alert("Please enter Chat Id");
    } else {
      try {
        if (dappContext.pvtKey && dappContext.isRepeatUser) {
          console.log("gasless");
          setIsLoading(true);
          await gaslessTxnHermes(
            successFallbackFn,
            errorFallbackFn,
            dappContext.pvtKey,
            chainId,
            walletRegistryAddress(chainId),
            walletRegistryABI.abi,
            "addTelegramChatID",
            [account, id, false],
            true
          );
        } else {
          const provider = getJsonProvider(chainId);
          const walletRegistryInstance = getContract(
            walletRegistryAddress(chainId),
            walletRegistryABI.abi,
            library,
            account
          );
          const tx = await walletRegistryInstance.addTelegramChatID(
            account,
            id
          );
          await tx.wait();
        }
      } catch (error) {
        console.log("Error in Function AddingChatId: ", error);
        setIsLoading(false);
      }
    }
  };

  const successFallbackFn = () => {
    console.log(id, "success ID");

    setIsLoading(false);
    setisCongrest(true);
    setEnable(true);
    setActive(true);
    setDefaultChatId(id);

    // setIsSuccess(true);
  };

  const handleModalClose = () => {
    setOpenWhatsApp(false);
  };

  const errorFallbackFn = () => {
    console.log("error");
    // setIsSuccess(false);
    setIsLoading(false);
  };
  const RenderModalScreen = (step) => {
    switch (step) {
      case 1:
        return (
          <ModalWrapper2 style={{ rowGap: 25 }}>
            <div style={{ width: mobile ? 250 : 300 }}>
              <NotificationProgress
                noOfComleteSendUsers={step}
                totalSendUsers={2}
                isStep={true}
              />
            </div>
            <ModalContent>
              Enter the bot page by clicking this link
              <a
                style={{ color: "#30bcc2" }}
                href="https://t.me/dappscobot"
                target="_blank"
                rel="noreferrer"
              >
                &nbsp;https://t.me/dappscobot
              </a>
              <br />
              then click “/start” at the bottom to go to the chat box.
            </ModalContent>
            <BoatButton onClick={AddBoat}>
              Next <NavigateNextIcon />
            </BoatButton>
          </ModalWrapper2>
        );

      case 2:
        if (isCongrest) {
          return (
            <ModalWrapper2 style={{ rowGap: 30 }}>
              <div style={{ width: mobile ? 250 : 300 }}>
                <NotificationProgress
                  noOfComleteSendUsers={step}
                  totalSendUsers={2}
                  isStep={true}
                />
              </div>
              <ModalContent>Congratulations</ModalContent>
              <CongrestText>
                Your telegram notification has been enabled.
              </CongrestText>
              <HomeText onClick={() => setIsOpen(false)}>Back to Home</HomeText>
            </ModalWrapper2>
          );
        } else {
          return (
            <ModalWrapper2 style={{ rowGap: 25 }}>
              <div style={{ width: mobile ? 250 : 300 }}>
                <NotificationProgress
                  noOfComleteSendUsers={step}
                  totalSendUsers={2}
                  isStep={true}
                />
              </div>
              <ModalContent>
                Copy the ChatId that you got from the bot and then
                <br />
                Paste the ChatId here & click on Submit
              </ModalContent>
              <WrapperText>
                <TgInput
                  type="number"
                  placeholder="Enter Chat ID"
                  value={id}
                  onChange={handleChange}
                  style={{ border: "1px solid #ccc", borderRadius: "20px" }}
                />
                {isLoading ? (
                  <SubmitButton>
                    <ClipLoader color="white" size={16} /> &nbsp;&nbsp;Submit
                  </SubmitButton>
                ) : (
                  <SubmitButton onClick={Submit}>Submit</SubmitButton>
                )}
              </WrapperText>
            </ModalWrapper2>
          );
        }
    }
  };

  const Update = async () => {
    if (id === undefined) {
      alert("Please enter Chat Id");
    } else {
      try {
        if (dappContext.pvtKey && dappContext.isRepeatUser) {
          console.log("gasless");
          setIsLoading(true);
          await gaslessTxnHermes(
            successFallback,
            errorFallbackFn,
            dappContext.pvtKey,
            chainId,
            walletRegistryAddress(chainId),
            walletRegistryABI.abi,
            "updateTelegramChatID",
            [account, id, false],
            true
          );
        } else {
          const provider = getJsonProvider(chainId);
          const walletRegistryInstance = getContract(
            walletRegistryAddress(chainId),
            walletRegistryABI.abi,
            library,
            account
          );
          const tx = await walletRegistryInstance.updateTelegramChatID(
            account,
            id
          );
          await tx.wait();
        }
      } catch (error) {
        console.log("Error in Function UpdatingChatId: ", error);
        setIsLoading(false);
      }
    }
  };

  const successFallback = () => {
    setIsLoading(false);
    // setId(id);
    setUpdate(true);
    setIsSuccessUpdate(true);
    setDefaultChatId(id);
    console.log(id, "Update ID");
    // setIsSuccess(true);
  };

  return (
    <div>
      {Mobile ? (
        <img
          src={notificationMobile}
          style={{ borderRadius: 25, width: "100%" }}
        />
      ) : Ipad ? (
        <img
          src={notificationIpad}
          style={{ borderRadius: 25, width: "100%" }}
        />
      ) : (
        <img src={notification} style={{ borderRadius: 25 }} />
      )}

      <ItemsNotiWrapper>
        <NotificationItems
          desc="Get Notified instantly for  Exclusive projects on dapps"
          enableClick={OpenConfig}
          chat_id={defaultChatId}
          img={NotyfictionImg}
          title="Telegram Notifications"
          isActive={active}
          editClick={(id: string) => {
            OpenUpdate(id);
          }}
        />
        <NotificationItems
          desc="Stay ahead of the game to know about our latest product"
          enableClick={handleWhatsappClick}
          chat_id={defaultWhatsAppId}
          img={Whatsapp}
          title="Whatsapp Notifications"
          isActive={whatsAppActive}
          isWhatsapp={true}
          // editClick={OpenUpdate}
          editClick={(id: string) => {
            OpenWhatsAppUpdate(id);
          }}
        />
        {/* <NotificationItems
          desc="Stay ahead of the game to know about our latest product"
          enableClick={() => {
            console.log("Not Integrated");
          }}
          img={BrowserImage}
          title="Browser Push Notification"
          isActive={false}
          // editClick={OpenUpdate}
          editClick={(id: string) => {
            OpenUpdate(id);
          }}
        /> */}
      </ItemsNotiWrapper>
      <CustomizedDialogs
        open={isOpen}
        onClose={() => setIsOpen(false)}
        title="ENABLE Telegram Alert"
      >
        <ModalWrapper>{RenderModalScreen(step)}</ModalWrapper>
      </CustomizedDialogs>

      {/* Telegram Chat Id Update */}
      <CustomizedDialogs
        open={editOpen}
        onClose={() => {
          setIsEditOpen(false);
          setIsSuccessUpdate(false);
        }}
        title="Update Telegram chat ID"
      >
        <ModalWrapper>
          <ModalWrapper2 style={{ rowGap: 25, height: 200 }}>
            {isSuccessUpdate ? (
              <>
                <ModalContent>Congratulations</ModalContent>
                <CongrestText>
                  Your telegram notification has been enabled.
                </CongrestText>
                <HomeText
                  onClick={() => {
                    setIsEditOpen(false), setIsSuccessUpdate(false);
                  }}
                >
                  Back to Home
                </HomeText>
              </>
            ) : (
              <>
                <ModalContent>
                  Update the Chat ID
                  <br />
                  to get notifications
                </ModalContent>
                <WrapperText>
                  <TgInput
                    type="number"
                    placeholder="Chat ID"
                    value={id}
                    onChange={handleChange}
                    style={{ border: "1px solid #ccc", borderRadius: "20px" }}
                  />
                  {isLoading ? (
                    <SubmitButton>
                      <ClipLoader color="white" size={16} /> &nbsp;&nbsp;Update
                    </SubmitButton>
                  ) : (
                    <SubmitButton onClick={Update}>Update</SubmitButton>
                  )}
                </WrapperText>
              </>
            )}
          </ModalWrapper2>
        </ModalWrapper>
      </CustomizedDialogs>
      {/* Telegram Chat Id Update */}

      <CustomizedDialogs
        open={openWhatsApp}
        onClose={handleModalClose}
        title={
          updateWhatsAppId ? "Update WhatsApp Number " : "Whatsapp Notification"
        }
      >
        <WhatsappModal
          onClose={handleModalClose}
          isUpdate={updateWhatsAppId}
          whatsAppId={defaultWhatsAppId}
        />
      </CustomizedDialogs>

      {/* WhatsApp Mobile Number Update */}
      {/* <CustomizedDialogs
        open={updateWhatsAppId}
        onClose={() => {
          setUpdateWhatsAppId(false);
        }}
        title="Update WhatsApp Mobile Number "
      >
        <ModalWrapper>
          <ModalWrapper2 style={{ rowGap: 25, height: 200 }}>
            {isSuccessUpdate ? (
              <>
                <ModalContent>Congratulations</ModalContent>
                <CongrestText>
                  Your Whatsapp notification has been enabled.
                </CongrestText>
                <HomeText
                  onClick={() => {
                    setIsEditOpen(false), setIsSuccessUpdate(false);
                  }}
                >
                  Back to Home
                </HomeText>
              </>
            ) : (
              <>
                <ModalContent>
                  Update the Mobile Number
                  <br />
                  to get notifications
                </ModalContent>
                <WrapperText>
                  <TgInput
                    type="number"
                    placeholder="Chat ID"
                    value={id}
                    onChange={handleChange}
                    style={{ border: "1px solid #ccc", borderRadius: "20px" }}
                  />
                  {isLoading ? (
                    <SubmitButton>
                      <ClipLoader color="white" size={16} /> &nbsp;&nbsp;Update
                    </SubmitButton>
                  ) : (
                    <SubmitButton onClick={Update}>Update</SubmitButton>
                  )}
                </WrapperText>
              </>
            )}
          </ModalWrapper2>
        </ModalWrapper>
      </CustomizedDialogs> */}
      {/* WhatsApp Mobile Number Update */}

      <Success
        Message="Success"
        Text="Telegram Chat Id Updated"
        handleClose={() => setUpdate(false)}
        open={isUpdate}
        isInfo={true}
        position={false}
      />
      <Success
        Message="Success"
        Text="Telegram Alert Enabled"
        handleClose={() => setEnable(false)}
        open={isEnable}
        isInfo={true}
        position={false}
      />
    </div>
  );
};
export default NotificationConfig;
