import React from "react";
import { Mobile } from "components/LandingPage/MediaQuery/Query";
import {
  DowloadLink,
  GuideLineContainer,
  GuidelineDownloadButton,
  SpaceBetween,
  Title,
  Typography,
  TypographyWrapper,
} from "./guidlines.styles";
// import WebFont from "webfontloader";

const DappsFontFamily = () => {
  return (
    <>
      <GuideLineContainer>
        <SpaceBetween>
          <Title>Typography</Title>
          <DowloadLink href="https://fonts.google.com/download?family=Montserrat">
            <GuidelineDownloadButton>Download Font</GuidelineDownloadButton>
          </DowloadLink>
        </SpaceBetween>

        <TypographyWrapper>
          <Typography>Font:</Typography>
          <Typography>&nbsp;Montserrat</Typography>
        </TypographyWrapper>
      </GuideLineContainer>
    </>
  );
};

export default DappsFontFamily;
