import React from "react";
import {
  GuideLineContainer,
  GuidelineDownloadButton,
  Image,
  LogoWrapper,
  Logos,
  SpaceBetween,
  Title,
} from "./guidlines.styles";

import Logo1 from "../../assets/Guidelines/dapps_logo_one.png";
import Logo2 from "../../assets/Guidelines/dapps_logo_two.png";
import Logo3 from "../../assets/Guidelines/dapps_logo_three.png";
import Logo4 from "../../assets/Guidelines/dapps_logo_four.png";
import { saveAs } from "file-saver";
import JSZip from "jszip";

const DappsLogo = [
  {
    id: 1,
    logoIcon: Logo1,
  },
  {
    id: 2,
    logoIcon: Logo2,
  },
  {
    id: 3,
    logoIcon: Logo3,
  },
  {
    id: 4,
    logoIcon: Logo4,
  },
];

const LogoUsage = () => {
  const handleDownload = async () => {
    const zip = new JSZip();
    // Add PNG files to the zip archive
    const file1 = await fetch(Logo1).then((res) => res.blob());
    zip.file("dappsLogo1.png", file1);
    const file2 = await fetch(Logo2).then((res) => res.blob());
    zip.file("dappsLogo2.png", file2);
    const file3 = await fetch(Logo3).then((res) => res.blob());
    zip.file("dappsLogo3.png", file3);
    const file4 = await fetch(Logo4).then((res) => res.blob());
    zip.file("dappsLogo4.png", file4);

    // Generate the zip file and download it
    const content = await zip.generateAsync({ type: "blob" });
    saveAs(content, "dappsLogo.zip");
  };

  return (
    <>
      <GuideLineContainer bgColor="secondary">
        <SpaceBetween>
          <Title>Logo Usage</Title>
          <GuidelineDownloadButton onClick={handleDownload}>
            Download Logos
          </GuidelineDownloadButton>
        </SpaceBetween>
        <LogoWrapper>
          {DappsLogo?.map((logo) => {
            return (
              <>
                <Logos key={logo.id}>
                  <Image src={logo.logoIcon} />
                </Logos>
              </>
            );
          })}
        </LogoWrapper>
      </GuideLineContainer>
    </>
  );
};

export default LogoUsage;
