import React from "react";
import {
  Column,
  GuideLineContainer,
  GuidelineDownloadButton,
  IconWrapper,
  Icons,
  Image,
  MainIconWrapper,
  SpaceBetween,
  Title,
} from "./guidlines.styles";

import MainIcon from "../../assets/Guidelines/mainIcon.png";
import Iconone from "../../assets/Guidelines/icon_one.png";
import IconTwo from "../../assets/Guidelines/icon_two.png";
import IconThree from "../../assets/Guidelines/icon_three.png";
import IconFour from "../../assets/Guidelines/icon_four.png";
import { saveAs } from "file-saver";
import JSZip from "jszip";

const DappsIcons = [
  {
    id: 1,
    socialIcon: MainIcon,
  },
  {
    id: 2,
    socialIcon: Iconone,
  },
  {
    id: 3,
    socialIcon: IconTwo,
  },
  {
    id: 4,
    socialIcon: IconThree,
  },
  {
    id: 5,
    socialIcon: IconFour,
  },
];

const DappsIcon = () => {
  const handleDownload = async () => {
    const zip = new JSZip();
    // Add PNG files to the zip archive
    const file1 = await fetch(MainIcon).then((res) => res.blob());
    zip.file("Favicon.png", file1);
    const file2 = await fetch(MainIcon).then((res) => res.blob());
    zip.file("SocialIcon1.png", file2);
    const file3 = await fetch(Iconone).then((res) => res.blob());
    zip.file("SocialIcon2.png", file3);
    const file4 = await fetch(IconTwo).then((res) => res.blob());
    zip.file("SocialIcon3.png", file4);
    const file5 = await fetch(IconThree).then((res) => res.blob());
    zip.file("SocialIcon4.png", file5);
    const file6 = await fetch(IconFour).then((res) => res.blob());
    zip.file("SocialIcon5.png", file6);

    // Generate the zip file and download it
    const content = await zip.generateAsync({ type: "blob" });
    saveAs(content, "socialIcons.zip");
  };
  return (
    <>
      <GuideLineContainer>
        <SpaceBetween>
          <Title>Favicons</Title>
          <GuidelineDownloadButton onClick={handleDownload}>
            Download Icons
          </GuidelineDownloadButton>
        </SpaceBetween>

        <Column>
          <MainIconWrapper>
            <Image src={MainIcon} />
          </MainIconWrapper>

          <SpaceBetween>
            <Title>Social Icons</Title>
          </SpaceBetween>

          <IconWrapper>
            {DappsIcons?.map((icons) => {
              return (
                <>
                  <Icons key={icons.id}>
                    <Image src={icons.socialIcon} />
                  </Icons>
                </>
              );
            })}
          </IconWrapper>
        </Column>
      </GuideLineContainer>
    </>
  );
};

export default DappsIcon;
