import { signTypedMessage } from "eth-sig-util";
import { Contract, ethers } from "ethers";
import forwarderAbi from "../../src/constants/abi/Hermes_abis/MinimalForwarder2.json";
import { gaslessTxn, gaslessTxnMetamask } from "./useGasless";

const apiUrl = "https://hermes-testnet.dapps.co/";

const EIP712Domain = [
  { name: "name", type: "string" },
  { name: "version", type: "string" },
  { name: "chainId", type: "uint256" },
  { name: "verifyingContract", type: "address" },
];

const ForwardRequest = [
  { name: "from", type: "address" },
  { name: "to", type: "address" },
  { name: "value", type: "uint256" },
  { name: "gas", type: "uint256" },
  { name: "nonce", type: "uint256" },
  { name: "data", type: "bytes" },
];

function getMetaTxTypeData(chainId, verifyingContract) {
  return {
    types: {
      EIP712Domain,
      ForwardRequest,
    },
    domain: {
      name: "MinimalForwarder",
      version: "0.0.1",
      chainId,
      verifyingContract,
    },
    primaryType: "ForwardRequest",
  };
}

async function buildRequest(
  forwarder,
  input,
  signer,
  params,
  abi,
  functionName,
  targetContractAddress
) {
  const nonce = Number(await forwarder.getNonce(await signer.getAddress()));
  console.log("nonce of signer", nonce, targetContractAddress);
  const targetContract = new ethers.Contract(
    targetContractAddress,
    abi,
    signer
  );

  //  const estimatedGas = await targetContract[functionName](...params);
  const estimatedGas = await targetContract.estimateGas[functionName](
    ...params
  );
  console.log("estimated gas", Number(estimatedGas));

  return { value: 0, gas: Number(estimatedGas) + 100000, nonce, ...input };
}

async function buildTypedData(forwarder, request) {
  const chainId = await forwarder.provider.getNetwork().then((n) => n.chainId);
  const typeData = getMetaTxTypeData(chainId, forwarder.address);
  return { ...typeData, message: request };
}

async function signMetaTxRequest(
  forwarderAddress,
  privKey,
  provider,
  targetContractAddress,
  functionName,
  abi,
  params
) {
  const wallet = new ethers.Wallet(
    privKey,
    new ethers.providers.JsonRpcProvider(provider)
  );
  const forwarder = new ethers.Contract(
    forwarderAddress,
    forwarderAbi.abi,
    wallet
  );

  const targetContract = new ethers.Contract(
    targetContractAddress,
    abi,
    wallet
  ) as Contract;

  console.log("targetContract", targetContract, functionName);

  const { data } = await targetContract.populateTransaction[functionName](
    ...params
  );

  const input = {
    data: data,
    from: wallet.address,
    to: targetContractAddress,
  };
  const request = await buildRequest(
    forwarder,
    input,
    wallet,
    params,
    abi,
    functionName,
    targetContractAddress
  );
  console.log("-------a", request);

  const toSign: any = await buildTypedData(forwarder, request);
  console.log("-------a", toSign);

  // const sig = await window.ethereum.request(d
  //   {
  //     method: "eth_signTypedData_v4",
  //     params: [await signer.getAddress(), JSON.stringify(toSign)],
  //   },
  //   function (err, result) {
  //     if (err) {
  //       return console.error(err);
  //     }
  //     const signature = result.result.substring(2);
  //     console.log("sig", signature);

  //     // const r = "0x" + signature.substring(0, 64);
  //     // const s = "0x" + signature.substring(64, 128);
  //     // const v = parseInt(signature.substring(128, 130), 16);
  //     // The signature is now comprised of r, s, and
  //   }
  // );
  // const signture = await signer._signTypedData(
  //   toSign.domain,
  //   toSign.types,
  //   request
  // );

  // console.log("sig", signature, "req--", request);

  // const recover = await forwarderContract.verify(request, signature);
  // console.log("recover", recover);

  // const privKey =
  //   "0x5de4111afa1a4b94908f83103eb1f1706367c2e68ca870fc3fb9a804cdab365a";
  const privateKey = Buffer.from(privKey.replace(/^0x/, ""), "hex");

  // const wallet = new ethers.Wallet(privKey);
  // const tx = await wallet.signTransaction(input);

  const signature = signTypedMessage(privateKey, { data: toSign }, "V4");

  // submit(request, sig, toSign);
  const verify = await forwarder.verify(request, signature);
  console.log("sig", verify, signature);

  return { request, signature };
}
//
async function signMetaTxRequestMetamask(
  forwarderAddress,
  signer,
  provider,
  targetContractAddress,
  functionName,
  abi,
  params
) {
  console.log("-------a ", ...params, await signer.getAddress());

  const forwarder = new ethers.Contract(
    forwarderAddress,
    forwarderAbi.abi,
    signer
  );
  console.log(
    signer.address,
    await forwarder.getNonce(await signer.getAddress())
  );
  const targetContract = new ethers.Contract(
    targetContractAddress,
    abi,
    signer
  );

  const { data } = await targetContract.populateTransaction[functionName](
    ...params
  );
  console.log("--");

  const input = {
    data: data,
    from: await signer.getAddress(),
    to: targetContractAddress,
  };
  const request = await buildRequest(
    forwarder,
    input,
    signer,
    params,
    abi,
    functionName,
    targetContractAddress
  );
  console.log("-------a", request);

  const toSign = await buildTypedData(forwarder, request);
  console.log("-------a", toSign);

  const signature = await window.ethereum.request(
    {
      method: "eth_signTypedData_v4",
      params: [await signer.getAddress(), JSON.stringify(toSign)],
    },
    function (err, result) {
      if (err) {
        return console.error(err);
      }
      const signature2 = result.result.substring(2);
      console.log("sig2", signature2);

      // const r = "0x" + signature.substring(0, 64);
      // const s = "0x" + signature.substring(64, 128);
      // const v = parseInt(signature.substring(128, 130), 16);
      // The signature is now comprised of r, s, and
    }
  );
  // const signture = await signer._signTypedData(
  //   toSign.domain,
  //   toSign.types,
  //   request
  // );

  // console.log("sig", signature, "req--", request);

  // submit(request, sig, toSign);
  const verify = await forwarder.verify(request, signature);
  console.log("sig", verify, signature);
  // let verify2 = await forwarder.verifySigner(request, signature);
  // console.log("sig",verify, verify2);

  return { request, signature };
}
export async function gaslessTxnHermes(
  succesFallback,
  errorFallback,
  privKeyOrSigner,
  chainID,
  contractAddress,
  contractAbi,
  functionName,
  params,
  isPrivateKey
) {
  console.log(
    "privKeyOrSigner",
    privKeyOrSigner.substring(0,5),
    chainID,
    contractAddress,
    functionName,
    params,
    isPrivateKey
  );
  let provider;
  let forwarder;
  if (chainID === 200202) {
    provider = "https://rpc-devnet-algorand-rollup.a1.milkomeda.com";
    forwarder = "";
  } else if (chainID === 8080) {
    provider = "https://liberty10.shardeum.org/";
    forwarder = "";
  } else if (chainID === 8081) {
    provider = "https://liberty20.shardeum.org/";
    forwarder = "0x700CCB796874829DfAF93A175de9560f4e7d4E34";
  } else if (chainID === 56) {
    forwarder = "0x612Aa0749f3e3670710b3bAcaa988aF1a0D3176a";
    provider = "https://rpc.ankr.com/bsc";
  } else if (chainID === 137) {
    forwarder = "0xc0e2F1aB33b19DFb5FAea966E1bDC4E955858ecd";
    provider = "https://polygon-rpc.com/";
  } else if (chainID == 250) {
    forwarder = "0xe48F49fC84418EE46ab2816EB4Dd816418754aF7";
    provider = "https://endpoints.omniatech.io/v1/fantom/mainnet/public";
  } else if (chainID == 997) {
    forwarder = "0x77848747CF7aE80310b081CB4926e52270E0baA5";
    provider = "https://rpc-testnet.5ire.network";
  }

  let Request;
  let Signature;
  if (isPrivateKey) {
    const { request, signature } = await signMetaTxRequest(
      forwarder,
      privKeyOrSigner,
      provider,
      contractAddress,
      functionName,
      contractAbi,
      params
    );

    Request = request;
    Signature = signature;
  } else {
    const providerM = new ethers.providers.Web3Provider(window.ethereum);
    const signer = providerM.getSigner();
    console.log("signer", await signer.getAddress());
    const { request, signature } = await signMetaTxRequestMetamask(
      forwarder,
      signer,
      provider,
      contractAddress,
      functionName,
      contractAbi,
      params
    );

    Request = request;
    Signature = signature;
  }

  const token =
    "eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCIsImtpZCI6Ik5qOEozakNNNGhKUWdiWHRGOXJPTSJ9.eyJpc3MiOiJodHRwczovL2Rldi0ta3EtdjgxNC51cy5hdXRoMC5jb20vIiwic3ViIjoiajBQR2E0aGZIQUE1T2d4cnhpdUdscHptQ2pOOHB5RXhAY2xpZW50cyIsImF1ZCI6Imh0dHBzOi8vZGV2LS1rcS12ODE0LnVzLmF1dGgwLmNvbS9hcGkvdjIvIiwiaWF0IjoxNjc0NDYyMzAyLCJleHAiOjE2NzcwNTQzMDIsImF6cCI6ImowUEdhNGhmSEFBNU9neHJ4aXVHbHB6bUNqTjhweUV4IiwiZ3R5IjoiY2xpZW50LWNyZWRlbnRpYWxzIn0.vdMW0gCHaoLKWuSreJ0jXPxI8n4sw3rgLJQWyuf9ikO5KSwMonTJDwaJGNgI6tA5-S6nWkUxhEwyTr5FcHlQcgGKr5WDSL2e7MgLb010jWfxjbrug-xQQ-tc7usaKa7V33gLmLAdBqWWSPO5nmf3YxiiqGwD2Akm1thNoxYdbmKMgxg0Zk4NdS0Z6j99yHC9ZdbY_P4Au-Chce8OgPS1BKm7PfYP8UGBVZrBm0aoagJgxPBujVijHmpkwIYm0Hf0MblF5iP8HkVYGd33dbI7JSGU6dx5UOgef7eLYKTPiq-B9ylju81I8n-twgPaBvNPdo23Y-Equ78emCpJLmpW0A";

  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },

    body: JSON.stringify({
      signature: Signature,
      txnRequest: Request,
      providerURL: provider,
      gasLimit: Request.gas,
    }),
  };

  console.log("done, signature", Signature);
  const response = await fetch(apiUrl + "api/executeMetaTxn", requestOptions);
  const data = await response.json();
  const object = JSON.parse(data);
  console.log(object, object.Status);

  if (object.Status !== 200) {
    await errorFallback();
    // Sending gasless tx via biconomy as a fallback
    // if (isPrivateKey === false) {
    //   gaslessTxnMetamask(
    //     succesFallback,
    //     errorFallback,
    //     chainID,
    //     contractAddress,
    //     contractAbi,
    //     functionName,
    //     params
    //   );
    // } else {
    //   await gaslessTxn(
    //     succesFallback,
    //     errorFallback,
    //     privKeyOrSigner,
    //     chainID,
    //     contractAddress,
    //     contractAbi,
    //     functionName,
    //     params
    //   );
    // }
  } else {
    await succesFallback();
  }

  return object;
}
