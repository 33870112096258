import { OneToOneMessages } from "store/reducer";
import { Message, UserMessages } from "types/message";
import { concat } from "lodash";

export const formatMessages = (
  allMessages: Message[],
  primaryWallet: string,
  secondryWallet: string
): OneToOneMessages => {
  const uniqueMessages = [
    ...new Map(allMessages?.map((item) => [item?.id, item])).values(),
  ];

  const contactList1 = uniqueMessages?.map((item) => item.from?.toLowerCase());
  const contactList2 = uniqueMessages?.map((item) => item.to?.toLowerCase());
  const contactList = [...new Set(concat(contactList1, contactList2))];

  const filteredMessages = contactList?.map((el) => {
    return {
      recieverAddress: el,
      messages: allMessages?.filter(
        (i) =>
          (i?.from?.toLowerCase() === primaryWallet?.toLowerCase() &&
            i?.to?.toLowerCase() === el?.toLowerCase()) ||
          (i?.to?.toLowerCase() === primaryWallet?.toLowerCase() &&
            i?.from?.toLowerCase() === el?.toLowerCase()) ||
          (i?.from?.toLowerCase() === secondryWallet?.toLowerCase() &&
            i?.to?.toLowerCase() === el?.toLowerCase()) ||
          (i?.to?.toLowerCase() === secondryWallet?.toLowerCase() &&
            i?.from?.toLowerCase() === el?.toLowerCase())
      ),
    };
  });

  const allFilteredMessages: UserMessages[] = [];
  const allFilteredNewRequests: UserMessages[] = [];

  for (let i = 0; i < filteredMessages?.length; i++) {
    const isMessage = filteredMessages?.[i]?.messages?.map(
      (el) =>
        el?.from?.toLowerCase() === primaryWallet?.toLowerCase() ||
        el?.from?.toLowerCase() === secondryWallet?.toLowerCase()
    );

    isMessage?.includes(true)
      ? allFilteredMessages.push(filteredMessages[i])
      : allFilteredNewRequests.push(filteredMessages[i]);
  }

  const messages = allFilteredMessages?.filter(
    (el) => el?.messages?.length !== 0
  );
  const newRequests = allFilteredNewRequests?.filter(
    (el) => el?.messages?.length !== 0
  );

  return { messages, newRequests } as OneToOneMessages;
};
