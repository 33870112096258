import { Container, Grid } from "@mui/material";
import Header from "components/Appbar";
import Footer from "components/Footer";
import React, { useContext } from "react";
import { useLocation } from "react-router-dom";
import Sidebar from "./SideBar";
import { TrandingData } from "const/Data";
import {
  ContentWrap,
  ImageDiv,
  SideBarWrapper,
  StickyDiv,
} from "./SidebarWrapper.style";
import DappsContext from "AuthenticationContext/Context";
import DappsText from "../../assets/images/dapps.png";
import {
  useIpad,
  useIpad2,
  useIpad3,
  useIpad4,
  useIpad5,
  useMobile,
  useMobile2,
  useMobile3,
} from "const/MuiMediaQuery";
import { UploadHead2 } from "components/Subscription/subscription.style";
import Trending from "components/User/Trending";
import TrendingIcon from "assets/images/trandingIcon.png";
interface isProps {
  children: React.ReactNode;
}
const SidebarWrapper = ({ children }: isProps) => {
  const location = useLocation();
  const mobile = useMobile();
  const mobile2 = useMobile2();
  const mobile3 = useMobile3();

  const ipad = useIpad();
  const ipad2 = useIpad2();
  const ipad3 = useIpad3();
  const ipad4 = useIpad5();
  const dappContext = useContext(DappsContext);
  // console.log(location.pathname);
  return (
    <>
      {location?.pathname == "/user/authentication" ||
      location?.pathname == "/compose-message" ||
      location?.pathname == "/ebook" ||
      location?.pathname == "/grants" ||
      location?.pathname == "/ContactUs" ||
      location?.pathname == "/AboutUs" ||
      location?.pathname == "/FAQ" ||
      location?.pathname == "/Steps" ||
      location?.pathname == "/loading" ||
      location?.pathname == "/googlelogin" ||
      location?.pathname == "/hedera" ||
      location?.pathname == "/loading" ||
      location?.pathname == "/create-ebook" ||
      location?.pathname == "/preview-ebook" ||
      location.pathname == "/network-connector" ||
      location.pathname == "/PageBuilder" ||
      location.pathname == "/chat-notification" ||
      location.pathname == "/landing-page" ||
      location.pathname == "/guidelines" ||
      location.pathname == "/termsandcondition" ||
      location.pathname === "/trusted-partners" ||
      location.pathname === "/transaction-logs" ? null : (
        <SideBarWrapper>
          <Container
            maxWidth="xl"
            style={{ padding: mobile || ipad ? "20px 22px" : "20px 40px" }}
          >
            <Grid
              container
              // style={{
              //   marginTop:
              //     dappContext.transactions.length > 0
              //       ? "6.5rem"
              //       : dappContext.transactions.length > 0 &&
              //         dappContext.isHellobar
              //       ? "9.5rem"
              //       : dappContext.isHellobar
              //       ? "8rem"
              //       : "5.5rem",
              // }}
            >
              <Grid
                item
                md={2.5}
                style={{
                  display:
                    mobile || mobile2 || mobile3 || ipad || ipad2 || ipad4
                      ? "none"
                      : null,
                }}
              >
                <StickyDiv>
                  <Sidebar />
                  {/* <ImageDiv
                  src={DappsText}
                  width={102}
                  style={{ float: "right", marginTop: -7 }}
                /> */}
                  {/* {location?.pathname == "/homepage" ? (
                    <div style={{ width: "92%" }}>
                      <UploadHead2 style={{ paddingTop: 16 }}>
                        <img src={TrendingIcon} width={23} />
                        Trending Apps
                      </UploadHead2>
                      <Trending trendingData={TrandingData} />
                    </div>
                  ) : null} */}
                </StickyDiv>
              </Grid>
              <Grid item lg={9.5} xs={12} md={12} sm={12}>
                {children}
              </Grid>
            </Grid>
            <ContentWrap>
              {/* <div style={{width:'100%',height:'auto'}}>{children}</div> */}
            </ContentWrap>
          </Container>
          {location.pathname == "/landing-page" ||
          location.pathname === "/trusted-partners" ? null : (
            <Footer />
          )}
        </SideBarWrapper>
      )}
    </>
  );
};
export default SidebarWrapper;
