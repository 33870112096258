import MessagingContract_NEW_PRODUCTION from "./messaging_contract_abis/1.MessagingContract_new_production.json";
import MessagingContract_DAPPS from "./messaging_contract_abis/2.MessagingContract_dapps.json";

// eslint-disable-next-line no-undef
const environment = process.env.REACT_APP_ENVIRONMENT;

const get_messagingContractABI = (environment) => {
  switch (environment) {
    case "DAPPS_NEW_PRODUCTION":
      return MessagingContract_NEW_PRODUCTION;

    case "DAPPS":
      return MessagingContract_DAPPS;

    default:
      return MessagingContract_DAPPS;
  }
};
const messagingContractABI = get_messagingContractABI(environment);

export default messagingContractABI;
