import AuthenticationState from "AuthenticationContext/State";
import SidebarWrapper from "components/SideBarWrapper";
import React, { lazy, Suspense, useEffect, useState } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  useParams,
  useLocation,
} from "react-router-dom";
import { useWeb3React, Web3ReactProvider } from "@web3-react/core";
import {
  ExternalProvider,
  JsonRpcFetchFunc,
  Web3Provider,
} from "@ethersproject/providers";
import { webRoutes } from "routes";
import { Provider } from "react-redux";
import { Web3Modal } from "./components/Web3Modal";
import Web3ReactManager from "./components/Web3ReactManager";
import { SpamProtectionState } from "./Context/SpamContext";
import store from "./store/index";
import "./App.css";
import { HashConnectAPIProvider } from "HashConnectAPIProvider-1.10";
import Footer from "components/Footer";
import LoadingCompoent from "components/LoadingComponent";
// import Header from "components/Appbar";
import QueryResolvers from "components/QueryResolver";
// import "./DappsWidgets";
const Header = lazy(() => import("components/Appbar/index"));
const Authenticaion = lazy(() => import("./page/Authentication/index"));
const Messanger = lazy(() => import("page/MessageInbox"));

const HomePage = lazy(() => import("page/index"));
const Ebook = lazy(() => import("page/Ebbok/index"));

const AboutUs = lazy(() => import("components/AboutUs/AboutUs"));
const ContactUs = lazy(() => import("components/ContactUs/ContactUs"));
// import AboutUs from "components/Footer/AboutUs";
// import { ContactUs } from "components/Footer/ContactUs
const GoogleAuth = lazy(() => import("page/GoogleAuth/index"));
const HederaNew = lazy(() => import("page/Hedera/index"));
const TextLoading = lazy(() => import("components/TextLoading/index"));
const FAQ = lazy(() => import("components/FAQ/Faq"));
const TGSteps = lazy(() => import("components/TGSteps/TgSteps"));
const CreateEbook = lazy(() => import("page/Ebbok/CreateEbook"));
const NetworkPage = lazy(() => import("components/Profile/Admin/NetworkList"));
const TransactionLogs = lazy(() => import("page/TransactionLogs/index"));
const ChatNotification = lazy(() => import("page/ChatNotification/index"));
const LandingPage = lazy(() => import("page/LandingPage/index"));
const TrustedPartners = lazy(() => import("page/TrustedPartners/index"));
import OneSignal from "react-onesignal";
// import FAQ from "components/Footer/Faq";
import jwtDecode from "jwt-decode";
import setuHttp from "utilities/http";
// import TGSteps from "components/TGSteps/TgSteps";
import PageBuilder from "page/PageBuilder/index";
import SocialAuthContext from "./Context/AuthContext";
import { gapi } from "gapi-script";
import { googleAuthAPIKey } from "./constants";
import TermsCondition from "components/LandingPage/TermsCondition";
import Guidelines from "components/Guidelines";
import UserHomePage from "page/User";

const App = () => {
  const getLibrary = (provider: ExternalProvider | JsonRpcFetchFunc) => {
    return new Web3Provider(provider);
  };
  const [isShow, setShow] = useState(false);

  useEffect(() => {
    gapi.load("client:auth2", () => {
      gapi.client.init({
        clientId: googleAuthAPIKey,
        plugin_name: "faucets-app",
      });
    });
  }, []);

  useEffect(() => {
    async function initializeOneSignal() {
      await OneSignal.init({
        appId: `${process.env.REACT_APP_ONE_SIGNAL_APP_ID}`,
        notifyButton: {
          enable: true,
        },
        allowLocalhostAsSecureOrigin: true,
      });
    }
    initializeOneSignal();

    async function getRpcJwt() {
      try {
        const cachedJwt = localStorage.getItem("DAPPS_JWT");
        if (cachedJwt) {
          const decodedJwt: any = jwtDecode(cachedJwt);
          // console.log("cached jwt decoded: ", decodedJwt);
          if (decodedJwt.exp > new Date().getTime() / 1000) return;
        }

        const res = await setuHttp.get("/api/getJwt");
        // console.log("new jwt: ", res.data.token);
        localStorage.setItem("DAPPS_JWT", res.data.token);
      } catch (error) {
        console.log("Error in getRpcJwt(): ", error);
      }
    }
    getRpcJwt();
  }, []);
  // const location = useLocation();
  const params = useParams();
  console.log("params", params);

  return (
    <div className="App">
      {/* <weather-card longitude='85.8245' latitude='20.296'></weather-card> */}

      <Provider store={store}>
        <AuthenticationState>
          <SpamProtectionState>
            <Web3ReactProvider getLibrary={getLibrary}>
              <SocialAuthContext>
                <HashConnectAPIProvider debug>
                  <Router>
                    <Suspense fallback={<LoadingCompoent />}>
                      <Header />

                      <Suspense fallback={<LoadingCompoent />}>
                        <Routes>
                          {/* <Route path="/" element={<UserHomePage />} /> */}
                          {/* <Route path="/" element={<HomePage />} /> */}
                          <Route path="/googlelogin" element={<GoogleAuth />} />
                          <Route path="/hedera" element={<HederaNew />} />
                          <Route
                            path="/user/authentication"
                            element={<Authenticaion />}
                          />
                          <Route
                            path="/compose-message"
                            element={<Messanger />}
                          />
                          <Route path="/ebook" element={<Ebook />} />
                          <Route path="/grants" element={<Ebook />} />
                          <Route path="/AboutUs" element={<AboutUs />} />
                          <Route path="/ContactUs" element={<ContactUs />} />
                          <Route path="/FAQ" element={<FAQ />} />
                          <Route path="/loading" element={<TextLoading />} />
                          <Route path="/Steps" element={<TGSteps />} />

                          <Route
                            path="/create-ebook"
                            element={<CreateEbook />}
                          />

                          <Route
                            path="/network-connector"
                            element={<NetworkPage />}
                          />
                          <Route
                            path="/PageBuilder"
                            element={<PageBuilder />}
                          />
                          <Route
                            path="/chat-notification"
                            element={<ChatNotification />}
                          />
                          <Route
                            path="/landing-page"
                            element={<LandingPage />}
                          />
                          <Route path="/guidelines" element={<Guidelines />} />
                          <Route
                            path="/termsandcondition"
                            element={<TermsCondition />}
                          />
                          <Route
                            path="/trusted-partners"
                            element={<TrustedPartners />}
                          />
                          <Route
                            path="/transaction-logs"
                            element={<TransactionLogs />}
                          />
                        </Routes>

                        <SidebarWrapper>
                          <Routes>
                            {webRoutes.map((rt, index) => {
                              return (
                                <Route
                                  path={rt.path}
                                  element={rt.element}
                                  key={index}
                                />
                              );
                            })}
                          </Routes>
                        </SidebarWrapper>
                      </Suspense>
                    </Suspense>
                  </Router>
                  <QueryResolvers />
                  <Web3ReactManager />
                </HashConnectAPIProvider>
              </SocialAuthContext>
            </Web3ReactProvider>
          </SpamProtectionState>
        </AuthenticationState>
      </Provider>
    </div>
  );
};

export default App;
