import React from "react";

import {
  FooterBottom,
  FooterBottomWrapper,
  FooterContent,
  FooterData,
  FooterImage,
  FooterShareWrapper,
  FooterWrapper,
  LinkWrapper,
} from "./LandingPageStyle";
import { useNavigate, Link } from "react-router-dom";
import logo from "assets/LandingPage/logo.png";
import { Typography } from "@mui/material";
import icon1 from "assets/LandingPage/icon1.png";
import icon2 from "assets/LandingPage/icon2.png";
import icon3 from "assets/LandingPage/icon3.png";
import FooterLogo from "assets/LandingPage/Footer-logo.png";
import { useMobile } from "const/MuiMediaQuery";
const Footer = () => {
  const navigate = useNavigate();
  const mobile = useMobile();
  return (
    <FooterWrapper>
      <img
        src={logo}
        width={131.28}
        height={36}
        style={{ cursor: "pointer" }}
        onClick={() => navigate("/")}
      />
      {mobile ? null : <FooterImage src={FooterLogo} />}
      <FooterContent>
        <FooterData>
          <Typography variant="subtitle1">Quick links</Typography>
          <LinkWrapper>
            <a
              href="https://discord.gg/bXBm5PQueS"
              target="_blank"
              rel="noopener noreferrer"
            >
              <Typography variant="subtitle2">Support</Typography>
            </a>
            <Link to="/FAQ">
              <Typography variant="subtitle2">FAQs</Typography>
            </Link>

            <Link to="/ContactUs">
              <Typography variant="subtitle2">Contact Us</Typography>
            </Link>
            <a
              href="https://docs.google.com/forms/d/e/1FAIpQLSfoY_mrQ4bvjm3rNdZxJf9Pw_lojYcDL9BCjdYNUlyjgzLF1g/viewform"
              target="_blank"
              rel="noopener noreferrer"
            >
              <Typography variant="subtitle2">Apply for grants</Typography>
            </a>
          </LinkWrapper>
        </FooterData>
        <FooterData>
          <Typography variant="subtitle1">Features</Typography>
          <LinkWrapper>
            {/* <Typography variant="subtitle2">Decentralized appstore</Typography> */}

            <Link to="/compose-message">
              <Typography
                variant="subtitle2"
                // onClick={() => navigate("/compose-message")}
              >
                Unstoppable messaging
              </Typography>
            </Link>

            <Link to="/notification">
              <Typography
                variant="subtitle2"
                onClick={() => navigate("/notification")}
              >
                Notifications
              </Typography>
            </Link>
            <a
              href="https://feed.dapps.co/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <Typography variant="subtitle2">dapps Feed</Typography>
            </a>
          </LinkWrapper>
        </FooterData>
        <FooterData>
          <Typography variant="subtitle1">About</Typography>
          <LinkWrapper>
            <Link to="/AboutUs">
              <Typography
                variant="subtitle2"
                // onClick={() => navigate("/AboutUs")}
              >
                About us
              </Typography>
            </Link>

            {/* <Link to="/guidelines">
            </Link> */}
            <Typography
              variant="subtitle2"
              onClick={() => navigate("/guidelines")}
            >
              Brand Guidelines
            </Typography>

            <a
              href="https://careers.dapps.co/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <Typography variant="subtitle2">Careers</Typography>
            </a>
          </LinkWrapper>
        </FooterData>

        <FooterData>
          <Typography variant="subtitle1">Join our community</Typography>
          <FooterShareWrapper>
            <a
              href="https://www.linkedin.com/company/dappsco/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={icon1} width={24} style={{ cursor: "pointer" }} />
            </a>
            <a
              href="https://twitter.com/dapps_co?s=21"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={icon2} width={24} style={{ cursor: "pointer" }} />
            </a>
            <a
              href="https://discord.com/invite/SH5pJuj28U"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={icon3} width={24} style={{ cursor: "pointer" }} />
            </a>
          </FooterShareWrapper>
        </FooterData>
      </FooterContent>
      <FooterBottomWrapper>
        <FooterBottom>©2022 dapps.co. All rights reserved.</FooterBottom>

        <FooterBottom
          onClick={() => {
            navigate("/termsandcondition");
          }}
        >
          Terms & Conditions.
        </FooterBottom>
      </FooterBottomWrapper>
    </FooterWrapper>
  );
};
export default Footer;
