/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect } from "react";
import { useState, useContext, createContext } from "react";
import { UserMessages } from "types/message";

interface SpamProtectionContext {
  selectedChat: UserMessages;
  isEnable: boolean;
  token: any;
  anyoneCanMessage: boolean;
  doubleEncryption: boolean;
  userMessage: [];
  // chatHistory: { chats: string }[];
  // replayMessageContent: any;
  tokenAmt: number;
  filterMenu: [];
  settingState: any;
  tabValue: number;
  tabContent: number;
  handleTabValue: (value: any) => any;

  collectFilterMenu: (menu: any) => any;
  removeFilterMenu: (menu: any) => any;

  saveUserSetting: (setting: any) => any;
  handleAnyoneCanMessage: () => void;
  handleUserMessage: (content: any) => any;
  // handleReplyMessage: (reply: any) => any;
  // handleUserChat: (chat: any) => any;
  handleTokenAmount: (amount: any) => any;
  handleTabToken: (token: any) => any;
  enableDoubleEncryption: () => void;
  handleSpamEnable: () => void;
  handleSelectedChat: (newSelectedChat: UserMessages) => void;
  handleHomePageTabContate: (index: any) => any;
}

export const EnableSpamProtection = createContext<SpamProtectionContext>({
  selectedChat: null,
  isEnable: false,
  token: null,
  anyoneCanMessage: false,
  doubleEncryption: false,
  userMessage: [],
  // chatHistory: [],
  // replayMessageContent: [],
  tokenAmt: 0,
  filterMenu: [],
  settingState: {},
  tabValue: 1,
  tabContent: 0,
  handleTabValue: (value: any) => {
    return value;
  },

  collectFilterMenu: (menu: any) => {
    return menu;
  },
  removeFilterMenu: (menu: any) => {
    return menu;
  },

  saveUserSetting: (setting: any) => {
    return setting;
  },
  handleAnyoneCanMessage: () => {
    console.log("");
  },
  handleUserMessage: (content: any) => {
    return content;
  },
  // handleReplyMessage: (reply: any) => {
  //   return reply;
  // },
  // handleUserChat: (chat: any) => {
  //   return chat;
  // },
  handleTokenAmount: (amount: any) => {
    return amount;
  },
  handleTabToken: (token: any) => {
    return token;
  },
  enableDoubleEncryption: () => {
    console.log("");
  },
  handleSpamEnable: () => {
    console.log("");
  },
  handleSelectedChat: (newSelectedChat: UserMessages) => {
    console.log("");
  },
  handleHomePageTabContate: (index: any) => {
    return index;
  },
});

export const useSetting = () => {
  const spamProtection = useContext(EnableSpamProtection);
  return spamProtection;
};

export const SpamProtectionState = (props: any) => {
  const [isEnable, setIsEnable] = useState(false);
  const [selectedChat, setSelectedChat] = useState<UserMessages>();
  const [doubleEncryption, setDoubleEncryption] = useState(false);
  const [token, setToken] = useState(null);
  const [tabContent, setTabContent] = useState(0);
  const [tokenAmt, setTokenAmt] = useState<number>();
  const [anyoneCanMessage, setAnyOneCanMessage] = useState(false);
  const [userMessage, setUserMessage] = useState<any>([
    {
      messageContent: "hello",
    },
  ]);
  // const [chatHistory, setChatHistory] = useState([
  //   {
  //     chats:
  //       "History Lorem ipsum is simply dummy text of the printing since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book Lorem Ipsum is simply dummy text andtypesetting industry. Lorem Ipsum has been the industrys standard dummy text ever",
  //   },
  // ]);
  // const [replayMessageContent, setReplayMessageContent] = useState([
  //   {
  //     reply:
  //       "Reply Lorem ipsum is simply dummy text of the printing since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book Lorem Ipsum is simply dummy text andtypesetting industry. Lorem Ipsum has been the industrys standard dummy text ever",
  //   },
  // ]);
  const [filterMenu, setFilterMenu] = useState<any>([]);
  const [tabValue, setTabValue] = useState<any>("1");

  const [settingState, setSettingState] = useState<any>({});

  const handleSelectedChat = (newSelectedChat: UserMessages) => {
    setSelectedChat(newSelectedChat);
  };

  const handleTabValue = (value: any) => {
    setTabValue(value);
  };

  const collectFilterMenu = (menu: any) => {
    setFilterMenu([...filterMenu, menu]);
  };
  const removeFilterMenu = (menuDelete: any) => {
    setFilterMenu((menu: any) => {
      return menu.filter((menu: any) => menu !== menuDelete);
    });
  };

  const saveUserSetting = (setting: any) => {
    console.log(setting);
    setSettingState(setting);
  };

  const handleUserMessage = (content: any) => {
    setUserMessage([...userMessage, content]);
  };

  // const handleUserChat = (chat: any) => {
  //   setChatHistory([...chatHistory, chat]);
  // };
  // const handleReplyMessage = (reply: any) => {
  //   setReplayMessageContent([...replayMessageContent, reply]);
  // };

  const handleAnyoneCanMessage = () => {
    setAnyOneCanMessage(!anyoneCanMessage);
  };

  const handleTabToken = (tokenName: any) => {
    console.log({ tokenName });
    setToken(tokenName);
  };

  const handleTokenAmount = (amount: any) => {
    setTokenAmt(amount);
  };

  const enableDoubleEncryption = () => {
    setDoubleEncryption(!doubleEncryption);
  };

  const handleSpamEnable = () => {
    // alert(isEnable);
    setIsEnable(!isEnable);
  };

  const handleHomePageTabContate = (index) => {
    setTabContent(index);
  };

  return (
    <EnableSpamProtection.Provider
      value={{
        selectedChat,
        isEnable,
        doubleEncryption,
        anyoneCanMessage,
        token,
        tokenAmt,
        userMessage,
        settingState,
        filterMenu,
        tabValue,
        // chatHistory,
        tabContent,
        // replayMessageContent,
        saveUserSetting,
        handleUserMessage,
        handleTokenAmount,
        handleSpamEnable,
        enableDoubleEncryption,
        handleTabToken,
        handleAnyoneCanMessage,
        collectFilterMenu,
        removeFilterMenu,
        handleTabValue,
        // handleUserChat,
        // handleReplyMessage,
        handleHomePageTabContate,
        handleSelectedChat,
      }}
    >
      {props.children}
    </EnableSpamProtection.Provider>
  );
};
