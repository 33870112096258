import React, { useContext, useState } from "react";

export type SocialAuthContextDataType = {
  /** isLogin */
  isLogin: boolean;
  /** dapps account */
  dappsAccount: string;
  /** dappsChainId */
  dappsChainId: number;
  /** connect */
  isDappsConnected: boolean;
  /** is sign up */
  isSignUp: boolean;
  /** setDappsAccount */
  setDappsAccount: (account: string) => void;
  /** setDappsChainId */
  setDappsChainId: (chainId: number) => void;
  /** setIsDappsConnected */
  setIsDappsConnected: (connected: boolean) => void;
  /** setIsLogin */
  setIsLogin: (login: boolean) => void;
  /** setIsSignUp */
  setIsSignUp: (isSignUp: boolean) => void;
};

// stake modal context
const SocialAuthContextData =
  React.createContext<SocialAuthContextDataType>(null);

// export the stake modal context hook
export const useSocialLoginContext = (): SocialAuthContextDataType => {
  return useContext<SocialAuthContextDataType>(SocialAuthContextData);
};

const SocialAuthContextProvider = ({
  children,
}: {
  children: React.ReactNode;
}): JSX.Element => {
  const [dappsAccount, setDappsAccount] = useState<string>();
  const [dappsChainId, setDappsChainId] = useState<number>();
  const [isDappsConnected, setIsDappsConnected] = useState<boolean>();
  const [isLogin, setIsLogin] = useState<boolean>();
  const [isSignUp, setIsSignUp] = useState<boolean>();

  return (
    <SocialAuthContextData.Provider
      value={{
        dappsAccount,
        isDappsConnected,
        dappsChainId,
        isLogin,
        isSignUp,
        setDappsAccount,
        setDappsChainId,
        setIsDappsConnected,
        setIsLogin,
        setIsSignUp,
      }}
    >
      {children}
    </SocialAuthContextData.Provider>
  );
};

export default SocialAuthContextProvider;
