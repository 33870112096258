import { Button } from "@mui/material";
import { MediaQuery } from "const/MediaQuery";
import React from "react";
import styled from "styled-components";
export const BannerWrapper = styled.div`
  height: 301px;
  left: 296px;
  top: 125px;

  /* Primary/100 */

  background: #a0e5e8;
  border-radius: 20px;
`;
interface isprops {
  isActive: boolean;
}
export const ItemsWrapper = styled.div<isprops>`
width: 555px;
height: 156px;
margin-top:10px;
padding:30px;
/* BG/white */
display: flex;
    flex-direction: column;
    justify-content: center;
    row-gap: 14px;
background: ${(props) => (props.isActive ? "#ECF8F0;" : "#FFFFFF;")}
/* Font/Secondary */

border: ${(props) =>
  props.isActive ? "1px solid #44A55A;" : "1px solid #DCDEDE;"}
border-radius: 25px;
 @media(max-width:430px){
   width:100%;
padding:15px;
height:auto;
  }
  @media(${MediaQuery.Ipad}){
    height:245px;
    text-align:center;
  }
`;
export const ConfigTitle = styled.div`
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 150%;
  /* identical to box height, or 30px */

  /* Font/header */

  color: #0f1f4a;
  @media (max-width: 430px) {
    font-size: 13px;
  }
  @media (${MediaQuery.Ipad}) {
    display: flex;
    align-items: center;
    column-gap: 10px;
  }
`;
export const ConfigDesc = styled.div`
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;
  /* identical to box height, or 24px */

  /* Font/header */

  color: #0f1f4a;
  @media (max-width: 430px) {
    font-size: 12px;
  }
`;
export const ConfigButton = styled(Button)`
  width: 92px !important;
  height: 48px !important;

  /* Primary/default */

  background: #30bcc2 !important;
  box-shadow: 3px 1px 18px rgba(48, 188, 194, 0.47) !important;
  border-radius: 16px !important;
  font-family: "Montserrat" !important;
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 12px !important;
  line-height: 150% !important;
  /* identical to box height, or 18px */

  display: flex !important;
  align-items: center !important;

  /* BG/white */

  color: #ffffff !important;
  @media (max-width: 430px) {
    width: 72px !important;
    height: 38px !important;
    font-size: 12px !important;
    text-transform: capitalize !important;
  }
  @media (${MediaQuery.Ipad}) {
    width: 100% !important;
  }
`;
export const MainWarpper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  @media (${MediaQuery.Ipad}) {
    flex-direction: column;
    row-gap: 10px;
  }
`;
export const ContentWrapper = styled.div`
  display: flex;
  align-items: center;
  column-gap: 10px;
  @media (${MediaQuery.Ipad}) {
    flex-direction: column;
    row-gap: 10px;
  }
`;
export const ItemsNotiWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
  margin-top: 1.5rem;
  @media (max-width: 430px) {
    flex-direction: column;
    row-gap: 10px;
  }
  @media (${MediaQuery.Ipad}) {
    column-gap: 20px;
  }
`;
export const ModalWrapper = styled.div`
  width: 588px;

  display: flex;
  justify-content: center;
  @media (max-width: 430px) {
    width: 100%;
  }
`;
export const ModalWrapper2 = styled.div`
  height: 300px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  row-gap: 15px;
`;
export const ModalContent = styled.div`
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 150%;
  /* or 39px */

  text-align: center;

  /* Font/header */

  color: #0f1f4a;
  @media (max-width: 430px) {
    font-size: 14px;
  }
`;
export const BoatButton = styled(Button)`
  width: 194px !important;
  height: 74px !important;
  text-transform: capitalize !important;
  /* Primary/default */

  background: #30bcc2 !important;
  box-shadow: 3px 1px 18px rgba(48, 188, 194, 0.47) !important;
  border-radius: 20px !important;
  font-family: "Montserrat" !important;
  font-style: normal !important;
  font-weight: 600 !important;
  font-size: 16px !important;
  line-height: 150% !important;
  /* identical to box height, or 24px */

  display: flex !important;
  align-items: center !important;

  /* BG/white */

  color: #ffffff !important;
`;
export const SubmitButton = styled.div`
  width: 130px;
  height: 60px;
  padding: 5px 16px;
  margin-right: 6px;
  border-radius: 20px;
  outline: none;
  border: none;
  background: #30bcc2 !important;
  box-shadow: 3px 1px 18px rgb(48 188 194 / 47%) !important;
  border-radius: 20px !important;
  font-family: "Montserrat";

  font-style: normal !important;
  font-weight: 600 !important;
  font-size: 18px !important;
  color: #ffffff !important;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  @media (max-width: 430px) {
    width: 100% !important;
    height: 48px !important;
    margin-top: 10px;
  }
  @media (min-width: 430px) and (max-width: 896px) {
    height: 60px;
    margin-top: 10px;
    width: 100% !important;
  }
`;

export const CongrestText = styled.div`
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 150%;
  /* identical to box height, or 27px */

  text-align: center;

  /* Font/header */

  color: #0f1f4a;
  @media (max-width: 430px) {
    font-size: 14px;
  }
`;

export const HomeText = styled.div`
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 150%;
  /* identical to box height, or 24px */

  text-align: center;
  text-decoration-line: underline;

  /* Font/header */

  color: #0f1f4a;
  :hover {
    cursor: pointer;
  }
`;

export const IdWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
export const WrapperText = styled.div`
  display: flex;
  align-items: center;
  column-gap: 10px;
  @media (max-width: 430px) {
    flex-direction: column;
    row-gap: 10px;
  }
`;
export const Componentwrapper = styled.div`
  display: flex;
  @media (min-width: 430px) and (max-width: 896px) {
    flex-direction: column;
  }
`;

export const ErrorStateWrapper = styled.div`
  width: 100%;
  padding: 5px;
  color: red;
`;
