import React, { useState, useContext, useEffect } from "react";
import { TgInput } from "components/Homepage/Homepage.style";
import NotificationProgress from "components/Profile/Shared/ProegresBar";
import {
  ModalContent,
  ModalWrapper,
  ModalWrapper2,
  CongrestText,
  HomeText,
  SubmitButton,
  WrapperText,
  Componentwrapper,
  ErrorStateWrapper,
} from "./config.style";
import { useMobile } from "const/MuiMediaQuery";
import { MuiOtpInput } from "mui-one-time-password-input";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import IconButton from "@mui/material/IconButton";
import { InputWrapper } from "components/Appbar/style";
import { auth } from "../../constants/firebaseConfig";
import { RecaptchaVerifier, signInWithPhoneNumber } from "firebase/auth";
import DappsContext from "../../AuthenticationContext/Context";
import { gaslessTxnHermes } from "hooks/useHermes";
import { walletRegistryAddress } from "constants/dappsContract";
import walletRegistryABI from "constants/abi/WalletRegistry";
import { useWeb3React } from "@web3-react/core";
import { utils } from "ethers";
import { ClipLoader } from "react-spinners";
import { ErrorDiv } from "components/Wallet/Wallet.style";

interface ModalProps {
  onClose: () => void;
  isUpdate: boolean;
  whatsAppId: string;
}

export const WhatsappModal = ({
  onClose,
  isUpdate,
  whatsAppId,
}: ModalProps) => {
  const mobile = useMobile();
  const dappContext = useContext(DappsContext);
  const [isProceed, setIsProceed] = useState(false);
  const [error, setError] = useState(false);
  const [value, setValue] = useState("");
  const [isValidOtp, setIsValidOtp] = useState(false);
  const [wNumber, setWNumber] = useState("");
  const [isCongrats, setIsCongrats] = useState(false);
  const captchaRef = React.useRef(null);
  const { account, chainId } = useWeb3React();
  const [txnsError, setTxnsError] = useState(false);
  const [proceedLoader, setProceedLoader] = useState(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isVisible, setIsVisible] = useState(true);

  const handleNumber = (e) => {
    setWNumber(e.target.value);

    if (e.target.value.length === 10) {
      setError(false);
    }
  };

  const showPassCode = () => {
    setIsVisible(!isVisible);
  };

  useEffect(() => {
    if (isUpdate) {
      setWNumber(whatsAppId);
    }
  }, []);

  const onCaptchVerify = () => {
    if (!window.recaptchaVerifier) {
      window.recaptchaVerifier = new RecaptchaVerifier(
        captchaRef.current,
        {
          size: "invisible",
          callback: (response) => {
            onSignup();
          },
          // "expired-callback": ()=>{},
        },
        auth
      );
    }
  };

  const onSignup = () => {
    if (wNumber.length !== 10) {
      setError(true);
    } else {
      onCaptchVerify();
      setProceedLoader(true);
      const appVerifier = window.recaptchaVerifier;
      const formatPh = "+91" + wNumber;
      console.log(appVerifier, auth, formatPh, "params");

      signInWithPhoneNumber(auth, formatPh, appVerifier)
        .then((confirmationResult) => {
          window.confirmationResult = confirmationResult;

          setIsProceed(true);
          setProceedLoader(false);
        })
        .catch((error) => {
          console.log(error, "Error in SignIn");
        });
    }
  };

  const onOTPVerify = () => {
    setIsLoading(true);
    window.confirmationResult
      .confirm(value)
      .then(async (res) => {
        addNumber();
      })
      .catch((err) => {
        setIsValidOtp(true);
        console.log(err, "err");
        setIsLoading(false);
      });
  };

  const addNumber = async () => {
    try {
      console.log("started");
      const message = wNumber;

      // Encode the message as UTF-8
      const utf8Encoded = utils.toUtf8Bytes(message);

      // Convert the UTF-8 encoded message to hexadecimal
      const hexEncoded = utils.hexlify(utf8Encoded);

      console.log(hexEncoded, "hexEncoded", typeof hexEncoded);

      if (dappContext.pvtKey && dappContext.isRepeatUser) {
        await gaslessTxnHermes(
          successFallback,
          errorFallback,
          dappContext.pvtKey,
          chainId,
          walletRegistryAddress(chainId),
          walletRegistryABI.abi,
          isUpdate ? "updateWhatsAppId" : "addWhatsAppId",
          [account, hexEncoded, false],
          true
        );
      }
    } catch (error) {
      console.log("Error in function addNumber:", error);
      setIsLoading(false);
      setTxnsError(true);
      setIsValidOtp(false);
    }
  };

  const successFallback = () => {
    setIsCongrats(true);
    setIsProceed(false);
    setIsLoading(false);
  };

  const errorFallback = () => {
    console.log("error");
    setIsLoading(false);
  };

  return (
    <>
      <div id="recaptcha-container" ref={captchaRef}></div>
      <ModalWrapper>
        {isProceed ? (
          <>
            <ModalWrapper2 style={{ rowGap: 25 }}>
              <div style={{ width: mobile ? 250 : 300 }}>
                <NotificationProgress
                  noOfComleteSendUsers={2}
                  totalSendUsers={2}
                  isStep={true}
                />
              </div>
              <ModalContent>
                Enter the 6-digit OTP that you must have received
              </ModalContent>
              {isValidOtp ? <ErrorDiv>Invalid Otp</ErrorDiv> : null}
              {txnsError ? (
                <ErrorDiv>Transaction failed ! Please try again</ErrorDiv>
              ) : null}

              <InputWrapper>
                <MuiOtpInput
                  length={6}
                  value={value}
                  onChange={(e) => {
                    setValue(e);
                  }}
                  TextFieldsProps={{
                    size: "small",
                    placeholder: "",
                    type: isVisible ? "password" : "text",
                    error: isValidOtp,
                    inputProps: { inputMode: "numeric" },
                  }}
                />
                {isVisible ? (
                  <IconButton onClick={showPassCode}>
                    <VisibilityOffIcon />
                  </IconButton>
                ) : (
                  <IconButton onClick={showPassCode}>
                    <RemoveRedEyeIcon />
                  </IconButton>
                )}
              </InputWrapper>
              {isLoading ? (
                <SubmitButton style={{ width: "200px" }}>
                  <ClipLoader color="white" size={16} /> &nbsp;&nbsp;
                  {isUpdate ? "Updating" : "Submiting"}
                </SubmitButton>
              ) : (
                <SubmitButton onClick={onOTPVerify} style={{ width: "200px" }}>
                  {isUpdate ? "Verify & Update" : "Verify & Submit"}
                </SubmitButton>
              )}
            </ModalWrapper2>
          </>
        ) : isCongrats ? (
          <ModalWrapper2 style={{ rowGap: 30 }}>
            <ModalContent>Congratulations</ModalContent>
            <CongrestText>
              Your WhatsApp notification has been enabled.
            </CongrestText>
            <HomeText
              onClick={() => {
                onClose();
              }}
            >
              Back to Home
            </HomeText>
          </ModalWrapper2>
        ) : (
          <>
            <ModalWrapper2 style={{ rowGap: 25 }}>
              <div style={{ width: mobile ? 250 : 300 }}>
                <NotificationProgress
                  noOfComleteSendUsers={1}
                  totalSendUsers={2}
                  isStep={true}
                />
              </div>

              <ModalContent>
                Please enter your 10-digit registered mobile number
              </ModalContent>

              <WrapperText style={{ flexDirection: "column" }}>
                {error ? (
                  <ErrorStateWrapper>Invalid Mobile Number</ErrorStateWrapper>
                ) : null}
                <Componentwrapper>
                  <TgInput
                    type="number"
                    placeholder="Mobile Number"
                    value={wNumber}
                    onChange={handleNumber}
                    style={{ border: "1px solid #ccc", borderRadius: "20px" }}
                  />
                  {proceedLoader ? (
                    <SubmitButton style={{ width: "200px" }}>
                      <ClipLoader color="white" size={16} /> &nbsp;&nbsp;
                      Proceed
                    </SubmitButton>
                  ) : (
                    <SubmitButton onClick={onSignup}>Proceed</SubmitButton>
                  )}
                </Componentwrapper>
              </WrapperText>
            </ModalWrapper2>
          </>
        )}
      </ModalWrapper>
    </>
  );
};
