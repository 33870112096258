import React from "react";
import HeroPaint from "../../assets/Guidelines/heroPaint.png";
import { saveAs } from "file-saver";
import JSZip from "jszip";
import {
  GuideLineContainer,
  GuideLineFillButton,
  GuidelineParagraph,
  GuidelineTitles,
  HeroContentWrapper,
  HeroImage,
  HeroImageWrapper,
  HeroSection,
  Image,
} from "./guidlines.styles";

import MainIcon from "../../assets/Guidelines/mainIcon.png";
import Iconone from "../../assets/Guidelines/icon_one.png";
import IconTwo from "../../assets/Guidelines/icon_two.png";
import IconThree from "../../assets/Guidelines/icon_three.png";
import IconFour from "../../assets/Guidelines/icon_four.png";

import Logo1 from "../../assets/Guidelines/dapps_logo_one.png";
import Logo2 from "../../assets/Guidelines/dapps_logo_two.png";
import Logo3 from "../../assets/Guidelines/dapps_logo_three.png";
import Logo4 from "../../assets/Guidelines/dapps_logo_four.png";

const GuidelineHeroSection = () => {
  const handleDownload = async () => {
    const zip = new JSZip();

    // Add PNG files to the zip archive
    const file1 = await fetch(MainIcon).then((res) => res.blob());
    zip.file("Favicon.png", file1);
    const file2 = await fetch(MainIcon).then((res) => res.blob());
    zip.file("SocialIcon1.png", file2);
    const file3 = await fetch(Iconone).then((res) => res.blob());
    zip.file("SocialIcon2.png", file3);
    const file4 = await fetch(IconTwo).then((res) => res.blob());
    zip.file("SocialIcon3.png", file4);
    const file5 = await fetch(IconThree).then((res) => res.blob());
    zip.file("SocialIcon4.png", file5);
    const file6 = await fetch(IconFour).then((res) => res.blob());
    zip.file("SocialIcon5.png", file6);

    const file7 = await fetch(Logo1).then((res) => res.blob());
    zip.file("dappsLogo1.png", file7);
    const file8 = await fetch(Logo2).then((res) => res.blob());
    zip.file("dappsLogo2.png", file8);
    const file9 = await fetch(Logo3).then((res) => res.blob());
    zip.file("dappsLogo3.png", file9);
    const file10 = await fetch(Logo4).then((res) => res.blob());
    zip.file("dappsLogo4.png", file10);

    // Generate the zip file and download it
    const content = await zip.generateAsync({ type: "blob" });
    saveAs(content, "BrandAssets.zip");
  };

  return (
    <>
      <GuideLineContainer>
        <HeroSection>
          <HeroContentWrapper>
            <GuidelineTitles>Using our brand</GuidelineTitles>
            <GuidelineParagraph>
              Our brand guidelines provide clear instructions and best practices
              <br />
              to ensure proper usage and presentation of our brand, helping you
              <br />
              showcase it effectively and consistently across all platforms.
            </GuidelineParagraph>
            <GuideLineFillButton onClick={handleDownload}>
              Download Our Brand Assets
            </GuideLineFillButton>
          </HeroContentWrapper>
          <HeroImageWrapper>
            <HeroImage>
              <Image src={HeroPaint} />
            </HeroImage>
          </HeroImageWrapper>
        </HeroSection>
      </GuideLineContainer>
    </>
  );
};

export default GuidelineHeroSection;
