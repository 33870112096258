import * as React from "react";
import Box from "@mui/material/Box";
import LinearProgress from "@mui/material/LinearProgress";
import { CustomizeProgress, ProgressLable } from "../Profile.style";
interface isProps {
  totalSendUsers: number;
  noOfComleteSendUsers: number;
  isStep?: boolean;
}
const NotificationProgress = ({
  totalSendUsers,
  noOfComleteSendUsers,
  isStep,
}: isProps) => {
  return (
    <Box sx={{ width: "100%", position: "relative" }}>
      {isStep ? (
        <ProgressLable>
          Step {noOfComleteSendUsers}/{totalSendUsers}{" "}
        </ProgressLable>
      ) : (
        <ProgressLable>
          Sent to {noOfComleteSendUsers}/{totalSendUsers} Users
        </ProgressLable>
      )}

      <CustomizeProgress
        variant="determinate"
        value={(noOfComleteSendUsers * 100) / totalSendUsers}
      />
    </Box>
  );
};
export default NotificationProgress;
