import React from "react";
import {
  CardContainer,
  CardDetails,
  ColorCard,
  ColorCardWrapper,
  ColorCodeParagraph,
  Flex,
  GuideLineContainer,
  Image,
  SpaceBetween,
  Title,
} from "./guidlines.styles";
import ColorCodeOne from "../../assets/Guidelines/colorCode_one.png";
import ColorCodeTwo from "../../assets/Guidelines/colorCode_two.png";
import ColorCodeThree from "../../assets/Guidelines/colorCode_three.png";
import ColorCodeFour from "../../assets/Guidelines/colorCode_four.png";
import ColorCodeFive from "../../assets/Guidelines/colorCode_five.png";
import ColorCodeSix from "../../assets/Guidelines/colorCode_six.png";

const ColorCode = [
  {
    colorImage: ColorCodeOne,
    colorHexCode: "#A0E5E8",
    RGB: "60, 229, 232",
  },
  {
    colorImage: ColorCodeTwo,
    colorHexCode: "#30BCC2",
    RGB: "48, 188, 194",
  },
  {
    colorImage: ColorCodeThree,
    colorHexCode: "#1F9EA4",
    RGB: "31, 158, 164",
  },
  {
    colorImage: ColorCodeFour,
    colorHexCode: "#231F20",
    RGB: "35, 31, 32",
  },
  {
    colorImage: ColorCodeFive,
    colorHexCode: "#FFFFFF",
    RGB: "255, 255,255",
  },
  {
    colorImage: ColorCodeSix,
    colorHexCode: "#F7F7F7",
    RGB: "247, 247, 250",
  },
];

const DappsColorCode = () => {
  return (
    <>
      <GuideLineContainer bgColor="secondary">
        <SpaceBetween>
          <Title>Colour Code</Title>
        </SpaceBetween>

        <CardContainer>
          {ColorCode.map((colors, ind) => {
            return (
              <>
                <ColorCardWrapper key={ind}>
                  <Flex>
                    <ColorCard>
                      <Image src={colors.colorImage} />
                    </ColorCard>
                    <CardDetails>
                      <ColorCodeParagraph fontWeight="600">
                        Hex Code: {colors.colorHexCode}
                      </ColorCodeParagraph>
                      <ColorCodeParagraph fontWeight="600">
                        Rgb: {colors.RGB}
                      </ColorCodeParagraph>
                    </CardDetails>
                  </Flex>
                </ColorCardWrapper>
              </>
            );
          })}
        </CardContainer>
      </GuideLineContainer>
    </>
  );
};

export default DappsColorCode;
