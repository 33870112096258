export const DAPPS_ENC_PVT_KEY = "DAPPS_ENC_PVT_KEY";
export const dappSupportedNetworks = [56, 137, 250, 997];
const environment = process.env.REACT_APP_ENVIRONMENT;

export const unifarmAccountsAddress = (networkID: number) => {
  switch (networkID) {
    // eth
    case 1:
      return "";

    // bsc
    case 56:
      return "0x1e1205eb304Db2DC47847Be657448d81133A82ae";

    // polygon
    case 137:
      switch (environment) {
        case "DAPPS_NEW_PRODUCTION":
          return "0x09858E514eB96c3315Bf487c3F2bB4eD88d65Bd3";
        case "DAPPS":
          return "0x1e1205eb304Db2DC47847Be657448d81133A82ae";
        default:
          return "0x1e1205eb304Db2DC47847Be657448d81133A82ae";
      }
    // fantom
    case 250:
      return "0x356d5A055A9564707E1402827E9d1C86B11fb85B";

    default:
      return "";
  }
};

export const subscriptionModuleAddress = (networkID: number) => {
  switch (networkID) {
    // eth
    case 1:
      return "";

    // bsc
    case 56:
      return "0x89f5595c2d8fF22beCb5d18D004C2C69AF616Bf0";

    // polygon
    case 137:
      switch (environment) {
        case "DAPPS_NEW_PRODUCTION":
          return "0xcd4De28E94F4ac6546dfE51b9a1A4ca280806a7d";
        default:
          return "0xcd4De28E94F4ac6546dfE51b9a1A4ca280806a7d";
      }
    // fantom
    case 250:
      return "0xd293D5f79A6187FDB5f91b01F55f92ca4Cc9C731";

    // shardeum
    case 8081:
      return "0xa6dC8f376B2071d60813E1170F1D7fdC49eb4Ba1";

    // firechan
    case 997:
      return "0x47d838B117A515BdFe91573E91CF071109D07980";

    default:
      return "";
  }
};

export const walletRegistryAddress = (networkID: number) => {
  switch (networkID) {
    // eth
    case 1:
      return "";

    // bsc
    case 56:
      return "0x38A547A5b52AFf437A15e75EbdF16291d6491f76";

    // polygon
    case 137:
      switch (environment) {
        case "DAPPS_NEW_PRODUCTION":
          return "0xaCbB8F1917E5746C1a02F64FC753D25a781eAE12";
        default:
          return "0xaCbB8F1917E5746C1a02F64FC753D25a781eAE12";
      }
    // fantom
    case 250:
      return "0x5F7C718783701F886284851f9c9eE4386D8Be53c";

    // shardeum
    case 8081:
      return "0x84D23022287e347f21d51be039D058545177d407";

    // firechain
    case 997:
      return "0xE667de8E2cd583D3fE8AC107Ba4547EaaF0a1561";
    default:
      return "";
  }
};

export const gamificationAddress = (networkID: number) => {
  switch (networkID) {
    // eth
    case 1:
      return "";

    // bsc
    case 56:
      return "0xFbe9d87fe82E1D4205B957B28D25301A9e097880";

    // polygon
    case 137:
      switch (environment) {
        case "DAPPS_NEW_PRODUCTION":
          return "0x06075a3b002B4bdCBc066358EF641745B63d265b";
        default:
          return "0x06075a3b002B4bdCBc066358EF641745B63d265b";
      }
    // fantom
    case 250:
      return "0xe6774ea9eC37B29CcCd93B118718B9C9E1D0210C";

    // shardeum
    case 8081:
      return "0x02CB1ac2208A404b20B9DD44FC32208212bbF2c4";

    // firechain
    case 997:
      return "0x612Aa0749f3e3670710b3bAcaa988aF1a0D3176a";
    default:
      return "";
  }
};

export const messagingContractAddress = (networkID: number) => {
  switch (networkID) {
    // eth
    case 1:
      return "";

    // bsc
    case 56:
      return "";

    // polygon
    case 137:
      switch (environment) {
        case "DAPPS_NEW_PRODUCTION":
          return "0x8532920591a6D23F809A821C569FF3791B0CCa16";
        case "DAPPS":
          return "0xd178AceC8b810319C57f3F4443858294cA8C44b7";
        default:
          return "0x8532920591a6D23F809A821C569FF3791B0CCa16";
      }
    case 250:
      return "0x748B34458Aa424933bde3A71BC8a8a795b0Ce9A7";

    default:
      return "";
  }
};

export const dappsDnsAddress = (networkID: number) => {
  switch (networkID) {
    // eth
    case 1:
      return "";

    // bsc
    case 56:
      return "";

    // polygon
    case 137:
      switch (environment) {
        case "DAPPS_NEW_PRODUCTION":
          return "";
        default:
          return "";
      }
    // fantom
    case 250:
      return "";

    // shardeum
    case 8081:
      return "";

    // firechain
    case 997:
      // return "0xAE755D7B19f1bfe61948f0C0937d846F204ec674";
      return "0x271437C9B2069F13Cc197B9e12A02ED276ae3A85";
    default:
      return "";
  }
};

// interface Case {
//   [cases: string]: string
// }

// interface MessageContractAddresses {
//   [chainId: number]: {Case}
// }

// export const messageContractAddress =
