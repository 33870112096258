import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
export const useMobile = () => {
  const theme = useTheme();
  const Mobile = useMediaQuery(theme.breakpoints.between("xs", "sm"));
  return Mobile;
};
export const useMobile2 = () => {
  const theme = useTheme();
  const Mobile = useMediaQuery(theme.breakpoints.down("xs"));
  return Mobile;
};
export const useMobile3 = () => {
  const theme = useTheme();
  const Mobile = useMediaQuery(theme.breakpoints.down("sm"));
  return Mobile;
};
export const useIpad = () => {
  const theme = useTheme();
  const Ipad = useMediaQuery(theme.breakpoints.between("sm", "md"));
  return Ipad;
};
export const useIpad2 = () => {
  const theme = useTheme();
  const Ipad = useMediaQuery(theme.breakpoints.down("md"));
  return Ipad;
};
export const useIpad3 = () => {
  const theme = useTheme();
  const Ipad = useMediaQuery(theme.breakpoints.down("lg"));
  return Ipad;
};
export const useIpad4 = () => {
  const theme = useTheme();
  const Ipad = useMediaQuery(theme.breakpoints.between("lg", "xl"));
  return Ipad;
};
export const useIpad5 = () => {
  const theme = useTheme();
  const Ipad = useMediaQuery(theme.breakpoints.between("md", "lg"));
  return Ipad;
};
export const LandingMobile = () => {
  const Mobile = useMediaQuery("(max-width:768px)");
  return Mobile;
};
export const LandingMobile2 = () => {
  const Mobile = useMediaQuery("(min-width: 431px) and (max-width: 715px)");
  return Mobile;
};
