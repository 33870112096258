import Dialog from "@mui/material/Dialog";
import { styled } from "@mui/material/styles";
export const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialog-paperWidthSm": {
    borderRadius: 25,
    maxWidth: "100%",
  },
  "& .MuiDialogContent-root": {
    padding: 18,
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
  "& .MuiDialogTitle-root": {
    boxShadow: "unset",
    fontWeight: 600,
    fontSize: 18,
    textTransform: "uppercase",
    [theme.breakpoints.down("xs")]: {
      fontSize: 13,
    },
  },
}));
