import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface MyState {
  isTxnsError: boolean;
}
interface setTxnsErrorPayload {
  isTxnsError: boolean;
}

const initialState: MyState = {
  isTxnsError: false,
};

const TransactionErrorSlice = createSlice({
  name: "txnsError",
  initialState,
  reducers: {
    setTxnsError: (state, action: PayloadAction<setTxnsErrorPayload>) => {
      state.isTxnsError = action.payload.isTxnsError;
    },
    // closeTxnsSnackbar: (state, action: PayloadAction<setTxnsErrorPayload>) => {
    //   state.isTxnsError = action.payload.isTxnsError;
    // },
  },
});

export const { setTxnsError } = TransactionErrorSlice.actions;

export default TransactionErrorSlice.reducer;
