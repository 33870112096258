import ethLogo from "../assets/images/eth.svg";
import bnLogo from "../assets/images/bn.svg";
import polygonLogo from "../assets/images/polygon.svg";
import aveLogo from "../assets/images/ave.png";
import FTMLogo from "../assets/Network/FTM.png";
import ArbitrumLogo from "../assets/images/arbitrum.svg";
import OptimismLogo from "../assets/images/optimism.svg";
import Polygon from "../assets/Network/polygon.png";
import BNB from "../assets/Network/bnb.png";
import ETH from "../assets/Network/eth.png";
import Shardeum from "../assets/Network/shardeum.png";
import Firechain from "../assets/Network/firechain.png";

// ETH RPC url
export const ethRpcUrl = process.env.REACT_APP_ETHEREUM_RPC_URL;
// BSC RPC url
export const bscRpcUrl = process.env.REACT_APP_BSC_RPC_URL;

// POLYGON RPC url
export const polygonRpcUrl = process.env.REACT_APP_POLYGON_RPC_URL;
// AVAX RPC URL
export const avaxRpcUrl = process.env.REACT_APP_AVAX_RPC_URL;

export const fantomRpcUrl = String(process.env.REACT_APP_FANTOM_RPC_URL);

export const arbitrumRpcUrl = String(process.env.REACT_APP_ARBITRUM_RPC_URL);

export const optimismRpcUrl = String(process.env.REACT_APP_OPTIMISM_RPC_URL);

export const shardeumRpcUrl = String(process.env.REACT_APP_SHARDEUM_RPC_URL);

export const firechainRpcUrl = String(process.env.REACT_APP_FIRECHAIN_RPC_URL);

// ETH ChainId
export const ETH_CHAIN = 1;
// BSC ChainId
export const BSC_CHAIN = 56;
// Polygon ChainId
export const POLYGON_CHAIN = 137;
// Avax ChainId
export const AVAX_CHAIN = 43114;
// // Fantom ChainId
export const FANTOM_CHAIN = 250;
// Artbitrum chainId
export const ARBITRUM_CHAIN = 42161;
// Optimism chainId
export const OPTIMISM_CHAIN = 10;
// Shardeum chainid
export const SHARDEUM_CHAIN = 8081;
// Firechain chainid
export const FIRECHAIN_CHAIN = 997;

export interface NetworkDetails {
  id: string;
  name: string;
  icon: string;
  chainId: number;
}

export interface Networks {
  [chainId: number]: NetworkDetails;
}

interface Explolers {
  [chainId: number]: string;
}

interface RPC {
  [chainId: number]: string;
}

export const rpcUrls: RPC = {
  1: ethRpcUrl,
  56: bscRpcUrl,
  137: polygonRpcUrl,
  250: fantomRpcUrl,
  43114: avaxRpcUrl,
  42161: arbitrumRpcUrl,
  10: optimismRpcUrl,
  8081: shardeumRpcUrl,
  997: firechainRpcUrl,
};

export const rpcUrlsForMulticall: RPC = {
  1: "https://rpc.ankr.com/eth",
  56: "https://rpc.ankr.com/bsc",
  137: "https://rpc.ankr.com/polygon",
  250: "https://rpc.ankr.com/fantom",
  43114: "https://rpc.ankr.com/avalanche",
  42161: "https://rpc.ankr.com/arbitrum",
  10: "https://rpc.ankr.com/optimism",
  8081: "https://liberty20.shardeum.org",
  997: "https://rpc-testnet.5ire.network",
};

export const networks: Networks = {
  // 1: {
  //   id:"3",
  //   name: 'Ethereum',
  //   icon: ETH,
  //   chainId: 1,
  // },
  56: {
    id: "2",
    name: "Binance",
    icon: BNB,
    chainId: 56,
  },
  137: {
    id: "1",
    name: "Polygon",
    icon: Polygon,
    chainId: 137,
  },
  250: {
    id: "4",
    name: "Fantom",
    icon: FTMLogo,
    chainId: 250,
  },
  // 43114: {
  //   name: 'Avalanche',
  //   icon: aveLogo,
  //   chainId: 43114,
  // },
  // 42161: {
  //   name: 'Arbitrum',
  //   icon: ArbitrumLogo,
  //   chainId: 42161,
  // },
  // 10: {
  //   name: 'Optimism',
  //   icon: OptimismLogo,
  //   chainId: 10,
  // },
  // 8081: {
  //   id: "5",
  //   name: "Shardeum",
  //   icon: Shardeum,
  //   chainId: 8081,
  // },
  997: {
    id: "6",
    name: "5irechain(Testnet)",
    icon: Firechain,
    chainId: 997,
  },
};

export const explolers: Explolers = {
  1: "https://etherscan.io",
  10: "https://optimistic.etherscan.io/",
  56: "https://bscscan.com",
  137: "https://polygonscan.com",
  250: "https://ftmscan.com",
  43114: "https://snowtrace.io/",
  42161: "https://arbiscan.io/",
  8081: "https://explorer-liberty20.shardeum.org",
  997: "https://rpc-testnet.5ire.network",
};

export const chainConfig = {
  56: [
    {
      chainId: "0x38",
      chainName: "Binance Smart Chain (Mainnet)",
      nativeCurrency: {
        name: "BNB",
        symbol: "BNB",
        decimals: 18,
      },
      rpcUrls: [process.env.REACT_APP_BSC_RPC_URL_SWITCH],
      blockExplorerUrls: ["https://bscscan.com/"],
      iconUrls: ["https://unifarm.co/app/assets/icons/bnb.png"],
    },
  ],
  137: [
    {
      chainId: "0x89",
      chainName: "POLYGON Mainnet",
      nativeCurrency: {
        name: "MATIC TOKEN",
        symbol: "MATIC",
        decimals: 18,
      },
      rpcUrls: [process.env.REACT_APP_POLYGON_RPC_URL_SWITCH],
      blockExplorerUrls: ["https://polygonscan.com/"],
      iconUrls: [
        "https://raw.githubusercontent.com/InfernalHeir/tokenlist/master/.github/assets/icons/matic.png",
      ],
    },
  ],
  250: [
    {
      chainId: "0xFA",
      chainName: "Fantom Mainnet",
      nativeCurrency: {
        name: "Fantom",
        symbol: "FTM",
        decimals: 18,
      },
      rpcUrls: [process.env.REACT_APP_FANTOM_RPC_URL_SWITCH],
      blockExplorerUrls: ["https://ftmscan.com/"],
      iconUrls: [FTMLogo],
    },
  ],
  42161: [
    {
      chainId: "0xA4B1",
      chainName: "Arbitrum Mainnet",
      nativeCurrency: {
        name: "Arbitrum",
        symbol: "ARB",
        decimals: 18,
      },
      rpcUrls: [arbitrumRpcUrl],
      blockExplorerUrls: ["https://arbiscan.io/"],
      iconUrls: [
        "https://raw.githubusercontent.com/InfernalHeir/tokenlist/mainnet-01/.github/assets/icons/arb.png",
      ],
    },
  ],
  10: [
    {
      chainId: "0xA",
      chainName: "Optimism Mainnet",
      nativeCurrency: {
        name: "Optimism",
        symbol: "ETH",
        decimals: 18,
      },
      rpcUrls: [optimismRpcUrl],
      blockExplorerUrls: ["https://optimistic.etherscan.io/"],
      iconUrls: [
        "https://raw.githubusercontent.com/InfernalHeir/tokenlist/mainnet-01/.github/assets/icons/opt.png",
      ],
    },
  ],
  43114: [
    {
      chainId: "0xA86A",
      chainName: "Avalanche Mainnet C-Chain",
      nativeCurrency: {
        name: "Avalanche",
        symbol: "AVAX",
        decimals: 18,
      },
      rpcUrls: [avaxRpcUrl],
      blockExplorerUrls: ["https://snowtrace.io/"],
      iconUrls: [
        "https://raw.githubusercontent.com/InfernalHeir/tokenlist/mainnet-01/.github/assets/icons/avax.png",
      ],
    },
  ],
  8081: [
    {
      chainId: "0x1F91",
      chainName: "Shardeum",
      nativeCurrency: {
        name: "Shardeum",
        symbol: "SHM",
        decimals: 18,
      },
      rpcUrls: [shardeumRpcUrl],
      blockExplorerUrls: ["https://explorer-liberty20.shardeum.org/"],
      iconUrls: [Shardeum],
    },
  ],
  997: [
    {
      chainId: "0x3E5",
      chainName: "5ire(Testnet)",
      nativeCurrency: {
        name: "5irechain",
        symbol: "5IRE",
        decimals: 18,
      },
      rpcUrls: [firechainRpcUrl],
      blockExplorerUrls: ["https://rpc-testnet.5ire.network/"],
      iconUrls: [Firechain],
    },
  ],
};

const bicoApiKeyPolygon = String(
  process.env.REACT_APP_BICONOMY_API_KEY_POLYGON
);
const bicoApiKeyFantom = String(process.env.REACT_APP_BICONOMY_API_KEY_FANTOM);
const bicoApiKeyBsc = String(process.env.REACT_APP_BICONOMY_API_KEY_BSC);

export const getGaslessApiKey = {
  56: bicoApiKeyBsc,
  137: bicoApiKeyPolygon,
  250: bicoApiKeyFantom,
};
