import NotificationConfig from "components/NotificationConfig";
import React, { lazy, Suspense } from "react";

// const HomePage = lazy(() => import("./page/index"));
const UserHomePage = lazy(() => import("page/User/index"));
const Subscription = lazy(() => import("./page/Subscription/index"));
const SubscriptionDetailPage = lazy(
  () => import("page/Subscription/SubscriptionDetail")
);
const AdminProfile = lazy(() => import("page/Profile/Admin/index"));
const GraphDashboardPage = lazy(
  () => import("page/Profile/Admin/GraphDashboard")
);
const NotificationPage = lazy(() => import("page/Notification"));
const ExplorePage = lazy(() => import("page/Explore"));
const TrendingPage = lazy(() => import("page/Trending"));
const ComposeMessage = lazy(() => import("page/MessageInbox/ComposeMessage"));
const TestIndex = lazy(() => import("components/Subscription/TestIndex"));
const Event = lazy(() => import("page/Event/index"));

export const webRoutes = [
  {
    path: "/",
    element: <UserHomePage />,
  },
  // {
  //   path: "/homepage",
  //   element: <UserHomePage />,
  // },
  {
    path: "/subscription",
    element: <Subscription />,
  },
  {
    path: "/subscription-detail",
    element: <SubscriptionDetailPage />,
  },
  {
    path: "/profile/admin",
    element: <AdminProfile />,
  },
  {
    path: "/profile/admin/graph-dashboard/:dappId/:networkId",
    element: <GraphDashboardPage />,
  },
  {
    path: "/notification",
    element: <NotificationPage />,
  },
  {
    path: "/explore",
    element: <ExplorePage />,
  },
  {
    path: "/trending",
    element: <TrendingPage />,
  },
  {
    path: "/test-upload",
    element: <TestIndex />,
  },
  {
    path: "/profile/admin/notification-config",
    element: <NotificationConfig />,
  },
  {
    path: "/event",
    element: <Event />,
  },
];
