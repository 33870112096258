import { useState, useEffect, useCallback, useContext } from "react";
import { useWeb3React } from "@web3-react/core";
// import { isMobile } from 'react-device-detect';
import { InjectedConnector } from "@web3-react/injected-connector";
import { WalletConnectConnector } from "@web3-react/walletconnect-connector";
import { useApplicationUserState } from "../store/hooks";
import { injected } from "../connectors";
import { WalletLinkConnector } from "@web3-react/walletlink-connector";
import { useHashConnect } from "../HashConnectAPIProvider-1.10";
import AuthenticationContext from "AuthenticationContext/Context";

const refreshState = () => {
  window.localStorage.setItem("wallet", "1");
};
export const useDisconnect = () => {
  const { connector, deactivate } = useWeb3React();
  const { disconnect } = useHashConnect();
  const dappsContext = useContext(AuthenticationContext);
  return useCallback(() => {
    if (connector instanceof InjectedConnector && window.ethereum.isMetaMask) {
      deactivate();
      return;
    } else if (connector instanceof WalletLinkConnector) {
      (connector as any).close();
      refreshState();
      return;
    } else if (connector instanceof WalletConnectConnector) {
      (connector as any).close();
      refreshState();
      return;
    } else {
      localStorage.removeItem("googleAuth");
      dappsContext?.setIsSocialLogin(false);
      disconnect();
    }
    deactivate();
  }, [connector, deactivate]);
};

export function useWeb3EagerConnect() {
  const { activate, active } = useWeb3React();

  const [tried, setTried] = useState(false);
  const {
    connectToExtension,
    pairingData,
    availableExtension,
    network,
    pairingString,
  } = useHashConnect();
  const handleClick = () => {
    // if (availableExtension && !pairingData) connectToExtension();
    connectToExtension();
    // if (availableExtension ) connectToExtension();
  };
  useEffect(() => {
    async function connectwallet() {
      const walletindex = localStorage.getItem("wallet");
      if (walletindex == "1") {
        await activate(injected)
          .then(() => {
            // console.log("connect w metamask")
            localStorage.setItem("wallet", "1");
          })
          .catch(() => {
            console.log("error in metamask");
          });
        // localStorage.setItem('wallet', '1');
      } else if (walletindex == "2") {
        await handleClick();
        // .then(() => {
        //   setTried(true);
        // })
        // .catch(() => {
        //   setTried(false);
        //   console.log('error in walletconnect');
        // });
        localStorage.setItem("wallet", "2");
      } else {
        await activate(null);
      }
    }
    connectwallet();
  }, []);

  // if the connection worked, wait until we get confirmation of that to flip the flag
  useEffect(() => {
    if (!tried && active) {
      setTried(true);
    }
  }, [tried, active]);

  return tried;
}

export function useInactiveListener(suppress = false) {
  const { active, error, activate } = useWeb3React();
  const { appChainId } = useApplicationUserState();
  useEffect(() => {
    const { ethereum } = window as any;
    if (ethereum && ethereum.on && !active && !error && !suppress) {
      const handleConnect = () => {
        activate(injected);
      };
      // eslint-disable-next-line
      const handleChainChanged = (chainId: string | number) => {
        activate(injected);
      };
      const handleAccountsChanged = (accounts: string[]) => {
        if (accounts.length > 0) {
          activate(injected);
        }
      };

      ethereum.on("connect", handleConnect);
      ethereum.on("chainChanged", handleChainChanged);
      ethereum.on("accountsChanged", handleAccountsChanged);

      return () => {
        if (ethereum.removeListener) {
          ethereum.removeListener("connect", handleConnect);
          ethereum.removeListener("chainChanged", handleChainChanged);
          ethereum.removeListener("accountsChanged", handleAccountsChanged);
        }
      };
    }
  }, [active, error, suppress, activate, appChainId]);
}
