import styled from "styled-components";
import { Button } from "@mui/material";
import Newsletter_vector from "../../assets/images/Newsletter_vector.png";
import { MediaQuery } from "const/MediaQuery";
export const HeroSectionWrapper = styled.div`
  height: 632px;
  // margin-top: 6rem;
  margin-bottom: 2rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  @media (max-width: 430px) {
    flex-direction: column;
    height: auto;
    row-gap: 42px;
  }
  @media (${MediaQuery.Ipad}) {
    flex-direction: column;
    height: auto;
    row-gap: 42px;
  }
  @media (${MediaQuery.Ipad2}) {
    flex-direction: column;
    height: auto;
    row-gap: 42px;
  }
`;
export const HeroImageWrapper = styled.div`
  display: flex;
  align-items: center;
  column-gap: 24px;
  @media (max-width: 430px) {
    flex-direction: column;
    row-gap: 17px;
  }
  @media (${MediaQuery.Ipad}) {
    flex-direction: column;
    row-gap: 17px;
  }
  @media (${MediaQuery.Ipad2}) {
    flex-direction: column;
    row-gap: 17px;
  }
`;
export const HeroImage = styled.div`
  position: relative;
  // transition: all 0.2s ease !important;
  // letter-spacing: 0.025em !important;
  // font-size: 1rem !important;
  border-color: #d1d9e6 !important;
  box-shadow: 3px 3px 6px #b8b9be, -3px -3px 6px #fff !important;

  width: 92px;
  height: 92px;
  display: flex;
  align-items: center;
  justify-content: center;

  /* BG/active */

  background: #fafcff;
  /* Primary/default */

  border: 1px solid;
  border-radius: 20px;
  @media (max-width: 430px) {
    width: 50px;
    height: 50px;
  }
  @media (${MediaQuery.Ipad}) {
    width: 80px;
    height: 80px;
  }
  @media (${MediaQuery.Ipad2}) {
    width: 80px;
    height: 80px;
  }

  :hover {
    box-shadow: unset !important;
    transition: box-shadow 800ms cubic-bezier(0.19, 1, 0.22, 1);
    border: 1px solid #30bcc2 !important;
    cursor: pointer;
    transform: scale(1.3) translateY(-6px);
  }
`;
export const SubscribeDiv = styled.div`
  font-size: 12px;
  font-weight: 700;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  row-gap: 2px;
`;
export const ImageFrame1 = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 10px;
  filter: drop-shadow(4px 4px 25px #e0e0e0);
  @media (max-width: 430px) {
    display: flex;
    flex-direction: row;
    column-gap: 7px;
  }
  @media (${MediaQuery.Ipad}) {
    display: flex;
    flex-direction: row;
    column-gap: 7px;
  }
  @media (${MediaQuery.Ipad2}) {
    display: flex;
    flex-direction: row;
    column-gap: 7px;
  }
`;
export const ContentWrap = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  row-gap: 25px;
  @media (max-width: 430px) {
    row-gap: 15px;
  }
  @media (${MediaQuery.Ipad}) {
    row-gap: 15px;
  }
  @media (${MediaQuery.Ipad2}) {
    row-gap: 15px;
  }
`;
export const ContentTitle = styled.div`
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  font-size: 40px;
  line-height: 49px;
  text-align: center;
  letter-spacing: -0.03em;

  /* Font/header */

  color: #0f1f4a;
  @media (max-width: 430px) {
    font-size: 22px;
    line-height: 38px;
  }
  @media (${MediaQuery.Ipad}) {
    font-size: 22px;
    line-height: 38px;
  }
  @media (${MediaQuery.Ipad2}) {
    font-size: 22px;
    line-height: 38px;
  }
`;
export const ContentDescription = styled.div`
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 26px;
  line-height: 150%;
  /* or 39px */

  text-align: center;

  /* Font/header */

  color: #0f1f4a;
  @media (max-width: 430px) {
    font-size: 16px;
  }
  @media (${MediaQuery.Ipad}) {
    font-size: 16px;
  }
  @media (${MediaQuery.Ipad2}) {
    font-size: 16px;
  }
`;
export const HeroButton = styled(Button)`
  width: 198px ;
  // height: 74px !important;
  padding: 15px 7px ;
  /* Primary/default */

  // background: #30bcc2 !important;
  background:linear-gradient(97.99deg, #A0E5E8 -15.4%, #1F9EA4 66.19%, #3A62B1 111.03%); !important;
  border-radius: 25px ;
  font-family: "Montserrat" !important;
  font-style: normal !important;
  font-weight: 600 !important;
  font-size: 16px !important;
  line-height: 150%;
  /* identical to box height, or 24px */

  display: flex;
  align-items: center;
  text-transform: uppercase;

  /* BG/white */

  color: #ffffff !important;
`;

export const SubscribeButton = styled(HeroButton)`
  height: 49px !important;
  width: 140px;
  border-radius: 0 20px 20px 0px !important;
  @media (max-width: 430px) {
    width: 100% !important;
    border-radius: 20px !important;
  }
`;
export const HeroIcon = styled.img`
  width: 70%;
  // :hover{
  //       transform: scale(1.05);
  //       transition: all .4s ease-in-out;
  // }
`;
export const ExploreWrapper = styled.div`
  height: auto;
  padding-bottom: 0px;
  background: #f2fdff;
  /* dropshadow */
  padding: 75px;
  padding-top: 80px;
  box-shadow: 4px 4px 25px #e0e0e0;
  text-align: left;
  padding-right: 40px;
  margin-bottom: 2rem;
  @media (max-width: 430px) {
    height: auto;
    padding: 35px;
  }
  @media (${MediaQuery.Ipad}) {
    height: auto;
  }
  @media (${MediaQuery.Ipad2}) {
    height: auto;
  }
`;
export const ExploreTitle = styled.div`
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  font-size: 40px;
  line-height: 49px;
  text-align: left;
  letter-spacing: -0.03em;

  /* Font/header */

  color: #0f1f4a;
`;
export const ExploreContent = styled.div`
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 25px;
  text-align: left;
  /* Font/header */
  margin-top: 1.5rem;
  color: #0f1f4a;
`;
export const ExploreButton = styled(Button)`
  width: 198px !important;
  // height: 74px !important;
  padding: 15px 7px !important;
  // background: #30bcc2 !important;
     background:linear-gradient(97.99deg, #A0E5E8 -15.4%, #1F9EA4 66.19%, #3A62B1 111.03%); !important;
 !important;
  
  font-family: "Montserrat" !important;
  font-style: normal !important;
  font-weight: 600 !important;
  font-size: 16px !important;
  border-radius: 25px !important;
  text-transform: uppercase !important;
  margin-top: 1.5rem !important;
  /* BG/white */

  color: #ffffff !important;
`;
export const LineWrapper = styled.div`
  display: flex;
  column-gap: 19px;
  align-items: center;
  justify-content: center;
`;
export const LineImage = styled.img`
  width: 29.5%;
  :hover {
    cursor: pointer;
  }
`;
export const TabWrapper = styled.div`
  @media (max-width: 430px) {
    margin-top: 2rem;
  }
`;
export const TabImage = styled.img`
  width: 100%;
  margin-top: 1rem;
`;
export const BuildWrapper = styled.div`
  padding: 75px;

  padding-top: 0;
  padding-bottom: 0;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: left;
  margin-bottom: 2rem;

  /* BG/secondary */

  background: #f7f7fa;
  /* modal_shadow */

  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
  border-radius: 20px;
  @media (max-width: 430px) {
    padding: 35px;
  }
`;
export const BuildIconWrapper = styled(BuildWrapper)`
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 40px 15px;
  flex-direction: column;
  row-gap: 30px;
  padding-top: 0px;
  padding-bottom: 0px;
`;

export const IconWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  // transform: rotate(-7deg);
  padding: 10px;
  width: 100%;
  column-gap: 10px;
`;
export const BuildTitle = styled.div`
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  font-size: 40px;
  line-height: 49px;
  /* identical to box height */

  letter-spacing: -0.03em;

  /* Font/header */

  color: #0f1f4a;
`;
export const BuildContent = styled.div`
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 150%;
  width: 100%;
  // width: 276px;
  /* or 24px */
  margin-top: 1.5rem;

  /* Font/header */

  color: #0f1f4a;
  @media (max-width: 430px) {
    width: auto;
  }
`;
export const BuildImageDiv = styled.div`
  width: 92px;
  height: 92px;

  /* BG/secondary */

  background: #f7f7fa;
  /* Primary/default */

  border: 1px solid #30bcc2;
  border-radius: 20px;
  transform: rotate(-15deg);
`;
export const BuildImageFrame1 = styled.div`
  display: flex;
  align-items: center;
  column-gap: 10px;
  filter: drop-shadow(4px 4px 25px #e0e0e0);
  transform: rotate(-15deg);
`;
export const MerqueeFont = styled.div`
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  font-size: 131.946px;
  line-height: 161px;
  /* identical to box height */

  text-transform: uppercase;

  /* Primary/default */

  color: #30bcc2;
  @media (max-width: 430px) {
    font-size: 32.9865px;
  }
`;
export const TrandingWrapper = styled.div`
  display: flex;
  align-items: center;
`;
export const Tranding1 = styled.div``;
export const Tranding2 = styled.div``;
export const Tranding3 = styled.div``;
interface isProps {
  isBorder: boolean;
}
export const CardDiv = styled.div<isProps>`
  width: 100%;
  // height: 579px;
 // height: 435px;
  border: ${(props) => (props.isBorder ? "0.58px solid #8080804f;" : "null;")}
  border-radius:10px;
  padding:10px;
  background-color: rgb(228,246,250);  
  transition: 0.3s;
  :hover{
    transform: translate(0, -10px);
  }

  @media (max-width: 430px) {
    display:flex;
    align-items: start;
    flex-direction:column;
    height:auto;
    border-right:unset;
    column-gap:15px;
  }
  @media (min-width: 430px)and (max-width:896px) {
    display:flex;
    
    align-items: start;
    height:auto;
    border-right:unset;
    column-gap:15px;
  }
  // @media (${MediaQuery.Ipad}) {
  //   display:flex;
  //   align-items: start;
  //   height:auto;
  //   border-right:unset;
  //   column-gap:15px;
  // }
  // @media (${MediaQuery.Ipad2}) {
  //   display:flex;
  //   align-items: start;
  //   height:auto;
  //   border-right:unset;
  //   column-gap:15px;
  // }
 
`;
export const ImageDiv = styled.div`
  width: 100%;
  height: 200px;
  @media (max-width: 430px) {
    width: 100%;
    height: 100%;
  }
  @media (min-width: 430px) and (max-width: 896px) {
    width: 100%;
    height: 100%;
  }
  // @media (${MediaQuery.Ipad}) {
  //   width: 100%;
  //   height: 100%;
  // }
  // @media (${MediaQuery.Ipad2}) {
  //   width: 100%;
  //   height: 100%;
  // }
`;
export const CardTitle = styled.div`
  height: auto;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 150%;
  color: #0f1f4a;
  display: flex;
  align-items: flex-start;
  text-align: left;
  margin-top: 8px;
  @media (max-width: 430px) {
    font-size: 16px;
    margin-top: 0px;
    height: auto;
    align-items: center;
    column-gap: 9px;
  }
  @media (min-width: 800px) and (max-width: 896px) {
    font-size: 18px;
    margin-top: 0px;
    height: auto;
    align-items: center;
    column-gap: 9px;
  }
  // @media (${MediaQuery.Ipad}) {
  //   font-size: 16px;
  //   margin-top: 0px;
  //   height: auto;
  //   align-items: center;
  //   column-gap: 9px;
  // }
  // @media (${MediaQuery.Ipad2}) {
  //   font-size: 16px;
  //   margin-top: 0px;
  //   height: auto;
  //   align-items: center;
  //   column-gap: 9px;
  // }
`;
export const CardDiscription = styled.div`
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 150%;
  /* or 21px */

  display: flex;
  align-items: start;

  /* Font/header */

  color: #0f1f4a;
  height: 146px;
  text-align: left;
  display: flex;
  flex: 1;
  margin: 20px 0;
  @media (max-width: 430px) {
    font-size: 12px;
    height: auto;
    margin-top: 0.5rem;
  }
  @media (min-width: 800px) and (max-width: 896px) {
    font-size: 19px;
    height: auto;
    margin-top: 0.5rem;
  }
  // @media (${MediaQuery.Ipad}) {
  //   font-size: 12px;
  //   height: auto;
  //   margin-top: 0.5rem;
  // }
  // @media (${MediaQuery.Ipad2}) {
  //   font-size: 12px;
  //   height: auto;
  //   margin-top: 0.5rem;
  // }
`;

export const BlogWrapper = styled.div`
  background: #ffffff;
  // height: 653px;
  height: 435px;
  margin-bottom: 2rem;
  @media (max-width: 430px) {
    height: auto;
  }
  @media (min-width: 430px) and (max-width: 896px) {
    height: auto;
  }
  // @media (${MediaQuery.Ipad}){
  //    height: auto;
  // }
`;

export const Title = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center !important;
`;

export const Subtitle = styled.div`
  font-weight: 700;
  font-size: 40px;
  letter-spacing: -0.03em;
`;

export const BlogTitle = styled.div`
  font-family: "Montserrat";
  flex-direction: column;
  // align-items: flex-start !important;
  align-items: center !important;
  column-gap: 10px;
  font-style: normal;
  font-weight: 600;
  font-size: 90px;
  line-height: 108.52%;
  /* or 98px */

  display: flex;
  align-items: center;
  letter-spacing: -0.05em;

  /* Font/header */

  color: #0f1f4a;
  text-align: center;
  width: 100%;
  justify-content: center;
  margin-bottom: 3rem;
  @media (max-width: 430px) {
    font-size: 26px;
    /* row-gap: 19px; */
    line-height: 42px;
  }
  @media (min-width: 430px) and (max-width: 896px) {
    font-size: 26px;
    /* row-gap: 19px; */
    line-height: 42px;
  }
`;
export const TrendingText = styled.h3`
  // -webkit-text-stroke: 1px black;
  color: white;
  text-shadow: 2px 2px 0 #000, -1px -1px 0 #000, 1px -1px 0 #000,
    -1px 1px 0 #000, 1px 1px 0 #000;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  font-size: 125px;
  line-height: 132px;
  /* identical to box height */
  margin: 0;
  height: 155px;
  margin-bottom: 2rem;

  text-transform: uppercase;

  /* background/main */

  color: #ffffff;

  /* Font/header */
  @media (max-width: 430px) {
    font-size: 54px;
    height: auto;
  }
  @media (min-width: 430px) and (max-width: 896px) {
    font-size: 54px;
    height: auto;
  }
`;

export const NewsLetterWrapper = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  background-image: url(${Newsletter_vector});
  background-repeat: no-repeat;
  background-size: contain;
  padding: 50px 0;
  row-gap: 20px;
  background-color: rgb(228, 246, 250);
  @media (max-width: 430px) {
    padding-right: 10px;
    padding-left: 10px;
    row-gap: 30px;
    margin-bottom: 3rem;
  }
  @media (min-width: 430px) and (max-width: 896px) {
    padding-right: 10px;
    padding-left: 10px;
    row-gap: 30px;
    margin-bottom: 3rem;
  }
`;

export const NewsLetterTitle = styled.div`
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 90px;

  color: #0f1f4a;
  @media (max-width: 430px) {
    font-size: 50px;
    text-align: center;
  }
  @media (min-width: 430px) and (max-width: 896px) {
    font-size: 50px;
    text-align: center;
  }
`;

export const Desc = styled.div`
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  text-align: center;
  color: #0f1f4a;
  @media (max-width: 430px) {
    font-size: 16px;
    text-align: center;
  }
  @media (min-width: 430px) and (max-width: 896px) {
    font-size: 16px;
    text-align: center;
  }
`;

export const Input = styled.input.attrs({ type: "email" })`
  width: 270px;
  height: 50px;
  padding: 5px 16px;
  // margin-right: 15px;
  border-radius: 20px 0 0 20px;
  outline: none;
  border: none;
  font-size: 16px;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  @media (max-width: 430px) {
    border-radius: 20px;
    width: 100%;
    margin-right: 0px;
  }
  @media (min-width: 430px) and (max-width: 896px) {
    width: 100%;
    margin-right: 0px;
  }
`;

export const TgInput = styled.input.attrs({ type: "number" })`
  height: 60px;
  padding: 5px 16px;
  margin-right: 6px;
  border-radius: 20px;
  outline: none;
  border: none;
  font-size: 16px;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  @media (max-width: 430px) {
    width: 100%;
    margin-right: 0px;
    height: 54px;
  }
  @media (min-width: 430px) and (max-width: 896px) {
    width: 100%;
    margin-right: 0px;
    height: 60px;
  }
`;
export const TextInput = styled.input.attrs({ type: "text" })`
  // width: 270px;
  height: 50px;
  width: 100%;
  margin-right: 0px;
  padding: 5px 16px;
  margin-right: 15px;
  border-radius: 20px;
  outline: none;
  border: none;
  font-size: 16px;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;

  @media (max-width: 430px) {
    width: 100%;
    margin-right: 0px;
    height: 40px;
    padding: 5px 9px;
  }

  @media (min-width: 430px) and (max-width: 896px) {
    width: 100%;
    margin-right: 0px;
  }

  @media (min-width: 1024px) {
    width: 100%;
  }
`;

export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 10px;
`;
