import React from "react";
import {
  CopyWrapper,
  FooterWrapper,
  FooterWrapper1,
  LinkDataWrap,
  LinkWrapper,
  LogoWrapper,
  SocialWrapper,
  Text,
  Title,
} from "./Footer.style";
// import Logo from "../../assets/images/logo2.png";
import Logo from "../../assets/images/beta.png";
import { Container } from "@mui/material";
import Facebook from "../../assets/Social/Linkedin.png";
import Twitter from "../../assets/Social/twitter.png";
import Telegram from "../../assets/Social/telegram.png";
import Discord from "../../assets/Social/Discord.png";
import Dapps from "../../assets/Social/dapps.png";
import Wordpress from "../../assets/Social/Wordpress.png";
import { Link, useNavigate } from "react-router-dom";
import { useWeb3React } from "@web3-react/core";

const Footer = () => {
  const navigate = useNavigate();
  const handleAbout = () => {
    navigate("/AboutUs");
  };
  const handleContactUs = () => {
    navigate("/ContactUs");
  };
  const handleFAQ = () => {
    navigate("/FAQ");
  };
  const { chainId } = useWeb3React();

  return (
    <FooterWrapper>
      <Container maxWidth="xl">
        <LinkWrapper>
          <LogoWrapper>
            <Link to="/">
              <img src={Logo} width={170} style={{ cursor: "pointer" }} />
            </Link>
          </LogoWrapper>
          <FooterWrapper1>
            <LinkDataWrap>
              <Title>Dapps</Title>
              <Text onClick={handleAbout}>About us</Text>
              <a
                href="https://careers.dapps.co/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <Text onClick={handleAbout}>Careers</Text>
              </a>

              <a
                href="https://discord.gg/bXBm5PQueS"
                target="_blank"
                rel="noopener noreferrer"
              >
                <Text>Support Hub</Text>
              </a>
              <Text onClick={handleContactUs}>Contact Us</Text>
              <Text onClick={handleFAQ}>FAQ</Text>

              <a
                href="https://docs.google.com/forms/d/e/1FAIpQLSfoY_mrQ4bvjm3rNdZxJf9Pw_lojYcDL9BCjdYNUlyjgzLF1g/viewform"
                target="_blank"
                rel="noopener noreferrer"
              >
                <Text>Apply For Grants</Text>
              </a>
            </LinkDataWrap>
            <LinkDataWrap>
              <Title>Features</Title>
              <Text>Decentralized appstore</Text>
              <Text onClick={() => navigate("/compose-message")}>
                Unstoppable messaging
              </Text>
              {/* notification */}
              <Text onClick={() => navigate("/notification")}>
                Notifications
              </Text>
              <a
                href="https://feed.dapps.co/"
                target="_blank"
                rel="noopener noreferrer"
              >
                {/* <Text>Apply For Grants</Text> */}
                <Text>dapps Feed</Text>
              </a>
            </LinkDataWrap>
            <LinkDataWrap>
              <Title>Follow Us</Title>
              <SocialWrapper>
                <a
                  href="https://www.linkedin.com/company/dappsco/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    src={Facebook}
                    style={{ cursor: "pointer" }}
                    width={35}
                  />
                </a>
                <a
                  href="https://twitter.com/dapps_co?s=21"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src={Twitter} style={{ cursor: "pointer" }} width={35} />
                </a>
                <a
                  href="https://discord.com/invite/SH5pJuj28U"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src={Discord} style={{ cursor: "pointer" }} width={35} />
                </a>
                <a
                  href="https://t.me/dapps_co"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    src={Telegram}
                    style={{ cursor: "pointer" }}
                    width={35}
                  />
                </a>

                <a
                  href="https://feed.dapps.co/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    src={Wordpress}
                    style={{ cursor: "pointer" }}
                    width={35}
                  />
                </a>
              </SocialWrapper>
            </LinkDataWrap>
          </FooterWrapper1>
        </LinkWrapper>
        <CopyWrapper>
          <Text>© 2022 dapps.co. All rights reserved.</Text>
          <Text>Version 2.0</Text>
          {/* <Text>
            <img src={Dapps} width={25} />
            <b style={{ color: "#30BCC2" }}>19,771,620</b> Dapps Uploaded
          </Text> */}
        </CopyWrapper>
      </Container>
    </FooterWrapper>
  );
};
export default Footer;
