import React, { useEffect } from "react";
import Header from "components/LandingPage/Header";
import GuidelineHeroSection from "./HeroSection";
import LogoUsage from "./LogoUsage";
import DappsIcon from "./DappsIcon";
import DappsFontFamily from "./DappsFontFamily";
import DappsColorCode from "./DappsColorCode";
import Footer from "components/LandingPage/Footer";
import { useNavigate, useLocation } from "react-router-dom";
const Guidelines = () => {
  const Location = useLocation();

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, [Location]);

  return (
    <>
      <Header />
      {/* HERO SECTION */}
      <GuidelineHeroSection />
      {/* Logo Usage */}
      <LogoUsage />
      {/* FavIcon and Social icons */}
      <DappsIcon />
      {/* Color Code */}
      <DappsColorCode />
      {/* Typography */}
      <DappsFontFamily />
      <Footer />
    </>
  );
};

export default Guidelines;
