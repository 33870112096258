import { useMemo } from "react";
import { useWeb3React } from "@web3-react/core";
import { Contract } from "ethers";
import { isEmpty } from "lodash";
import MESSAGING_CONTRACT_ABI from "../constants/abi/MessagingContract";
import WALLET_REGISTRY_CONTRACT_ABI from "../constants/abi/WalletRegistry";
import { ExternalProvider } from "@ethersproject/providers";
import { getJsonProvider } from "utilities/provider";

export const useContract = (ABI, address: string) => {
  const { library, chainId } = useWeb3React();
  return useMemo(() => {
    if (!address || !library || !chainId) return null;
    return new Contract(address, ABI, library);
  }, [ABI, address, library, chainId]);
};

export const useContractJsonRPC = (ABI, address: string, chainId: number) => {
  const provider = getJsonProvider(chainId);
  return useMemo(() => {
    if (!chainId || isEmpty(ABI)) return null;
    return new Contract(address, ABI, provider);
  }, [chainId, ABI]);
};

export const useMessagingContract = (address: string) => {
  return useContract(MESSAGING_CONTRACT_ABI.abi, address);
};

export const useWalletRegistryContract = (address: string, chainId: number) => {
  return useContractJsonRPC(WALLET_REGISTRY_CONTRACT_ABI.abi, address, chainId);
};

export const useBiconomyContractinstance = (
  address: string,
  ABI: any,
  provider: any
): Contract | null => {
  return useMemo(() => {
    if (!address || !provider) return null;
    return new Contract(address, ABI, provider);
  }, [ABI, address, provider]);
};
