import styled from "styled-components";
import FormControl from "@mui/material/FormControl";
import Button from "@mui/material/Button";
import MenuItem from "@mui/material/MenuItem";
import TextField from "@mui/material/TextField";
import { MediaQuery } from "const/MediaQuery";
import { useMobile2 } from "const/MuiMediaQuery";
import LinearProgress from "@mui/material/LinearProgress";
import ListItemText from "@mui/material/ListItemText";
import { Accordion, Skeleton } from "@mui/material";
export const CustomeMenu = styled(MenuItem)``;
export const ProfileDetailRoot = styled.div`
  width: 350px;
  height: 120px;
  display: flex;
  align-items: center;
  column-gap: 10px;
  /* BG/white */

  background: #ffffff;
  /* Font/Secondary */

  border: 1px solid #dcdede;
  /* shadow */

  box-shadow: 6px 6px 14px rgba(189, 195, 199, 0.18);
  & .profile-root {
  }
  border-radius: 25px;
  & .profile-name {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 150%;
    /* identical to box height, or 24px */

    display: flex;
    align-items: center;

    /* Font/primary */

    color: #231f20;
  }
  & .profile-address {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 150%;
    /* identical to box height, or 24px */

    display: flex;
    align-items: center;

    /* Font/primary */

    color: #231f20;
  }
  @media (max-width: 430px) {
    width: 100%;
  }
`;
export const FormControlCustome = styled(FormControl)`
  & MuiPaper-root. {
    border-radius: 25px !important;
  }
  & .MuiSelect-outlined {
    text-align: left !important;
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 150%;
    /* identical to box height, or 24px */

    display: flex;
    align-items: center;

    /* Font/primary */

    color: #231f20;
  }
  & .MuiOutlinedInput-root {
    width: 276px !important;
    height: 120px;

    /* BG/white */

    background: #ffffff;
    /* Font/Secondary */

    border-radius: 25px;
  }
  & .MuiOutlinedInput-notchedOutline {
    border-color: #dcdede !important;
  }
`;
export const TabContentWrapper = styled.div`
  row-gap: 30px;
  left: 286px;

  top: 335px;
  padding: 30px;
  /* BG/white */
  justify-content: start;
  display: flex;
  flex-flow: wrap;
  clumn-gap: 30px;
  column-gap: 30px;
  background: #ffffff;
  /* Font/Secondary */

  border: 1px solid #dcdede;
  border-radius: 20px;
  @media (max-width: 430px) {
    border: unset;
    padding: 0px;
    padding-top: 20px;
    height: 100%;
  }
`;
export const TabContentWrapper2 = styled.div`
  row-gap: 30px;
  left: 286px;

  top: 335px;
  padding: 30px;
  /* BG/white */
  justify-content: start;
  display: flex;
  flex-flow: wrap;
  clumn-gap: 30px;
  column-gap: 30px;
  background: #ffffff;
  /* Font/Secondary */

  border: 1px solid #dcdede;
  border-radius: 20px;
  @media (max-width: 430px) {
    border: unset;
    padding: 0px;
    padding-top: 20px;
    height: auto;
    width: 100%;
  }
`;
export const DappsWrapper = styled.div`
  width: 331px;
  height: auto;
  left: 319px;
  top: 375px;
  padding-bottom: 1rem;
  /* BG/white */

  background: #ffffff;
  /* Font/Secondary */

  border: 1px solid #dcdede;
  border-radius: 20px;
  :hover {
    box-shadow: 6px 6px 14px rgb(189 195 199 / 18%);
    cursor: pointer;
    border: 1px solid #30bcc2;
    transition: box-shadow 0.2s ease-in-out;
  }
  @media (min-width: 500px) and (max-width: 768px) {
    width: 100%;
  }
  @media (max-width: 430px) {
    width: 100%;
    padding-bottom: 0rem;
  }
  @media (${MediaQuery.Ipad}) {
    width: 306px !important;
  }
  @media (${MediaQuery.Ipad2}) {
    width: 306px !important;
  }
`;

export const CategoryWrapper = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 4px;
  overflow: hidden;
`;
export const ChipWrapper = styled.div`
  display: flex;
  align-items: center;
  column-gap: 10px;
  width: auto;
  overflow: auto;
  & .scroller {
    display: none;
  }
  ::-webkit-scrollbar {
    display: none;
  }
`;
export const ItemTitle = styled.div`
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 150%;
  /* identical to box height, or 30px */
  text-align: left;
  display: flex;
  align-items: center;
  column-gap: 4px;
  color: #000000;
`;
export const FontIndex = styled.div`
  font-family: "Montserrat";
  font-style: normal;
  font-weight: bold;
  font-size: 66px;
  line-height: 150%;
  position: absolute;
  color: #dcdede;
  top: 9px;
  z-index: -1;
  left: 12px;
`;

export const ItemsWrapper = styled.div`
  filter: drop-shadow(0px 1px 0px rgba(0, 0, 0, 0.09));
  position: relative;
  display: flex;
  align-items: center;
  padding-left: 20px;
  // height: 170px;
  height: 143px;
  position: relative;
  @media (max-width: 430px) {
    column-gap: 1px;
  }
`;

export const DashBoardButon = styled(Button)`
  width: 100% !important;
  height: 55px !important;

  /* BG/white */

  background: #ffffff !important;
  /* Primary/default */

  border: 1px solid #30bcc2 !important;
  border-radius: 25px !important;
  font-family: "Montserrat" !important;
  font-style: normal !important;
  font-weight: 600 !important;
  font-size: 12px !important;
  line-height: 150% !important;
  /* identical to box height, or 24px */

  display: flex !important;
  align-items: center !important;

  /* Primary/default */

  color: #30bcc2 !important;

  @media (max-width: 430px) {
    text-transform: capitalize !important;
    width: 100% !important;
  }
  @media (${MediaQuery.Ipad}) {
    text-transform: capitalize !important;
    width: 100% !important;
  }
  // :hover{
  //   background:#30bcc2 !important;
  //   color:#ffffff !important;
  // }
`;

export const ViewDappsButton = styled(Button)`
  width: 196px !important;
  height: 55px !important;

  /* BG/white */

  background: #ffffff !important;
  /* Primary/default */

  border: 1px solid white !important;
  border-radius: 25px !important;
  font-family: "Montserrat" !important;
  font-style: normal !important;
  font-weight: 600 !important;
  font-size: 12px !important;
  line-height: 150% !important;
  /* identical to box height, or 24px */

  display: flex !important;
  align-items: center !important;

  /* Primary/default */

  color: #30bcc2 !important;
  @media (max-width: 430px) {
    display: none !important;
  }
  @media (${MediaQuery.Ipad}) {
    display: none !important;
  }
`;
export const ButtonWrapper = styled.div`
  padding-left: 20px;
  display: flex;
  column-gap: 15px;

  @media (max-width: 430px) {
    justify-content: center;
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 20px;
  }
  @media (${MediaQuery.Ipad}) {
    justify-content: center;
    padding-left: 20px;
    padding-right: 20px;
  }
`;
export const GraphWrapper = styled.div`
  height: auto;
  left: 287px;
  top: 199px;
  padding: 20px;

  /* BG/white */

  background: #ffffff;
  /* Font/Secondary */

  border: 1px solid #dcdede;
  border-radius: 20px;
`;
export const NotificationWrapper = styled.div`
  height: auto;
  left: 1006px;
  top: 145px;

  /* BG/white */

  background: #ffffff;
  /* Font/Secondary */

  border: 1px solid #dcdede;
  border-radius: 20px;
`;
export const BreadcrumbsWrapper = styled.div`
  margin-top: 1rem;
  margin-bottom: 1rem;
  & .MuiLink-root {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 150%;
    /* identical to box height, or 24px */

    /* Font/subheader */

    color: #555555;
  }
  & .css-1gqyz35-MuiTypography-root {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 150%;
    /* identical to box height, or 24px */

    /* Font/primary */

    color: #231f20;
  }
`;
export const CounterWrapper = styled.div`
  display: flex;
  column-gap: 45px;
  margin-top: 47px;
  flex-flow: wrap;
  row-gap: 33px;
  @media (max-width: 430px) {
    column-gap: 1px;
    row-gap: 33px;
    flex-flow: nowrap;
    justify-content: space-between;
  }
  @media (${MediaQuery.Ipad}) {
    flex-flow: wrap;
    row-gap: 33px;
  }
`;
export const NotificationText = styled.div`
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  text-align: left;
  padding-left: 20px;
  padding-right: 20px;
  font-size: 16px;
  line-height: 150%;
  /* or 24px */

  color: #000000;
  @media (max-width: 430px) {
    margin-top: 0.5rem;
  }
`;
export const PreviewText = styled.div`
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;
  /* or 24px */
  padding: 0 20px;
  margin-top: -11px;
  display: flex;
  align-items: center;

  /* Font/tertiary */
  word-break: break-all;
  color: #9fa0a3;
`;

export const NotificationButton = styled(Button)`
  width: 100% !important;
  height: 74px !important;

  /* Primary/default */

  background: #30bcc2 !important;
  box-shadow: 3px 1px 18px rgba(48, 188, 194, 0.47) !important;
  border-radius: 20px !important;

  font-family: "Montserrat" !important;
  font-style: normal !important;
  font-weight: 600 !important;
  font-size: 16px !important;
  line-height: 150% !important;
  /* identical to box height, or 24px */

  display: flex !important;
  align-items: center !important;

  /* BG/white */

  color: #ffffff !important;
`;
export const NotificationDisableButton = styled(Button)`
  width: 100% !important;
  height: 74px !important;

  /* Primary/default */

  background: grey !important;
  box-shadow: unset !important;
  border-radius: 20px !important;

  font-family: "Montserrat" !important;
  font-style: normal !important;
  font-weight: 600 !important;
  font-size: 16px !important;
  line-height: 150% !important;
  /* identical to box height, or 24px */

  display: flex !important;
  align-items: center !important;

  /* BG/white */

  color: white !important;
  :hover {
    cursor: not-allowed !important;
  }
`;
export const ButtonWrapperNotification = styled.div`
  padding-left: 20px;
  padding-right: 20px;
  margin-top: 1rem;
  @media (max-width: 430px) {
    padding: 0px;
    margin-top: 0.5rem;
  }
`;
export const SubScribeWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  padding: 20px 20px 15px;
  width: auto;
  height: auto;
  row-gap: 20px;
`;
export const ListWrapper = styled.div`
display: flex;
    flex-direction: column;
   
}
`;
export const SubScribeList = styled.div`
  display: flex;
  align-items: center;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 150%;
  /* or 24px */

  display: flex;
  align-items: center;
  background: #ffffff;
  /* line divider */
  height: 74px;
  box-shadow: 0px 1px 0px rgba(0, 0, 0, 0.09);
  /* Font/subheader */

  color: #555555;
  column-gap: 7px;
`;
export const DropdonwListStyle = styled.div`
  display: flex;
  align-items: center;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  width: 100%;
  font-size: 16px;
  line-height: 150%;
  /* or 24px */
  justify-content: space-between;
  display: flex;
  align-items: center;

  /* line divider */
  height: 74px;
  box-shadow: unset;
  /* Font/subheader */

  color: #555555;
  column-gap: 15px;
`;
export const SubscribeScroll = styled.div`
  overflow: auto;
  height: 275px;

  ::-webkit-scrollbar {
    display: none;
  }
  @media (max-width: 430px) {
    width: 100%;
  }
`;
export const SearchWrapper = styled.div`
  width: 100%;
`;
export const SearhInput = styled(TextField)`
background: #ffffff;
border-color:  #9fa0a3;
width:100%;
border-radius: 25px;
& .MuiOutlinedInput-root {
  border-radius: 20px;
}
& .MuiOutlinedInput-notchedOutline {
  border-color: #9FA0A3 !important;
`;
export const CustomeSelect = styled(FormControl)`
  width: 100% !important;
  margin: 0px !important;
  & .MuiInputLabel-root {
    color: #9fa0a3 !important;
    width: 28% !important;
    background: WHITE !important;
  }
  & .MuiInputLabel-root.Mui-focused {
    color: #9fa0a3 !important;
  }
  & .MuiOutlinedInput-root {
    border-radius: 15px;
    background: #ffffff;
    /* Font/tertiary */

    // border: 1px solid #9fa0a3;
    border-color: #9fa0a3 !important;
  }
  & .MuiOutlinedInput-notchedOutline {
    border-color: #9fa0a3 !important;
  }
`;
export const CustomeTextField = styled(TextField)`
  background: #ffffff;
  /* Font/tertiary */

  border-color: #9fa0a3 !important;
  border-radius: 25px;
  & .MuiOutlinedInput-root {
    border-radius: 15px;
  }
  & .MuiOutlinedInput-notchedOutline {
    border-color: #9fa0a3 !important;
  }
  input {
    ::-webkit-input-placeholder {
      font-family: "Montserrat" !important;
      font-style: normal !important;
      font-weight: 400 !important;
      font-size: 16px !important;
      line-height: 150% !important;
      /* identical to box height, or 24px */

      display: flex !important;
      align-items: center !important;

      /* Font/tertiary */

      color: #9fa0a3 !important;
    }
  }
  textarea {
    ::-webkit-input-placeholder {
      font-family: "Montserrat" !important;
      font-style: normal !important;
      font-weight: 400 !important;
      font-size: 16px !important;
      line-height: 150% !important;
      /* identical to box height, or 24px */

      display: flex !important;
      align-items: center !important;

      /* Font/tertiary */

      color: #9fa0a3 !important;
    }
  }
`;
export const PlaceHoderSpan = styled.div`
  font-family: "Montserrat" !important;
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 16px !important;
  line-height: 150% !important;
  /* identical to box height, or 24px */

  display: flex !important;
  align-items: center !important;

  /* Font/tertiary */

  color: #9fa0a3 !important;
`;
export const LabelButton = styled(Button)`
  width: 142px !important;
  height: 61px !important;

  /* BG/white */

  background: #ffffff !important;
  /* Primary/default */

  border: 1px solid #30bcc2 !important;
  border-radius: 22px !important;
  font-family: "Montserrat" !important;
  font-style: normal !important;
  font-weight: 600 !important;
  font-size: 16px !important;
  line-height: 150% !important;
  /* identical to box height, or 24px */

  display: flex !important;
  align-items: center !important;

  /* Primary/default */

  color: #30bcc2 !important;
  @media (max-width: 430px) {
    display: none !important;
  }
`;
export const ModalButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;

  gap: 10px;
  margin-top: 20px;
`;
interface isPropsButton {
  isDisable?: boolean;
}
export const NotifyButton = styled(Button)<isPropsButton>`
  width: 182px !important;
  height: 74px !important;

  /* Primary/default */

  background: ${(props) =>
    props.isDisable ? "grey !important" : "#30bcc2 !important"};
  box-shadow: ${(props) =>
    props.isDisable
      ? "unset"
      : "3px 1px 18px rgba(48, 188, 194, 0.47) !important"};
  border-radius: 20px !important;
  font-family: "Montserrat" !important;
  font-style: normal !important;
  font-weight: 600 !important;
  font-size: 16px !important;
  line-height: 150% !important;
  /* identical to box height, or 24px */

  display: flex !important;
  align-items: center !important;

  /* BG/white */

  color: #ffffff !important;
  @media (max-width: 430px) {
    text-transform: capitalize !important;
    width: 100% !important;
  }
`;
export const EmptyWrap = styled.div`
  margin: 0px auto;
`;
export const CancelButton = styled(Button)`
  width: 182px !important;
  height: 74px !important;

  /* Primary/default */

  background: transparent !important;
  box-shadow: unset !important;
  border-radius: 20px !important;
  font-family: "Montserrat" !important;
  font-style: normal !important;
  font-weight: 600 !important;
  font-size: 16px !important;
  line-height: 150% !important;
  /* identical to box height, or 24px */

  display: flex !important;
  align-items: center !important;

  /* BG/white */

  color: #30bcc2 !important;
`;

export const NotificationImage = styled.img`
  border: 1px solid #dcdede;
  /* shadow */

  filter: drop-shadow(6px 6px 14px rgba(189, 195, 199, 0.18));
  border-radius: 80px;
  width: 95px;
  height: 95px;
  margin-right: 10px;
  @media (max-width: 430px) {
    height: 70px;
    width: 70px;
  }
`;

export const PreviewDiv = styled.div`

height: 120px;

/* Font/tertiary */
font-family: 'Montserrat';
font-style: normal;
font-weight: 400;
font-size: 16px;
/* Font/tertiary */
display: flex;
color: #9FA0A3;
border: 1px solid #9FA0A3;
border-radius: 25px;
justify-content: center;
align-items: center;
background:white !important;
}
`;
export const PreviewTitle = styled.div`
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 150%;
  /* identical to box height, or 27px */
  margin-bottom: 0.7rem;
  text-transform: uppercase;

  /* Font/header */

  color: #0f1f4a;
`;
export const NotificationPreview = styled.div`
  height: auto;
  padding-bottom: 25px;
  /* BG/white */

  background: #ffffff;
  /* BG/tertiary */
  padding-right: 20px;
  border: 1px solid #f8f8f8;
  /* shadow */

  box-shadow: 6px 6px 14px rgba(189, 195, 199, 0.18);
  border-radius: 25px;
`;
export const CTALabel = styled.div`
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 150%;
  /* identical to box height, or 24px */
  padding-left: 20px;
  margin-top: 0.5rem;
  display: flex;
  align-items: center;
  word-break: break-all;
  :hover {
    cursor: pointer;
  }

  /* Primary/default */

  color: #30bcc2;
`;
export const WalletAddressWrap = styled.div`
  width: 100%;
`;
export const CustomizeProgress = styled(LinearProgress)`
height: 34px !important;
background-color: #30BCC2 !important;
border-radius: 54px !important;
& .MuiLinearProgress-bar1Determinate{
  background-color: #A0E5E8 !important;
    border-radius: 54PX !important;
}
}
`;
export const ProgressLable = styled.div`
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 150%;
  color: #0f1f4a;
  position: absolute;
  z-index: 1;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  letter-spacing: 1.1px;
  @media (max-width: 430px) {
    font-size: 14px;
  }
`;
export const DilougWrapper = styled.div`
  width: 670px;
  display: flex;
  justify-content: center;
  @media (max-width: 430px) {
    width: 100%;
  }
`;
export const GloabalWrapper = styled.div`
  width: 545px;

  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  @media (max-width: 430px) {
    width: 100%;
  }
`;
export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 85%;
  row-gap: 20px;
  margin-bottom: 1rem;
`;

export const WidgetWrapper = styled.div`
  height: 306px;
  background: #ffffff;
  border: 1px solid #dcdede;
  border-radius: 20px;
  padding: 25px;

  display: flex;
  /* align-items: center; */
  column-gap: 34px;
  width: auto;
  @media (max-width: 430px) {
    height: auto;
    flex-direction: column;
  }
  @media (${MediaQuery.Ipad2}) {
    height: auto;
    flex-direction: column;
    row-gap: 1rem;
  }
`;
export const WidgetWrapper2 = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 35px;
  @media (max-width: 430px) {
    margin-bottom: 2.5rem;
  }
`;
export const WidgetWrapper3 = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 35px;
  @media (max-width: 430px) {
    flex-direction: column;
    row-gap: 35px;
  }
  @media (${MediaQuery.Ipad}) {
    display: flex;
    flex-direction: revert;
    justify-content: space-between;
    align-items: center;
    column-gap: 3%;
  }
`;
export const Widgettext = styled.div`
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 150%;
  /* identical to box height, or 30px */

  color: #000000;
  @media (max-width: 430px) {
    font-size: 16px;
  }
`;

export const Widget1 = styled.div`
  width: 321px;
  height: 63.36px;

  background: rgba(160, 229, 232, 0.2);
  /* Primary/default */

  border: 1px solid #30bcc2;
  box-shadow: 4px 4px 20px rgba(188, 239, 241, 0.3);
  border-radius: 15px;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 150%;
  /* identical to box height, or 24px */

  display: flex;
  align-items: center;

  /* Primary/Hover */

  color: #1f9ea4;

  padding-left: 8px;
  padding-right: 8px;
  display: flex;
  align-items: center;
  column-gap: 13px;
  @media (max-width: 430px) {
    font-size: 14px;
    width: 100%;
  }
`;
export const Widget2 = styled.div`
  width: 236px;
  height: 54px;
  padding-left: 8px;
  padding-right: 8px;
  display: flex;
  align-items: center;
  column-gap: 13px;
  background: rgba(160, 229, 232, 0.2);
  /* Primary/default */

  border: 1px solid #30bcc2;
  box-shadow: 4px 4px 20px rgba(188, 239, 241, 0.3);
  border-radius: 15px;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 150%;
  /* identical to box height, or 18px */

  display: flex;
  align-items: center;

  /* Primary/Hover */

  color: #1f9ea4;
  @media (max-width: 430px) {
    font-size: 11px;
    width: 90%;
  }
`;

export const WidgetCode = styled.div`
  width: 321px;
  height: auto;

  background: rgba(160, 229, 232, 0.2);
  /* Primary/default */

  border: 1px solid #30bcc2;
  box-shadow: 4px 4px 20px rgba(188, 239, 241, 0.3);
  border-radius: 15px;
  overflow: hidden;
  padding: 10px;
  padding-left: 15px;
  padding-right: 15px;
  @media (max-width: 430px) {
    width: 100%;
  }
  @media (${MediaQuery.Ipad2}) {
    width: auto;
  }
`;
export const CodeWrapp = styled.div`
  width: 100%;
  overflow: auto;
  background: rgba(160, 229, 232, 0.2);
  border: 1px solid #30bcc2;
  margin-top: 0.8rem;
  border-radius: 7px;
`;
export const Codetext = styled.div`
  font-size: 16px;
  padding: 0.5rem;
`;
export const CodeHeading = styled.div`
  margin-top: 0.8rem;
  color: #1f9ea4;
  font-size: 16px;
  font-weight: 800;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
export const Wrappdata = styled.div`
  overflow: auto;
  height: 190px;
  width: 100%;
  overflow-x: hidden;
  padding-right: 15px;

  @media (${MediaQuery.Ipad2}) {
  }
`;

export const ViewDocumnet = styled(Button)`
  width: 200px;
  height: 50px;
  background: #30bcc2 !important;
  box-shadow: 3px 1px 18px rgb(48 188 194 / 47%) !important;
  border: 1px solid #30bcc2 !important;
  border-radius: 17px !important;
  color: white !important;
  text-transform: capitalize !important;
  font-size: 14px !important;
  font-family: "Montserrat" !important;
  display: -webkit-box !important;
  display: -webkit-flex !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-align-items: center !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -webkit-column-gap: 8px !important;
  column-gap: 8px !important;
  font-weight: 600 !important;
`;
export const ViewDocumnetWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 77%;
`;
export const EbookWrapper = styled.div`
  height: 500px;
  left: 286px;
  top: 335px;
  padding: 20px;
  /* BG/white */

  background: #ffffff;
  /* Font/Secondary */

  border: 1px solid #dcdede;
  border-radius: 20px;
`;
export const EbookButton = styled(Button)`
  width: 219px !important;
  height: 74px !important;

  /* Primary/default */

  background: #30bcc2 !important;
  box-shadow: 3px 1px 18px rgba(48, 188, 194, 0.47) !important;
  border-radius: 20px !important;
  font-family: "Montserrat" !important;
  font-style: normal !important;
  font-weight: 600 !important;
  font-size: 16px !important;
  line-height: 150% !important;
  /* identical to box height, or 24px */

  display: flex;
  align-items: center;

  /* BG/white */

  color: #ffffff !important;
  text-transform: capitalize !important;
`;
export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  row-gap: 1.5rem;
`;
export const EbookCardWrapper = styled.div`
  width: 471px !important;
  height: 222px !important;
  left: 311px !important;
  top: 357px !important;
  padding: 17px;

  /* BG/active */

  background: #fafcff !important;
  /* Font/tertiary */

  border: 1px solid #9fa0a3 !important;
  border-radius: 16px !important;
  display: flex;
  align-items: center;
  column-gap: 28px;
  @media (max-width: 600px) {
    width: 100% !important;
    align-items: start;
  }
`;

export const ImageWrapper = styled.div``;
export const ContentWrapperDiv = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  row-gap: 10px;
`;
export const TitleWrapper = styled.div`
  font-family: "Montserrat" !important;
  font-style: normal !important;
  font-weight: 600 !important;
  font-size: 20px !important;
  line-height: 150% !important;
  /* or 30px */

  /* Primary/default */

  color: #30bcc2 !important;
`;
export const DivTitle = styled.div`
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 150%;
  /* identical to box height, or 30px */

  display: flex;
  align-items: center;

  /* Font/header */

  color: #0f1f4a;
  width: 100px;
  @media (max-width: 600px) {
    font-size: 15px;
  }
`;
export const Wrappertext = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
export const AddMore = styled(Button)`
  width: 109px !important;
  height: 48px !important;

  /* Primary/default */

  background: #30bcc2 !important;
  box-shadow: 3px 1px 18px rgba(48, 188, 194, 0.47) !important;
  border-radius: 16px !important;
  font-family: "Montserrat" !important;
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 12px !important;
  line-height: 150% !important;
  color: #ffffff !important;
`;
export const NetwrokDiv = styled.div`
  height: 50px;

  /* BG/white */

  background: #ffffff;
  /* Font/Secondary */

  border: 1px solid #dcdede;
  /* shadow */

  box-shadow: 6px 6px 14px rgba(189, 195, 199, 0.18);
  border-radius: 25px;
  width: 300px;
  ont-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 150%;
  /* identical to box height, or 24px */

  display: flex;

  align-items: center;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  justify-content: space-between;
  :hover {
    cursor: pointer;
  }
  @media (max-width: 430px) {
    font-size: 12px;
    width: 100%;
  }
`;
export const HeaderWrapp = styled.div`
  display: flex;
  column-gap: 15px;
  align-items: center;
  margin-left: 1.1rem;
  @media (max-width: 430px) {
    width: 100%;
  }
  & > div {
    @media (max-width: 430px) {
      width: 100%;
    }
  }
`;
export const NetworkHeaderWrapp = styled.div``;
export const BacktoHome = styled.div`
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 150%;
  /* identical to box height, or 27px */

  /* Font/header */

  color: #0f1f4a;
  :hover {
    cursor: pointer;
  }
  @media (max-width: 430px) {
    font-size: 16px;
    margin-bottom: 2rem;
  }
  @media (${MediaQuery.Ipad}) {
    margin-bottom: 2rem;
  }
`;
export const NetworkHead = styled.div`
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 39px;
  /* identical to box height */

  letter-spacing: -0.03em;

  /* Font/header */

  color: #0f1f4a;
  text-align: center;
  @media (max-width: 430px) {
    margin-top: 1rem;
  }
`;
interface isProps {
  isActive: boolean;
}
export const NetworkSkelton = styled(Skeleton)`
  width: 437px;
  height: 123px !important;
  border-radius: 20px;
  @media (max-width: 430px) {
    width: 100%;
    height: 100%;
  }
  @media (${MediaQuery.Ipad}) {
    width: 327px;
  }
`;
export const ConnectNetwrokItem = styled.div<isProps>`
width: 437px;
height: 123px;
left: 721px;
top: 236px;

/* BG/brand */

background: ${(props) => (props.isActive ? " #ECF8F0;" : "#F2FDFF;")}
/* Primary/default */

border: 1px solid #30BCC2;
border-radius: 20px;
display:flex;
align-items: center;
padding:16px;
 @media(max-width:430px){
   width:100%;
   height:100%;
  }
  @media(${MediaQuery.Ipad}){
    width:327px;
  }

`;
export const ConnectorWrapper = styled.div`
  margin-top: 3rem !important;
  display: flex;
  flex-flow: wrap;
  justify-content: center;
  width: 100%;

  margin: 0px auto;
  gap: 30px;
  @media (max-width: 430px) {
    width: 100%;
    margin-top: 2rem !important;
    margin-bottom: 2rem !important;
    gap: 20px;
  }
  @media (${MediaQuery.Ipad}) {
    width: 100%;
    justify-content: center;
    column-gap: 18px;
    gap: 20px;
  }
`;
export const ItemDiv = styled.div`
  display: flex;
  align-items: start;
  column-gap: 10px;
  justify-content: space-between;
  width: 100%;
  @media (max-width: 430px) {
  }
`;
export const TitleDivWrapper = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 16px;
  @media (max-width: 430px) {
    row-gap: 9px;
  }
`;
export const ConnectTitle = styled.div`
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 150%;
  /* identical to box height, or 27px */

  /* Font/header */

  color: #0f1f4a;
  @media (max-width: 430px) {
    font-size: 16px;
  }
  @media (${MediaQuery.Ipad}) {
    font-size: 16px;
  }
`;
export const ConnetorContent = styled.div`
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;
  /* identical to box height, or 24px */

  /* Font/header */

  color: #0f1f4a;
  @media (max-width: 430px) {
    font-size: 12px;
  }
  @media (${MediaQuery.Ipad}) {
    font-size: 11px;
    font-weight: 500;
  }
`;
export const ActiveButton = styled(Button)`
  width: 138px !important;
  height: 48px !important;

  /* Primary/Hover */

  background: #1f9ea4 !important;
  /* Primary/400 */

  border: 1px solid #60a499 !important;
  box-shadow: 3px 1px 18px rgba(48, 188, 194, 0.47) !important;
  border-radius: 16px !important;
  font-family: "Montserrat" !important;
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 12px !important;
  line-height: 150% !important;
  /* identical to box height, or 18px */

  display: flex !important;
  align-items: center !important;

  /* BG/white */

  color: #ffffff !important;
  margin-top: -10px !important;
  text-transform: capitalize !important;
  @media (max-width: 430px) {
    width: 118px !important;
    height: 38px !important;
    margin-top: 0px !important;
  }
`;
export const ConnectImageDiv = styled.div`
  display: flex;
  align-items: start;
  column-gap: 10px;
`;
export const CheckText = styled.div`
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 150%;
  /* identical to box height, or 24px */

  /* Success/Active */

  color: #44a55a;
  display: flex;
  align-items: center;
  column-gap: 10px;
  @media (max-width: 430px) {
    font-size: 12px;
  }
`;
export const Element1Wrapp = styled.div`
  position: absolute;

  right: 0px;
  top: -33px;
  z-index: -11;
  @media (max-width: 430px) {
    display: none;
  }
  @media (${MediaQuery.Ipad}) {
    display: none;
  }
`;
export const ElementImage1 = styled.img`
  height: 495.9px;
`;

export const Element2Wrapp = styled.div`
  position: absolute;
  left: -185px;
  top: 407px;
  z-index: -11;
  @media (max-width: 430px) {
    display: none;
  }
  @media (${MediaQuery.Ipad}) {
    display: none;
  }
`;
export const ElementImage2 = styled.img`
  width: 897px;
  height: 495.9px;
`;

export const RoleButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;

  width: 50px;
  // height: 48px;

  /* Primary/default */
  color: #fff;
  cursor: pointer;

  background: #30bcc2;
  box-shadow: 3px 1px 18px rgba(48, 188, 194, 0.47);
  border-radius: 16px;
`;

export const ConfirmButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 7px;

  color: #30bcc2;

  cursor: pointer;
  width: 120px;
  // height: 48px;

  /* BG/white */

  background: #ffffff;
  /* Primary/default */

  border: 1px solid #30bcc2;
  border-radius: 16px;
  :hover {
    background: #30bcc2 !important;

    color: white !important;
  }

  @media (max-width: 430px) {
    padding: 6px 12px;
    width: 100px;
  }
`;
export const SubscribeListButton = styled(Button)`
  width: 100% !important;
  height: 74px !important;
  margin-top: 1rem !important;
  /* BG/white */

  background: #ffffff !important;
  /* Primary/default */

  border: 1px solid #30bcc2 !important;
  border-radius: 25px !important;

  font-family: "Montserrat" !important;
  font-style: normal !important;
  font-weight: 600 !important;
  font-size: 16px !important;
  line-height: 150% !important;
  /* identical to box height, or 24px */
  text-transform: capitalize !important;

  display: flex !important;
  align-items: center !important;

  /* Primary/default */
  text-transform: capitalize !important;

  color: #30bcc2 !important;
`;
export const GlobalHead = styled.div`
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 150%;
  /* identical to box height, or 27px */

  display: flex;
  align-items: center;
  text-align: center;

  /* Font/header */

  color: #0f1f4a;
`;
export const GlobalHeadWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-bottom: 1rem;
  margin-top: 1rem;
`;
export const TextStyle = styled(ListItemText)`
  & .MuiTypography-root {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 150%;
    /* or 24px */

    display: flex;
    align-items: center;

    /* Font/primary */

    color: #231f20;
  }
`;
export const TextStyle2 = styled(ListItemText)`
  & .MuiTypography-root {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 150%;
    /* identical to box height, or 24px */

    display: flex;
    align-items: center;

    color: #000000;
  }
`;
export const AddButton = styled(Button)`
  width: 204px !important;
  height: 74px !important;

  /* Primary/default */

  background: #30bcc2 !important;
  box-shadow: 3px 1px 18px rgba(48, 188, 194, 0.47) !important;
  border-radius: 20px !important;
  font-family: "Montserrat" !important;
  font-style: normal !important;
  font-weight: 600 !important;
  font-size: 16px !important;
  line-height: 150% !important;
  /* identical to box height, or 24px */

  display: flex !important;
  align-items: center !important;

  /* BG/white */

  color: #ffffff !important;
  text-transform: capitalize !important;
`;
export const ButtonAddWrapper = styled.div`
  display: flex;
  justify-content: end;
  width: 100%;
  margin-top: 0.8rem;
`;
export const CustomeGloabalTextField = styled(TextField)`
  background: #ffffff;
  /* Font/tertiary */

  border: 1px solid #9fa0a3;
  border-radius: 25px;
  margin-bottom: 1rem !important;
  & .MuiOutlinedInput-root {
    border-radius: 15px;
  }
  & .MuiOutlinedInput-notchedOutline {
    border-color: #231f20 !important;
  }
  &
    .css-9ddj71-MuiInputBase-root-MuiOutlinedInput-root.Mui-error
    .MuiOutlinedInput-notchedOutline {
    border-color: red !important;
  }
  &
    .css-dpjnhs-MuiInputBase-root-MuiOutlinedInput-root.Mui-error
    .MuiOutlinedInput-notchedOutline {
    border-color: red !important;
  }
`;
export const TextLable = styled.div`
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 150%;
  /* identical to box height, or 24px */

  display: flex;
  align-items: center;
  text-align: center;

  /* Font/header */

  color: #0f1f4a;
`;

export const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  row-gap: 5px;
`;
export const EditCustomeList = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  margin-top: 1.5rem;
`;
export const CustomeTitlewrapper = styled.div`
  display: flex;
  align-items: center;
  column-gap: 10px;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 150%;
  /* identical to box height, or 24px */

  display: flex;
  align-items: center;

  color: #000000;
`;
export const CreateMoreButton = styled(Button)`
  width: 124px !important;
  height: 48px !important;

  border: 1px solid #30bcc2 !important;
  border-radius: 16px !important;
  border-radius: 16px !important;
  font-family: "Montserrat" !important;
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 12px !important;
  line-height: 150% !important;
  /* identical to box height, or 18px */

  display: flex !important;
  align-items: center !important;

  /* Primary/default */
  text-transform: capitalize !important;
  color: #30bcc2 !important;
`;
export const MoveText = styled.div`
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  width: 100%;
  text-align: left;
  font-size: 18px;
  line-height: 150%;
  /* identical to box height, or 27px */
  margin-bottom: 1rem;
  display: flex;
  align-items: center;
  text-align: center;

  /* Font/header */

  color: #0f1f4a;
`;
export const BackMove = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  column-gap: 8px;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 150%;
  margin-bottom: 1.5rem;
  /* identical to box height, or 24px */

  /* Font/header */

  color: #0f1f4a;
`;
interface ribbonProps {
  BgColor: string;
}
export const RibbonStyle = styled.div<ribbonProps>`
  background: ${(props) => props.BgColor};
  position: ABSOLUTE;
  right: -17px;
  top: 5px;
  padding: 10px 20px 10px 20px;
  box-shadow: 8px 6px 10px #e7e7e7;
  width: 34%;

  color: white;
  text-align: 106px;

  font-size: 14px;
  height: 28px;
  display: flex;
  align-items: center;
  border-radius: 1px;

  &:after {
    content: " ";
    display: block;
    width: 0;
    height: 0;
    position: absolute;
    top: -10px;
    right: 0;
    border: solid 10px transparent;
    border-top-width: 5px;
    border-bottom-width: 5px;
    border-bottom-color: ${(props) => props.BgColor};
    border-left-color: ${(props) => props.BgColor};
  }
`;
export const CustomizeAccordion = styled(Accordion)`
  padding: 20px 25px;
  gap: 16px;
  width: 339px;

  background: #ffffff;
  border: 1px solid #9fa0a3;
  border-radius: 15px !important;
  box-shadow: none !important;
  @media (max-width: 430px) {
    width: 100%;
  }
  :before {
    display: none !important;
  }
  & .MuiAccordionSummary-content {
    margin: 0 !important;
  }
  & .MuiAccordionSummary-root {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0px;
    gap: 16px;
    width: 100%;
    height: auto;
  }
  & .MuiAccordionDetails-root {
    padding: 0 !important;
    padding-top: 1rem !important;
  }
`;
export const ContainerImage = styled.div`
  width: 50px;
  height: 50px;

  /* BG/white */

  background: #ffffff;
  /* Font/tertiary */

  border: 1px solid #9fa0a3;
  border-radius: 48px;
  & > img {
    border-radius: 48px;
  }
`;
export const ConentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 8px;

  width: auto;
  height: 56px;
`;
export const WrapHeader = styled.div`
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 150%;
  /* identical to box height, or 27px */

  display: flex;
  align-items: center;

  /* Font/primary */

  color: #231f20;
`;
export const WrapText = styled.div`
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 150%;
  /* identical to box height, or 21px */

  display: flex;
  align-items: center;

  /* Font/primary */

  color: #231f20;
`;
export const InWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
`;
export const DataButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
  border-bottom: 1.8px solid #9fa0a3;
  padding-bottom: 1rem;
`;
export const MintButton = styled(Button)`
  width: 158px !important;
  height: 48px !important;

  /* Primary/default */

  background: #30bcc2 !important;
  /* Primary/default */

  border: 1px solid #30bcc2 !important;
  border-radius: 16px !important;
  ont-family: "Montserrat" !important;
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 12px !important;
  line-height: 150% !important;
  /* identical to box height, or 18px */

  display: flex !important;
  align-items: center !important;

  /* BG/white */

  color: #ffffff !important;
  text-transform: capitalize !important;
`;
interface isPropsShareButton {
  isFullWidth: boolean;
}
export const ShareDomain = styled(Button)<isPropsShareButton>`
  width:${(props) =>
    props.isFullWidth ? "100% !important;" : "158px !important;"} 
  height: 48px !important;

  /* BG/white */

  background: #ffffff !important;
  /* Primary/default */

  border: 1px solid #30bcc2 !important;
  border-radius: 16px !important;
  font-family: "Montserrat" !important;
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 12px !important;
  line-height: 150% !important;
  /* identical to box height, or 18px */

  display: flex !important;
  align-items: center !important;

  /* Primary/default */

  color: #30bcc2 !important;
  text-transform: capitalize !important;
`;
export const AddServerText = styled.div`
  /* desktop/paragraph bold */

  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 150%;
  /* identical to box height, or 24px */

  display: flex;
  align-items: center;

  color: #000000;
  justify-content: center;
  padding-top: 1rem;
  :hover {
    cursor: pointer;
  }
`;
export const ServerButtonWrap = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  gap: 10px;

  width: 100%;
  height: 99px;
`;
export const CancelButton2 = styled(Button)`
  width: 116px !important;
  height: 74px !important;

  /* BG/white */

  background: #ffffff !important;
  /* Primary/default */

  border: 1px solid #60a499 !important;
  border-radius: 25px !important;
  font-family: "Montserrat" !important;
  font-style: normal !important;
  font-weight: 600 !important;
  font-size: 16px !important;
  line-height: 150% !important;
  /* identical to box height, or 24px */

  display: flex !important;
  align-items: center !important;

  /* Primary/default */
  color: #30bcc2 !important;
  text-transform: capitalize !important;
  @media (max-width: 430px) {
    width: 91px !important;
    height: 48px !important;
    border-radius: 16px !important;
    font-size: 12px !important;
  }
`;
export const SaveButton = styled(Button)`
  width: 116px !important;
  height: 74px !important;

  /* Primary/Hover */

  background: #1f9ea4 !important;
  /* Primary/400 */

  border: 1px solid #60a499 !important;
  box-shadow: 3px 1px 18px rgba(48, 188, 194, 0.47) !important;
  border-radius: 20px !important;
  font-family: "Montserrat" !important;
  font-style: normal !important;
  font-weight: 600 !important;
  font-size: 16px !important;
  line-height: 150% !important;
  /* identical to box height, or 24px */

  display: flex !important;
  align-items: center !important;

  /* BG/white */

  color: #ffffff !important;
  text-transform: capitalize !important;
  @media (max-width: 430px) {
    width: 91px !important;
    height: 48px !important;
    border-radius: 16px !important;
    font-size: 12px !important;
  }
`;
export const SaveDisableButton = styled(Button)`
  width: 116px !important;
  height: 74px !important;

  /* Font/tertiary */

  background: #9fa0a3 !important;
  box-shadow: inset 3px 1px 18px rgba(42, 48, 55, 0.19) !important;
  border-radius: 20px !important;
  font-family: "Montserrat" !important;
  font-style: normal !important;
  font-weight: 600 !important;
  font-size: 16px !important;
  line-height: 150% !important;
  /* identical to box height, or 24px */

  display: flex !important;
  align-items: center !important;

  /* BG/white */

  color: #ffffff !important;
  text-transform: capitalize !important;
  @media (max-width: 430px) {
    width: 91px !important;
    height: 48px !important;
    border-radius: 16px !important;
    font-size: 12px !important;
  }
`;
export const ModalContent = styled.div`
  padding: 20px;
  padding: 20px;
  width: 548px;
  display: flex;
  flex-direction: column;
  gap: 55px;
  padding-top: 0;
  padding-bottom: 0;
  @media (max-width: 430px) {
    width: 260px;
    padding: 0;
    gap: 10px;
  }
`;
export const TextfieldWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 30px;
`;
export const MintModal = styled.div`
  padding: 20px;
  padding: 20px;
  width: 450px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding-top: 0;
  padding-bottom: 0;
  @media (max-width: 430px) {
    width: 100%;
    padding: 0px;
  }
`;
export const MintContent = styled.div`
  background: #f2fdff;
  border-radius: 15px;
  width: 100%;
  & > ul {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 150%;
    /* identical to box height, or 24px */

    color: #000000;
    @media (max-width: 430px) {
      font-size: 14px;
    }
    & > li {
      :marker {
        font-size: 5px !important;
      }
    }
  }
`;
export const ShareWrapper = styled.div`
  display: flex;
  flex-direction: column;

  align-items: flex-start;
  padding: 8px 24px;
  width: 528px;

  padding-top: 0;
  padding-bottom: 0;
  /* align-items: self-end; */
  justify-content: start;
  gap: 19px;
  height: auto;
  @media (max-width: 430px) {
    width: 100%;
    padding: 0;
  }
`;
export const ShareText = styled.div`
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;
  /* or 24px */

  display: flex;
  align-items: center;

  /* Font/header */

  color: #0f1f4a;
  @media (max-width: 430px) {
    font-size: 15px;
  }
`;
export const ShareName = styled.div`
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 150%;
  /* identical to box height, or 24px */

  text-decoration-line: underline;

  /* Font/header */

  color: #0f1f4a;
`;
export const ShareIcons = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 8px;
  gap: 16px;
  @media (max-width: 430px) {
    gap: 13px;
  }
`;
export const ShareContiner = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 16px;
  gap: 8px;

  width: 56px;
  height: 56px;
  @media (max-width: 430px) {
    width: 40px;
    height: 40px;
    padding: 8px;
  }
  :hover {
    background: #a0e5e8;
    /* Font/Secondary */

    border: 1px solid #dcdede;
    /* shadow */

    box-shadow: 6px 6px 14px rgba(189, 195, 199, 0.18);
    border-radius: 15px;
    cursor: pointer;
  }
`;

export const ShareSoulDomain = styled.a`
  text-decoration: none;
  font-family: "Montserrat" !important;
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 12px !important;
  line-height: 150% !important;
  color: #30bcc2 !important;
`;
