import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider/Divider";
import Drawer from "@mui/material/Drawer";
import styled from "styled-components";

import TwitterLogin from "react-twitter-auth";
interface isprops {
  isSelected: boolean;
}
export const GoogleTitle = styled.div`
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;
  color: #231f20;
  position: absolute;
  left: 17.6%;
`;
export const ArrowWrap = styled.div`
  position: absolute;
  right: 0%;
`;
export const GoogleAuthWrap = styled.div<isprops>`
  width: 370px;
  height: 70px;
  font-family: "Montserrat";
  justify-content: space-between;
  /* BG/white */
  position: relative;
  /* Font/Secondary */

  border-radius: 25px;
  /* BG/white */

  background: ${(props) => (props.isSelected ? "#f2fdff" : "#ffffff")};
  /* Font/Secondary */

  border: ${(props) =>
    props.isSelected ? "1px solid #30bcc2" : "1px solid #dcdede"};

  display: flex;
  align-items: center;
  column-gap: 15px;
  :hover {
    cursor: pointer;
    background: #f2fdff;
    /* Primary/default */

    border: 1px solid #30bcc2;
    /* shadow */

    box-shadow: 6px 6px 14px rgba(189, 195, 199, 0.18);
  }
  @media (max-width: 430px) {
    height: 83px !important;
    width: 100% !important;
  }
  & > button {
    opacity: 1 !important;
    width: 100% !important;
    height: 100% !important;
    border-radius: 24px !important;
    box-shadow: none !important;
    & > div {
      transform: scale(1.5) !important;
      position: relative !important;
      margin-left: 25px !important;
      margin-right: 0px !important;
      background: transparent !important;
      padding: 0px !important;
    }
    :hover {
      cursor: pointer;
      background: #f2fdff !important;
    }
  }
`;

export const TwitterButton = styled(TwitterLogin)<isprops>`
  width: 370px;
  height: 70px;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;
  color: #231f20;

  border-radius: 25px;
  /* BG/white */

  background: ${(props) => (props.isSelected ? "#f2fdff" : "#ffffff")};
  /* Font/Secondary */

  border: ${(props) =>
    props.isSelected ? "1px solid #30bcc2" : "1px solid #dcdede"};

  display: flex;
  justify-content: space-between;
  align-items: center;
  column-gap: 15px;
  :hover {
    cursor: pointer;
    background: #f2fdff;
    /* Primary/default */

    border: 1px solid #30bcc2 !important;
    /* shadow */

    box-shadow: 6px 6px 14px rgba(189, 195, 199, 0.18);
  }
  @media (max-width: 430px) {
    height: 83px !important;
    width: 100% !important;
  }
`;
export const ItemWrapper = styled.div<isprops>`
  width: 370px;
  height: 70px;
  font-family: "Montserrat";
  justify-content: space-between;
  /* BG/white */

  /* Font/Secondary */

  border-radius: 25px;
  /* BG/white */

  background: ${(props) => (props.isSelected ? "#f2fdff" : "#ffffff")};
  /* Font/Secondary */

  border: ${(props) =>
    props.isSelected ? "1px solid #30bcc2" : "1px solid #dcdede"};

  display: flex;
  align-items: center;
  column-gap: 15px;
  :hover {
    cursor: pointer;
    background: #f2fdff;
    /* Primary/default */

    border: 1px solid #30bcc2;
    /* shadow */

    box-shadow: 6px 6px 14px rgba(189, 195, 199, 0.18);
  }
  @media (max-width: 430px) {
    height: 83px !important;
    width: 100% !important;
  }
`;
export const ContentWrapper = styled.div`
  display: flex;
  flex-flow: wrap;
  column-gap: 11px;
  row-gap: 11px;
  width: 400px;
  font-family: "Montserrat";
  flex-direction: column;
  align-items: center;
  @media (max-width: 430px) {
    margin-top: 15px !important;
    width: 100% !important;
  }
`;
export const WallteTitle = styled.div`
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;
  color: #231f20;

  @media (max-width: 768px) {
    font-size: 14px;
  }
`;
export const PassCodeWrap = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  row-gap: 30px;
  width: 700px;
  @media (max-width: 430px) {
    width: 100%;
  }
`;
export const ContentWrap = styled.div`
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;
  /* or 24px */

  text-align: center;

  color: #323232;
`;
export const PassCodeButton = styled(Button)`
  width: 220px !important;
  height: 55px !important;

  /* Primary/default */

  background: #30bcc2 !important;
  box-shadow: 3px 1px 18px rgba(48, 188, 194, 0.47) !important;
  border-radius: 20px !important;
  font-family: "Montserrat" !important;
  font-style: normal !important;
  font-weight: 600 !important;
  font-size: 14px !important;
  color: #ffffff !important;
`;
export const UserWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 25px;
  width: 550px;
  & input {
    margin-top: 0px !important;
  }

  @media (max-width: 430px) {
    width: 100%;
    margin-top: 2rem;
    height: 415px;
    row-gap: 20px;
    overflow: hidden;

    & img {
      width: 160px;
    }
  }
`;
export const UserContent = styled.div`
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  text-align: center;

  color: #323232;
  @media (max-width: 430px) {
    font-size: 14px;
  }
`;
export const LoginButton = styled(Button)`
  width: 107px !important;
  height: 64px !important;

  /* Primary/default */

  background: #30bcc2 !important;
  box-shadow: 3px 1px 18px rgba(48, 188, 194, 0.47) !important;
  border-radius: 20px !important;
  color: white !important;
  @media (max-width: 430px) {
    width: 125px !important;
    height: 50px !important;
  }
`;
export const DisableLoginButton = styled(Button)`
  width: 107px !important;
  height: 64px !important;

  /* Primary/default */

  background: #9fa0a3 !important;
  box-shadow: inset 3px 1px 18px rgba(42, 48, 55, 0.19) !important;
  border-radius: 20px !important;
  color: white !important;
  @media (max-width: 430px) {
    width: 125px !important;
    height: 50px !important;
  }
`;
export const InputWrapper = styled.div`
  display: flex;
  margin-top: 2%;
  align-items: center;
  @media (max-width: 430px) {
    justify-content: center;
  }
`;
export const ErrorDiv = styled.div`
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  color: #d91413;
  @media (max-width: 430px) {
    text-align: center;
  }
`;
export const DrawerRoot = styled.div`
  width: 460px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  ::-webkit-scrollbar {
    display: none !important;
  }
  @media (max-width: 430px) {
    width: 100%;
  }
`;
export const ProfileWrapper = styled.div`
  width: 400px;
  padding: 20px 25px;
  gap: 20px;

  /* BG/white */

  background: #ffffff;
  /* Font/subheader */
  flex-direction: column;
  border: 1px solid #dcdede;
  border-radius: 25px;
  display: flex;
  align-items: center;
  column-gap: 15px;
  @media (max-width: 430px) {
    width: 100%;
  }
`;
export const AddressWrap = styled.div`
  display: flex;
  align-items: center;
  column-gap: 15px;
  justify-content: start;
  width: 100%;
`;
export const DrwerTitle = styled.div`
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 150%;
  /* identical to box height, or 30px */

  display: flex;
  align-items: center;

  /* Font/primary */

  color: #231f20;
`;
interface isIndex {
  isview: boolean;
}
export const DrwerTitleWrapper = styled.div<isIndex>`
  display: flex;
  justify-content: space-between;
  width: 86%;
  align-items: center;
  padding-top: 1rem;
  padding-bottom: 1.5rem;
  z-index: ${(props) => (props.isview ? "-11;" : "11;")} @media
    (max-width: 430px) {
    width: 100%;
    z-index: ${(props) => (props.isview ? "-11;" : "11;")};
  }
`;
export const NameWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;
export const WallteAddress = styled.div`
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 150%;
  /* identical to box height, or 24px */

  display: flex;
  align-items: center;

  /* Font/subheader */

  color: #555555;
  @media (max-width: 430px) {
    font-size: 13px;
  }
`;
export const ProfileName = styled.div`
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;
  /* identical to box height, or 24px */

  display: flex;
  align-items: center;

  /* Font/subheader */

  color: #555555;
`;

export const TitleWrapper = styled.div`
  margin-top: 2rem;
  @media (max-width: 430px) {
    width: 100%;
  }
`;
export const TitleText = styled.div`
  ont-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 150%;
  /* identical to box height, or 24px */

  display: flex;
  align-items: center;

  /* Font/primary */

  color: #231f20;
  margin-bottom: 0.8rem;
`;
export const UfarmWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;
export const UfamrTitle = styled.div`
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 150%;
  /* identical to box height, or 16px */

  display: flex;
  align-items: center;

  /* Font/primary */

  color: #231f20;
`;
export const UfarmPrice = styled.div`
  /* desktop/paragraph bold */

  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;
  /* identical to box height, or 24px */

  display: flex;
  align-items: center;

  /* Font/primary */

  color: #231f20;
`;

export const SettingButton = styled(Button)`
  width: 185px !important;
  height: 74px !important;

  /* BG/white */

  background: #ffffff !important;
  /* Primary/default */

  border: 1px solid #30bcc2 !important;
  border-radius: 25px !important;
  color: #30bcc2 !important;
  font-family: "Montserrat" !important;
  font-style: normal !important;
  font-weight: 600 !important;
  font-size: 16px !important;
  text-transform: capitalize !important;
`;
interface isProps {
  isFullWidth: boolean;
}
export const LogoutButton = styled(Button)<isProps>`
  width: ${(props) =>
    props.isFullWidth ? "185px !important" : "100% !important"};
  height: 74px !important;

  /* BG/white */

  background: #ffffff !important;
  /* Font/subheader */

  border: 1px solid #555555 !important;
  border-radius: 25px !important;
  color: #555555 !important;
  font-family: "Montserrat" !important;
  font-style: normal !important;
  font-weight: 600 !important;
  font-size: 16px !important;
  text-transform: capitalize !important;
`;
export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  width: 85%;
  padding-top: 2rem;
  padding-bottom: 2rem;
  @media (max-width: 430px) {
    padding-top: 1rem;
    column-gap: 10px;
    width: 100%;
  }
`;

export const NetworkWraper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  row-gap: 25px;
  @media (msx-width: 430px) {
    row-gap: 10px;
  }
`;

export const NetworkContentWraper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  row-gap: 4px;
`;
export const NetworkSelectWrap = styled.div`
  display: flex;
  flex-flow: wrap;
  justify-content: center;
  row-gap: 20px;
  column-gap: 15px;
  @media (max-width: 430px) {
    row-gap: 6px;
  }
`;
export const NetworkSelect = styled.div<isprops>`
  width: 315px;
  height: 89px;
  background: ${(props) => (props.isSelected ? "#f2fdff" : "#ffffff")};
  /* Font/Secondary */

  border: ${(props) =>
    props.isSelected ? "1px solid #30bcc2" : "1px solid #dcdede"};
  border-radius: 25px;
  display: flex;
  align-items: center;
  column-gap: 19px;
  padding-left: 20px;
  :hover {
    cursor: pointer;
    background: #f2fdff;
    /* Primary/default */

    border: 1px solid #30bcc2;
    /* shadow */

    box-shadow: 6px 6px 14px rgba(189, 195, 199, 0.18);
  }
`;
export const ErrorTitle = styled.div`
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  color: #231f20;
`;
export const ErrorContent = styled.div`
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  color: #323232;
  text-align: center;
`;
export const NetworkButtonDisable = styled(Button)`
  width: 225px !important;
  height: 74px !important;
  background: #9fa0a3 !important;
  box-shadow: inset 3px 1px 18px rgba(42, 48, 55, 0.19) !important;
  border-radius: 20px !important;
`;

export const NetworkButton = styled(Button)`
  width: 225px !important;
  height: 74px !important;
  background: #30bcc2 !important;
  box-shadow: 3px 1px 18px rgba(48, 188, 194, 0.47) !important;
  border-radius: 20px !important;
  font-family: "Montserrat" !important;
  font-style: normal !important;
  font-weight: 600 !important;
  font-size: 16px !important;
  color: #ffffff !important;
  text-transform: capitalize !important;
`;
export const ConntectToDapps = styled(Button)`
  width: 400px !important;
  height: 74px !important;
  background: #30bcc2 !important;
  box-shadow: 3px 1px 18px rgba(48, 188, 194, 0.47) !important;
  border-radius: 20px !important;
  font-family: "Montserrat" !important;
  font-style: normal important;
  font-weight: 600 !important;
  font-size: 16px !important;
  color: #ffffff !important;
  margin-top: 2rem !important;
  text-transform: capitalize !important;
  @media (max-width: 430px) {
    width: 100% !important;
    margin-top: 1.5rem !important;
  }
`;
export const TitleSidebar = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  color: #231f20;
  text-align: left;
  width: 85%;
  margin-top: 25px;
  margin-bottom: 16px;
  @media (max-width: 430px) {
    width: 100%;
  }
`;

export const ContentSidebar = styled.div`
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;
  /* or 24px */

  display: flex;
  align-items: center;

  /* Font/subheader */

  color: #555555;
  text-align: left;
  width: 85%;
  @media (max-width: 430px) {
    width: 100%;
    font-size: 15px;
  }
`;
export const DrawerCustomRoot = styled(Drawer)`
  & .MuiPaper-root {
    background-color: white !important;
    height: 100% !important;
    overflow: auto !important;
    @media (max-width: 430px) {
      width: 100% !important;
      padding-left: 15px;
      padding-right: 15px;
      overflow: auto !important;
    }
  }
  & .MuiPaper-root::-webkit-scrollbar {
    display: none !important;
  }
`;
export const ErrorDrawer = styled.div`
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 150%;
  /* identical to box height, or 24px */
  display: flex;
  align-items: center;
  column-gap: 10px;
  margin-top: 0.7rem;

  /* Warning/Active */

  color: #ff9039;
`;
export const LoaderWrapper = styled.div`
  width: 500px;
  height: 100%;
  flex-direction: column;
  justify-content: center;
  left: c;
  align-items: center;
  display: flex;
  justify-content: center;
  align-items: center;
  @media (max-width: 430px) {
    width: 100% !important;
  }
`;
export const ConnectDevider = styled(Divider)`
width: 98%;
:before{
  border-top:1px solid #555555; !important;
}
:after{
  border-top:1px solid #555555; !important;
}
`;
export const TransectionHashWrap = styled.div`
  width: 100%;
  height: 44px;

  /* Font/header */

  border: 1px solid #0f1f4a;
  border-radius: 15px;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 150%;
  /* identical to box height, or 24px */

  display: flex;
  align-items: center;
  justify-content: center;
  /* Font/header */

  color: #0f1f4a;
  :hover {
    cursor: pointer;
  }
`;
