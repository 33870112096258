import React from "react";
import { Button, Menu } from "@mui/material";
import Drawer from "@mui/material/Drawer";
import InputBase from "@mui/material/InputBase/InputBase";
import { RadioGroup } from "@mui/material";
import TextField from "@mui/material/TextField";
import { alpha, styled } from "@mui/material/styles";
import LinearProgress from "@mui/material/LinearProgress";
import { makeStyles } from "@mui/styles";
import { MediaQuery } from "const/MediaQuery";
import Styled from "styled-components";
import Tooltip, { TooltipProps, tooltipClasses } from "@mui/material/Tooltip";
import MobileStepper from "@mui/material/MobileStepper";
import Tippy from "@tippyjs/react";
import "tippy.js/dist/tippy.css";

export const HelloBarText = Styled.p`
  font-family: "Montserrat";
  margin:0;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 150%;
  /* identical to box height, or 24px */

  display: flex;
  align-items: center;

  /* Font/header */

  color: #0f1f4a;

  @media(max-width:430px){
    flex-direction:column;
    justify-content:center;
    width:100%
   }

  
`;

export const useStyles = makeStyles(() => ({
  iconeButonStyle: {
    padding: 7,
    "&:hover": {
      backgroundColor: "#30BCC2 !important",
      "&>svg g path": {
        fill: "white !important",
      },
    },
  },
  iconeButonStyle1: {
    padding: 7,
    marginRight: "0.5rem !important",
    width: "38px",
    height: "38px",
    "&:hover": {
      backgroundColor: "#30BCC2 !important",
      "&>.MuiBadge-root svg g path": {
        fill: "white !important",
      },
    },
  },
  AppRoot: {
    background: "white !important",
    boxShadow: "0px 1px 0px rgb(0 0 0 / 9%) !important",
    height: 82,
    marginBottom: "1rem",
  },
  AppRootauth: {
    background: "white !important",
    boxShadow: "0px 1px 0px rgb(0 0 0 / 9%) !important",
    height: 230,
  },
  AppHeader: {
    minHeight: "78px !important",
    paddingLeft: "40px !important",
    paddingRight: "17px !important",
    ["@media(max-width:430px)"]: {
      paddingLeft: "11px !important",
      paddingRight: "11px !important",
    },
  },
}));
export const ConnectedButton = Styled(Button)`
width: 200px ;
height: 50px;
background: white !important;

border: 1px solid #30BCC2 !important;
border-radius: 17px !important;
color: #30BCC2 !important;
text-transform: lowercase !important;
font-size: 14px !important;
font-family: 'Montserrat' !important;
display: flex !important;
align-items: center !important;
column-gap: 8px !important;
font-weight: 600 !important;
@media(max-width:430px){
  width:150px !important;
  font-size:12px !important;

}
`;
export const WrongNetwork = Styled(Button)`
width: 235px ;
height: 50px;
background: white !important;

border: 1px solid #FF9039 !important;
border-radius: 17px !important;
color: #FF9039 !important;
text-transform: capitalize !important;
font-size: 14px !important;
font-family: 'Montserrat' !important;
display: flex !important;
align-items: center !important;
column-gap: 8px !important;
font-weight: 600 !important;
background:#FEF5EB !important;
`;
export const StyledButton = Styled(Button)<{
  account: string;
  active: boolean;
}>`
    width: 200px ;
    height: 50px;
    background: #30BCC2 !important;
    box-shadow: 3px 1px 18px rgba(48, 188, 194, 0.47) !important;
    border: 1px solid #30BCC2 !important;
    border-radius: 17px !important;
    color: white !important;
    text-transform: capitalize !important;
    font-size: 14px !important;
    font-family: 'Montserrat' !important;
    display: flex !important;
    align-items: center !important;
    column-gap: 8px !important;
    font-weight: 600 !important;
    @media(max-width:430px){
      width: 146px !important;
height: 38px !important;
background: #30BCC2 !important;
box-shadow: 3px 1px 18px rgba(48, 188, 194, 0.47) !important;
border-radius: 14px !important;
font-family: 'Montserrat' !important;
font-style: normal !important;
font-weight: 400 !important;
font-size: 12px !important;
color: #FFFFFF !important;
    }
`;

export const GrantButton = Styled.div`
display: flex;
flex-direction: row;
justify-content: center;
align-items: center;
padding: 15px 7px;
color:#30BCC2;
cursor:pointer;
font-family: 'Montserrat';
font-style: normal;
font-weight: 600;
font-size: 16px;

width: 140px;
// height: 74px;

/* BG/white */

background: #FFFFFF;
/* Primary/default */

border: 1px solid #30BCC2;
border-radius: 17px;
&:hover {
  background: #30bcc2 !important;
  color: #ffffff !important;
 
}


`;
export const DisableRemoveButton = Styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 15px 7px;


  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;

  background: #9fa0a3;
  color: #dcdede;
  border: none;

  width: 198px;

  border-radius: 17px;
`;
export const DesktopWrapper = Styled.div`
 display:flex;
 justify-content:space-between;
 align-items:center;
 width:100%;
 @media(max-width:590px){
  display: none;

 }
 @media(min-width: 590px) and (max-width: 730px){
  display: none;
 }
 @media(${MediaQuery.miniDesktop}){
  display: none;
 }
`;
export const DesktopWrapper2 = Styled.div`
 
 display: none;
 @media(max-width:590px){
  display: none;

 }
 @media(min-width: 590px) and (max-width: 730px){
  display: none;
 }
 @media(${MediaQuery.miniDesktop}){
  display:flex;
  justify-content:space-between;
  align-items:center;
  width:100%;
 }

`;
export const Search = styled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: 12,
  border: "1px solid #9FA0A3",

  backgroundColor: alpha(theme.palette.common.white, 0.15),
  "&:hover": {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginRight: theme.spacing(3),
  marginLeft: theme.spacing(3),

  [theme.breakpoints.down("sm")]: {
    marginLeft: theme.spacing(3),
    width: "auto",
  },
}));

export const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

export const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create("width"),
    width: "100%",
    color: "black",
    [theme.breakpoints.up("md")]: {
      width: "32ch",
      fontFamily: "Montserrat !important",
      height: "2.8ch",
    },
  },
}));

export const MobileWrapper = Styled.div`

 display:none;
 @media(max-width:590px){
 display:flex;
 justify-content:space-between;
 align-items:center;
 width:100%;
 }
  @media(min-width: 590px) and (max-width: 730px){
    display:none;
  }
  @media(${MediaQuery.miniDesktop}){
 display:none;
 }
`;
export const IpadWrapper = Styled.div`

 display:none;
 @media(max-width:590px){
  display:none;
 }
  @media(min-width: 590px) and (max-width: 730px){
    display:flex;
    justify-content:space-between;
    align-items:center;
    width:100%;
 }
 @media(${MediaQuery.miniDesktop}){
 display:none;
 }
`;
export const LogoWrapper = Styled.div`
display:flex;
align-items: center;
`;

export const CustomDrawer = Styled(Drawer)`
& .MuiPaper-root{
  width: 310px;
  height: 100% !important;
  left: 0px;
  top: 0px;
  background-color: #FFFFFF !important;
  box-shadow: 4px 4px 25px #e0e0e0;
}
`;
export const DrawerWrapper = Styled.div`
padding:20px;
`;
export const HeaderWrapper = Styled.div`
display:flex;
justify-content: space-between;
width: 100%;
align-items: center;
`;
export const ContentWraper = Styled.div`
margin-top:1rem;

`;
export const FooterDiv = Styled.div`
position: absolute;
bottom: 10px;
`;
export const SearchWrapper = Styled.div`
margin-left:1rem;
 & .MuiOutlinedInput-root{
  border: unset;
border-radius: 20px;
 }
 & .MuiTextField-root{
  border: unset !important;
  border-radius: 20px;
 }
 & .MuiSvgIcon-root{
  display:none;
 }
 & .MuiOutlinedInput-notchedOutline {
  border-color: #9FA0A3 !important;
}
 
`;
export const OptionWrapper = Styled.div`
display: flex;
align-items: center;
column-gap:10px;
height:56px;
font-family: 'Montserrat';
font-style: normal;
font-weight: 600;
font-size: 16px;
line-height: 19px;
padding-left:1rem;


/* Text/Primary */

color: rgba(0, 0, 0, 0.87);
:hover{
  background: rgba(160, 229, 232, 0.1);
  cursor:pointer;
}
::-webkit-scrollbar {
  width: 1em !important;
}
 
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3) !important;
}
 
::-webkit-scrollbar-thumb {
  background-color: darkgrey !important;
  outline: 1px solid slategrey !important;
}
`;
export const TrandingText = Styled.div`
height: 40px;
font-style: normal;
font-weight: 700;
font-size: 20px;
line-height: 24px;
padding-left:1rem;
display:flex;
align-items: center;
/* Text/Primary */

color: rgba(0, 0, 0, 0.87);

`;
export const CustomeTextFiled = styled(TextField)`
  border: unset;
  border-radius: 20px;
  & .MuiOutlinedInput-notchedOutline {
    border-color: #9fa0a3 !important;
  }
  & input {
    height: 18px !important;
  }
`;
export const SkeltonWrapper = Styled.div`
display: flex;
flex-direction: column;
row-gap:1rem;
padding-left:0.1rem;
`;
export const SkeltonText = Styled.div`
font-style: normal;
font-weight: 700;
font-size: 20px;
line-height: 24px;
height: 28px;
display:flex;
align-items: center;
color: rgba(0, 0, 0, 0.87);
margin-bottom:1rem;
`;

export const Wrapper = Styled.div`
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
}

`;

export const InputWrapper = Styled.div`
display: flex;
justify-content: center;
align-items: center;
`;

export const KarmaPointText = Styled.div`
font-family: 'Montserrat';
font-style: normal;
font-weight: 600;
font-size: 16px;
line-height: 150%;
/* identical to box height, or 24px */

display: flex;
align-items: center;

color: #000000;
margin-top:-0.5rem;
`;
export const KarmaWrapper = Styled.div`
display: flex;
flex-direction: column;
align-items: center;
justify-content: center;
width:450px;
margin-left:1rem;
margin-right:1rem;
    width: 270px;
    text-align: center;
    margin-top:0.9rem;
`;
export const KarmaDescription = Styled.div`
font-family: 'Montserrat';
font-style: normal;
font-weight: 400;
font-size: 16px;
line-height: 150%;
`;

export const MaainWrapper = Styled(Menu)`
& .MuiPaper-elevation{
border: 1px solid #30BCC2;
border-radius: 11px;
width:308px;
}

`;
export const PendingMaainWrapper = Styled(Menu)`
& .MuiPaper-elevation{
border: 1px solid #30BCC2;
border-radius: 11px;
width:450px;
}

`;
export const CustomeSliderHnadle = Styled(MobileStepper)`
border: unset !important;
& .MuiMobileStepper-dots{
  position: absolute;
  top: 9px;
  align-items: center;
  display: flex;
  justify-content: center;
  width: 88%;
}
& .css-26w9jf-MuiMobileStepper-dot{
  background-color:#30BCC2;
}
`;
export const CustomeButton = Styled(Button)`
width: 252px !important;
height: 48px !important;

/* Primary/default */

background: #30BCC2 !important;
box-shadow: 3px 1px 18px rgba(48, 188, 194, 0.47) !important; 
border-radius: 16px !important;

font-family: 'Montserrat' !important;
font-style: normal !important;
font-weight: 400 !important;
font-size: 12px !important;
line-height: 150% !important;
/* identical to box height, or 18px */

display: flex !important;
align-items: center !important;

/* BG/white */

color: #FFFFFF !important;
margin: 0px auto !important;
`;

export const ArrowWrapper = Styled.div`
display: flex;
    justify-content: space-between;
    /* margin-top: -50px; */
    position: absolute;
    width: 100%;
    top: 126px;
`;

export const PendingCustomizeProgress = styled(LinearProgress)`
height: 25px !important;
background-color: #30BCC2 !important;
border-radius: 0px !important;
& .MuiLinearProgress-bar1Indeterminate{
  background-color: #A0E5E8 !important;
    border-radius: 54PX !important;
}
& .MuiLinearProgress-bar2Indeterminate{
  background-color: #A0E5E8 !important;
    border-radius: 54PX !important;
}
}`;

export const CustomizedRadio = styled(RadioGroup)`
  & .Mui-checked {
    color: #30bcc2 !important;
  }
`;

export const PendingListWrapper = Styled.div`
display:flex;
justify-content:space-between;
padding:10px;
width:100%;

`;

export const PendingListMessage = Styled.p`
  margin:0;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 150%;
  /* identical to box height, or 24px */
  
  
  /* Font/primary */
  
  color: #231F20;
    

`;

export const CustomWidthTooltip = styled(
  ({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} />
  )
)({
  [`& .${tooltipClasses.tooltip}`]: {
    maxWidth: 500,
  },
});
interface prProps {
  isMargin: boolean;
}
export const ProgressWrapper = Styled.div<prProps>`
color: red;
  // position: fixed;
 
  width: 100%;
  z-index: 111;
  height: 25px;
  margin-top:${(props) => (props.isMargin ? "10px;" : "-2px;")}
`;
export const TippyStyle = styled(Tippy)`
  background: white;
  width: 600px;
  border: 1px solid #30bcc2;
  border-radius: 15px;
`;
interface isPropsHello {
  isMargin: boolean;
}
export const HelloBar = Styled.div`
font-family: 'Montserrat';
font-style: normal;
font-weight: 500;
font-size: 16px;
line-height: 150%;
/* identical to box height, or 24px */

display: flex;
align-items: center;
// text-decoration-line: underline;

/* Font/header */

color: #0F1F4A;
display: flex;
flex-direction: row;
justify-content: center;
align-items: flex-start;
padding: 6px 115px;
gap: 10px;
background: #A0E5E8;
// position: fixed;
width: 100%;
margin-top:16px;
z-index:1111;
:hover{
  cursor:pointer !important;
}
@media(max-width:430px){
 font-size: 12px;
    padding: 9px;
}

`;
