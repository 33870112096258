import {
  useSetOneToOneMessage,
  useSetSpamProtection,
  useSetDapps,
} from "store/hooks";

function QueryResolvers() {
  useSetOneToOneMessage();
  useSetSpamProtection();
  useSetDapps();
  return null;
}

export default QueryResolvers;
