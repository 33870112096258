import React from "react";
import {
  HeaderContent,
  HeaderWraper,
  LandingPageButton,
} from "./LandingPageStyle";
import { useNavigate } from "react-router-dom";
import Logo from "assets/LandingPage/logo.png";
import { LandingMobile, useIpad, useMobile } from "const/MuiMediaQuery";
const Header = () => {
  const mobile = useMobile();
  const ipad = LandingMobile();
  const navigate = useNavigate();
  return (
    <HeaderWraper>
      <HeaderContent>
        <img
          src={Logo}
          width={131}
          style={{ cursor: "pointer" }}
          onClick={() => navigate("/")}
        />
        {mobile || ipad ? null : (
          <LandingPageButton Size="sm" onClick={() => navigate("/homepage")}>
            Explore
          </LandingPageButton>
        )}
      </HeaderContent>
    </HeaderWraper>
  );
};
export default Header;
