import styled from "styled-components";
import { Button } from "@mui/material";
import {
  LandingIpad,
  LandingIpad2,
  Mobile,
  IpadMini,
} from "../LandingPage/MediaQuery/Query";

interface BgProps {
  bgColor?: "primary" | "secondary";
}

interface FontWeightProps {
  fontWeight?: "600";
}

export const DowloadLink = styled.a`
  text-decoration: none;

  @media (${Mobile}) {
    width: 100%;
  }
`;

export const GuideLineContainer = styled.div<BgProps>`
  background-color: ${(props) =>
    props.bgColor == "secondary" ? "#141414" : "#060606"};
  padding: 4rem 9.5rem;

  @media (${LandingIpad}) {
    padding: 4rem 1.5rem;
  }
  @media (${IpadMini}) {
    padding: 4rem 1.5rem;
  }
  @media (${Mobile}) {
    padding: 24px 16px;
  }
`;

export const HeroImage = styled.div`
  width: 386px;
  height: 386px;

  @media (${Mobile}) {
    width: 140px;
    height: 140px;
  }
`;

export const GuidelineTitles = styled.h1`
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 64px;
  line-height: 78px;
  letter-spacing: 1.2px;
  color: #ffffff;
  margin: 0;

  @media (${Mobile}) {
    font-size: 32px;
    line-height: 42px;
  }
`;

export const GuidelineParagraph = styled.p<FontWeightProps>`
  font-family: "Inter";
  font-style: normal;
  font-weight: ${(props) => (props.fontWeight == "600" ? 600 : 400)};
  font-size: 20px;
  line-height: 24px;
  font-weight: 400;
  letter-spacing: 0.2px;
  margin: 10px 0;

  /* Secondary/Light */

  color: #9eabb1;

  @media (${Mobile}) {
    font-size: 16px;
    text-align: center;
  }
`;

export const ColorCodeParagraph = styled(GuidelineParagraph)<FontWeightProps>`
  font-weight: ${(props) => (props.fontWeight == "600" ? 600 : 400)};

  @media (${Mobile}) {
    font-size: 16px;
    text-align: center;
    margin: 0;
  }
`;

export const Title = styled.h3`
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 48px;
  line-height: 33px;
  margin: 0;
  color: #ffffff;

  @media (${Mobile}) {
    font-size: 24px;
  }
`;

export const SpaceBetween = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media (${Mobile}) {
    flex-direction: column;
    gap: 30px;
  }
`;

export const CardDetails = styled.div`
  padding: 0 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;

  @media (${Mobile}) {
    align-items: start;
  }
`;

export const Flex = styled.div`
  display: flex;
`;

export const GuideLineFillButton = styled(Button)`
  width: 335px !important;
  height: 72px !important;

  background: #30bcc2 !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center;
  border-radius: 100px !important;
  transition: 0.5s !important;

  font-family: "Inter" !important;
  //   font-family: "Montserrat" !important;
  font-style: normal !important;
  font-weight: 600 !important;
  font-size: 20px !important;
  line-height: 24px !important;
  text-transform: capitalize !important;
  color: #060606 !important;
  &:hover {
    background: #cafafc !important;
  }

  @media (${Mobile}) {
    width: 100% !important;
    font-size: 16px !important;
    height: 50px !important;
  }
`;

export const Image = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

export const IconWrapper = styled.div`
  margin-top: 60px;
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  justify-content: space-between;

  @media (${Mobile}) {
    margin-top: 30px;
    width: 100%;
    display: flex;
    justify-content: center;
    gap: 20px;
  }
`;

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  @media (${Mobile}) {
    align-items: center;
    justify-content: center;
  }
`;

export const MainIconWrapper = styled.div`
  margin: 60px 0;
  width: 176px;
  height: 176px;

  @media (${Mobile}) {
    width: 95px;
    height: 95px;
    margin: 30px 0;
  }

  @media (${LandingIpad}) {
    width: 107px;
    height: 107px;
  }
  @media (${IpadMini}) {
    width: 107px;
    height: 107px;
  }
`;

export const Icons = styled.div`
  width: 176px;
  height: 176px;
  @media (${LandingIpad}) {
    width: 107px;
    height: 107px;
  }

  @media (${IpadMini}) {
    width: 107px;
    height: 107px;
  }
`;

export const GuidelineDownloadButton = styled(Button)`
  width: 240px !important;
  height: 72px !important;
  background: #141414 !important;
  border: 1px solid #30bcc2 !important;
  border-radius: 100px !important;

  font-family: "Inter" !important;
  font-style: normal !important;
  font-weight: 600 !important;
  font-size: 20px !important;
  line-height: 24px !important;
  color: #30bcc2 !important;
  display: flex !important;
  align-items: center !important;
  text-transform: capitalize !important;
  // transition: 0.5s;
  transition: 0.5s !important;

  &:hover {
    background: #30bcc2 !important;
    color: black !important;
  }

  @media (${Mobile}) {
    width: 100% !important;
    height: 50px !important;
    font-size: 16px !important;
  }
`;

export const HeroSection = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  @media (${LandingIpad}) {
    flex-direction: column;
  }
  @media (${IpadMini}) {
    flex-direction: column;
  }
  @media (${Mobile}) {
    flex-direction: column;
    gap: 20px;
  }
`;

export const HeroContentWrapper = styled.div`

width: 50%;
display: flex;
flex-direction: column;
justify-content: start;
    align-items: start;
    gap: 20px;

    @media (${LandingIpad}) {
      width: 100%;
      align-items: center;
    }
    @media (${IpadMini}) {
      width: 100%;
      align-items: center;
    }
    @media (${Mobile}) {
      width: 100%;
      gap:0;
      align-items: center;
    }

}
`;

export const HeroImageWrapper = styled.div`
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @media (${Mobile}) {
    width: 100%;
  }
`;

export const LogoWrapper = styled.div`
  width: 100%;
  display: flex;

  flex-wrap: wrap;
  margin-top: 60px;

  @media (${Mobile}) {
    margin-top: 30px;
  }
`;

export const Logos = styled.div`
  width: 50%;
  @media (${Mobile}) {
    width: 100%;
  }
`;

export const Typography = styled.p`
  margin: 0;
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 64px;
  line-height: 77px;
  letter-spacing: 1.2px;
  color: #9eabb1;
  text-transform: capitalize;

  @media (${Mobile}) {
    font-size: 32px;
  }
`;

export const TypographyWrapper = styled.div`
  margin-top: 60px;
  display: flex;
  align-items: center;
  @media (${Mobile}) {
    margin-top: 30px;
    justify-content: center;
  }
`;

export const CardContainer = styled.div`
  margin-top: 60px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: -40px;

  @media (${Mobile}) {
    margin-top: 30px;
    margin-bottom: 0px;
  }
`;

export const ColorCardWrapper = styled.div`
  width: calc(33.33% - 20px);
  margin-bottom: 40px;

  @media (${LandingIpad}) {
    width: calc(50% - 20px);
  }

  @media (${IpadMini}) {
    width: calc(50% - 20px);
  }
  @media (${Mobile}) {
    width: calc(100%);
    margin-bottom: 10px;
  }
`;
export const ColorCard = styled.div`
  width: 120px;
  height: 120px;

  @media (${Mobile}) {
    width: 70px;
    height: 70px;
  }
`;
