import subscriptionModule from "./subscription_module_abis/SubscriptionModule.json";

// eslint-disable-next-line no-undef
const environment = process.env.REACT_APP_ENVIRONMENT;

const get_subscriptionModuleABI = (environment) => {
  switch (environment) {
    case "DAPPS_NEW_PRODUCTION":
      return subscriptionModule;

    default:
      return subscriptionModule;
  }
};
const subscriptionModuleABI = get_subscriptionModuleABI(environment);

export default subscriptionModuleABI;
