import Terms from "components/TermsConditon/Terms";
import React, { useEffect } from "react";
import Footer from "./Footer";
import Header from "./Header";
import { useLocation } from "react-router";

const TermsCondition = () => {
  const Location = useLocation();

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, [Location]);

  return (
    <>
      <Header />
      <Terms />
      <Footer />
    </>
  );
};

export default TermsCondition;
