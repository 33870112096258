import styled from "styled-components";

import { LandingIpad, LandingIpad2 } from "../LandingPage/MediaQuery/Query";

export const TermWrapper = styled.div`
  //   height: 548px;
  background: #060606;
  //   padding-top: 60px;
  padding-bottom: 60px;
  padding-left: 152px;
  padding-right: 152px;
  position: relative;

  @media (max-width: 768px) {
    padding: 48px 16px 24px;
  }
  @media (${LandingIpad}) {
    padding: 48px 16px 24px;
  }
`;

export const TCHeader = styled.h1`
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 48px;
  color: #ffffff;
  margin: 10px 0;
  @media (max-width: 768px) {
    font-size: 32px;
  }
`;
export const Center = styled.div`
  display: flex;
  justify-content: center;
`;

export const TermHeader = styled.h1`
  margin: 10px 0px;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  color: #ffffff;
`;

export const TermsText = styled.p`
  margin: 0;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  color: #9eabb1;
`;

export const SubHead = styled.h2`
  margin: 10px 0px 10px 20px;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  color: #ffffff;
  @media (max-width: 768px) {
    margin: 10px 0px 10px 0px;
  }
`;
export const SubText = styled.h2`
  margin: 10px 0px 10px 20px;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  color: #9eabb1;
  @media (max-width: 768px) {
    margin-top: 0;
    margin-left: 10px;
    margin-right: 10px;
  }
`;

export const List = styled.li`
  margin-bottom: 10px;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  color: #9eabb1;
`;
