// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: "AIzaSyDE2w3QgXzs_yvfiLh6avGKh34T8KWtYV4",
    authDomain: "dapps-3fae1.firebaseapp.com",
    projectId: "dapps-3fae1",
    storageBucket: "dapps-3fae1.appspot.com",
    messagingSenderId: "362915804358",
    appId: "1:362915804358:web:37f302417854f5a35893c7",
    measurementId: "G-BKNTE47NQV"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

export const auth = getAuth(app);