import Snackbar from "@mui/material/Snackbar";
import styled from "styled-components";
interface isProps {
  position?: boolean;
  isInfo?: boolean;
}
export const CustomeSnackBar = styled(Snackbar)<isProps>`

    z-index:${(props) =>
      props.isInfo ? "11 !important;" : "10000 !important;"}
    @media (max-width: 430px) {
        z-index:123333 !important;
    }

& .MuiAlert-filledSuccess{
    font-family: 'Montserrat';
    font-weight: 500;
    font-size: 0.875rem;
    line-height: 1.43;
    letter-spacing: 0.01071em;
    background-color: #ECF8F0;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    padding: 6px 16px;
    color: #44A55A;
    width: 100%;
    border: 1px solid #44A55A;
    margin-top: ${(props) => (props.position ? "0rem;" : "4rem;")}
    box-shadow: 6px 6px 14px rgb(68 165 90 / 25%);
    border-radius: 15px;
   z-index:-11111;
    @media (max-width: 430px) {
        margin-top: ${(props) => (props.position ? "0rem;" : "4rem;")}
    }
}


& .MuiAlert-filledWarning{
    font-family: 'Montserrat';
    font-weight: 500;
    font-size: 0.875rem;
    line-height: 1.43;
    letter-spacing: 0.01071em;
    background-color: #FEF5EB;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    padding: 6px 16px;
    color: #FF9039;
    width: 100%;
    border: 1px solid #FF9039;
    margin-top: 4rem;
    box-shadow: 6px 6px 14px rgb(255 144 57 / 15%);
    border-radius: 15px;
    z-index:-11;
}
&  .MuiAlert-filledInfo{
    font-family: 'Montserrat';
    font-weight: 500;
    font-size: 0.875rem;
    line-height: 1.43;
    letter-spacing: 0.01071em;
    background-color: #EEF2FB;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    padding: 6px 16px;
    color:  #3A62B1;
    width: 100%;
    border: 1px solid #3A62B1;
    margin-top: 4rem;
    box-shadow: 6px 6px 14px rgba(58, 98, 177, 0.15);
    border-radius: 15px;
    z-index:-11;
}
& .MuiAlert-filledError{
    font-family: 'Montserrat';
    font-weight: 500;
    font-size: 0.875rem;
    line-height: 1.43;
    letter-spacing: 0.01071em;
    background-color: #fedfdf;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    padding: 6px 16px;
    color: #eb0f0f;
    width: 100%;
    border: 1px solid #ed0909;
    margin-top: 4rem;
    box-shadow: 6px 6px 14px rgb(165 68 68 / 25%);
    border-radius: 15px;
    z-index:-11;
    @media(max-width:430px){
        margin-top: 3rem;
    }
}


`;
export const MessageWraper = styled.div`
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  color: #44a55a;
`;
