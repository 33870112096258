import { ApolloClient, InMemoryCache } from "@apollo/client";

export const graphSupportedChains = [56, 137, 250, 997];

const environment = process.env.REACT_APP_ENVIRONMENT;

const getDappsGraphBaseUri = (chainId: number) => {
  switch (chainId) {
    case 1:
      return "";
    case 56:
      return "https://api.thegraph.com/subgraphs/name/rajatlko13/dapps-staging-bsc";
    case 137:
      switch (environment) {
        case "DAPPS_NEW_PRODUCTION":
          return "https://api.thegraph.com/subgraphs/name/rajatlko13/dapps-staging-polygon";
        case "DAPPS":
          return "https://api.thegraph.com/subgraphs/name/rajatlko13/messaginggraph";
        default:
          return "https://api.thegraph.com/subgraphs/name/rajatlko13/messaginggraph";
      }
    case 250:
      return "https://api.thegraph.com/subgraphs/name/rajatlko13/dapps-staging-fantom";
    case 8081:
      return "https://stagingapi.dapps.co";
    // return "http://localhost:9000";
    case 997:
      return "https://stagingapi.dapps.co";
    // return "http://localhost:9000";
    default:
      return null;
  }
};

export const getGraphqlClient = (chainId: number) => {
  const client = new ApolloClient({
    uri: getDappsGraphBaseUri(chainId),
    cache: new InMemoryCache(),
  });
  return client;
};

const getDappsDBGraphBaseUri = (chainId: number) => {
  switch (chainId) {
    case 1:
      return "";
    case 56:
      return "https://api.thegraph.com/subgraphs/name/rajatlko13/dapps-staging-bsc";
    case 137:
      switch (environment) {
        case "DAPPS_NEW_PRODUCTION":
          return "http://localhost:4000/";
        case "DAPPS":
          return "https://api.thegraph.com/subgraphs/name/rajatlko13/messaginggraph";
        default:
          return "https://api.thegraph.com/subgraphs/name/rajatlko13/messaginggraph";
      }
    case 250:
      return "https://api.thegraph.com/subgraphs/name/rajatlko13/dapps-fantom";
    default:
      return null;
  }
};

export const getDBGraphqlClient = () => {
  const client = new ApolloClient({
    uri: "https://stagingapi.dapps.co",
    // uri: "http://localhost:9000",
    cache: new InMemoryCache(),
  });
  return client;
};
