import { MediaQuery } from "const/MediaQuery";
import styled from "styled-components";

export const FooterWrapper = styled.div`
  height: auto;
  background: #ffffff;
  box-shadow: 0px -1px 0px rgba(0, 0, 0, 0.09);
  // margin-top: 3rem;
  //  margin-top: 20rem;
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
  @media (max-width: 430px) {
    height: auto;
    background: #ffffff;
    box-shadow: 0px -1px 0px rgb(0 0 0 / 9%);
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
  }
  @media ${MediaQuery?.Ipad} {
    height: auto;
    background: #ffffff;
    box-shadow: 0px -1px 0px rgb(0 0 0 / 9%);
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
  }
  @media ${MediaQuery?.Ipad2} {
    height: auto;
    background: #ffffff;
    box-shadow: 0px -1px 0px rgb(0 0 0 / 9%);
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
  }
`;
export const LinkWrapper = styled.div`
  display: flex;
  align-items: start;

  justify-content: space-between;
  @media (max-width: 430px) {
    flex-direction: column;
    row-gap: 20px;
  }
  @media ${MediaQuery?.Ipad} {
    column-gap: 4rem;
    justify-content: center;
    flex-direction: column;
    row-gap: 17px;
  }
  @media (${MediaQuery.Ipad2}) {
  }
`;
export const CopyWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 3.5rem;
  column-gap: 0.5rem;
  @media (max-width: 430px) {
    flex-direction: column;
    row-gap: 30px;
  }
  @media ${MediaQuery?.Ipad} {
    margin-top: 2.5rem;
  }
`;
export const LinkDataWrap = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 16px;
  text-align: left;
`;
export const Title = styled.div`
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  color: #231f20;
  @media (max-width: 430px) {
    font-size: 16px;
  }
`;
export const Text = styled.div`
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  display: flex;
  align-items: center;
  column-gap: 7px;
  font-size: 16px;
  color: #231f20;
  cursor: pointer;
  @media (max-width: 430px) {
    font-size: 14.5px;
  }
  &:hover {
    color: #30bcc2;
  }
`;
export const SocialWrapper = styled.div`
  display: flex;
  column-gap: 10px;
  align-items: center;
`;
export const LogoWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  column-gap: 10rem;

  @media (max-width: 768px) {
    flex-direction: column;
    row-gap: 30px;
    & img {
      width: 150px;
    }
  }
  @media ${MediaQuery?.Ipad} {
    column-gap: 5rem;
  }
  @media ${MediaQuery?.Ipad2} {
    column-gap: 5rem;
  }
`;
export const FooterWrapper1 = styled.div`
  display: flex;
  justify-content: space-between;
  width: 85%;
  @media (max-width: 430px) {
    flex-direction: column;
    row-gap: 30px;
  }
  @media ${MediaQuery?.Ipad} {
    width: 100%;
  }
  @media ${MediaQuery?.Ipad2} {
    width: 100%;
  }
`;
