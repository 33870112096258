import { FooterWrapper } from "components/LandingPage/LandingPageStyle";
import React from "react";
import {
  TermHeader,
  TermWrapper,
  TermsText,
  SubHead,
  SubText,
  List,
  TCHeader,
  Center,
} from "./Terms.style";

const Terms = () => {
  return (
    <TermWrapper>
      <Center>
        <TCHeader>
          Terms & <span style={{ color: "#30bcc2" }}>Condition</span>
        </TCHeader>
      </Center>
      <TermHeader>A. INTRODUCTION TO OUR SERVICES</TermHeader>
      <TermsText>
        This Agreement governs your use of Dapps Services (Services - e.g., Name
        of the services), through which you can buy, get, license, rent, or
        subscribe to services, Apps (as defined below), (collectively,
        Services). Services may be provided by the dapps or by a third party.
        Our Services are available in your country or territory of residence. By
        connecting a wallet for use of the Services in any country or territory,
        you define it as your Home Country. To use our Services, you must have
        compatible hardware, software, and Internet access (fees may apply).
        These factors may have an impact on the performance of our services.
      </TermsText>
      <TermHeader>
        B. USING OUR SERVICES,PAYMENTS, TAXES, AND REFUNDS
      </TermHeader>
      <TermsText>
        You can obtain Services on our Website or app for free or at a cost,
        which is referred to as a Transaction. Each Transaction is an electronic
        contract between you and Dapps, as well as between you and the entity
        providing Services on our platform. When you first make a transaction.
      </TermsText>
      <TermsText style={{ marginTop: "10px" }}>
        Dapps will charge fees for any paid Transactions in governing token or
        stable coin as well. If you have also added it to your Dapps Wallet,
      </TermsText>
      <TermsText style={{ marginTop: "10px" }}>
        We cannot charge you for any payment failure reasons, you remain
        responsible for any uncollected amounts, and we will not attempt to
        charge you again until you try to initiate any repayment.
      </TermsText>
      <SubHead>ACCOUNT</SubHead>
      <SubText>
        Using our Services and accessing your Content may necessitate the use of
        a wallet address. A wallet address is an account that you use throughout
        the Web 3.0 ecosystem. Your account is valuable, and you are responsible
        for keeping it secure and confidential. Dapps is not liable for any
        losses incurred as a result of unauthorized use of your wallet. If you
        believe your wallet has been compromised, please contact dapps.
      </SubText>
      <SubHead>PRIVACY</SubHead>
      <SubText>
        Your use of our Services is subject to Dapps Privacy Policy, which is
        available at (Link of privacy policy)
      </SubText>
      <SubHead>SERVICES AND CONTENT USAGE RULES</SubHead>
      <SubText>
        Your use of the Services must adhere to the guidelines outlined in this
        section (Usage Rules). Any other use of the Services and Feature is in
        violation of this Agreement. Dapps may monitor your use of the Services
        and Features to ensure you are abiding by these Usage Rules.
      </SubText>
      <SubHead>ALL SERVICES</SubHead>
      <ul>
        <List>
          You can use the Services and Content for personal as well as
          commercial purposes (except as set forth in the dapps Store Content
          section below or as otherwise specified by dapps).
        </List>
        <List>
          Dapps delivery of Services does not transfer any commercial or
          promotional use rights to you, and does not constitute a grant or
          waiver of any rights of the copyright owners.
        </List>
        <List>
          Manipulating play counts, downloads, ratings, or reviews via any means
          — such as (i) using a bot, script, or automated process; or (ii)
          providing or accepting any kind of compensation or incentive — is
          prohibited.
        </List>
        <List>
          It is your responsibility not to lose, destroy, or damage services
          once availed.
        </List>
        <List>
          You may not tamper with or circumvent any security technology included
          with the Services or Features.
        </List>
        <List>
          You may access our Services only using dapps software, Website, and
          via dapps authorized 3rd party services, may not modify or use
          modified versions of such software.
        </List>
      </ul>

      <SubHead>DOWNLOADS</SubHead>
      <SubText>
        You may be limited in the amount of Content you may download, and some
        downloaded Content may expire after a given amount of time after
        downloaded. Certain Content may not be available for download at all.
      </SubText>
      <SubText>
        You may be able to redownload previously acquired Services (Redownload)
        to your devices Via your wallet address. Content may not be available
        for Redownload if that Content is no longer offered on our Services.
      </SubText>
      <SubText>
        Content also may be removed from our Services at any time (for instance,
        because the provider removed it), after which it cannot be downloaded,
        re-downloaded, or otherwise accessed from dapps.
      </SubText>

      <SubHead>SUBSCRIPTIONS</SubHead>
      <SubText>
        You may be able to purchase access to Content or Services on a
        subscription basis through the Services and certain Apps (Paid
        Subscriptions plus free subscriptions). If we are unable to charge your
        payment method for any reason (such as expiration or insufficient
        funds), and you have not canceled the Paid Subscription, you are still
        liable for any uncollected amounts. This may cause a delay in the start
        of your next Paid Subscription period, as well as a change in the date
        on which you are billed for each period. as shown on your receipt. If we
        are unable to successfully charge your payment method to renew your
        subscription, we reserve the right to cancel your Paid Subscription.
        Some Paid Subscriptions may provide a free trial period before charging
        your payment method. If you want to cancel a Paid Subscription before we
        charge your payment method, you must do so at least 24 hours before the
        free trial ends.
      </SubText>
      <SubText>
        If you start a free trial to a Paid Subscription offered by dapps as a
        service provider and cancel before it ends, you cannot reactivate the
        free trial.
      </SubText>
      <SubText>
        Free trials or free offers to dapps Paid Subscriptions, cannot be
        combined with any free trials or offers.
      </SubText>
      <SubText>
        When your Paid Subscription to any Service or Content ends, you will
        lose access to any functionality or Content of that Service that
        requires a Paid Subscription.
      </SubText>

      <TermHeader>C. YOUR SUBMISSIONS TO OUR SERVICES</TermHeader>
      <TermsText>
        You may be able to submit or post materials such as ratings and reviews
        through our Services. Your use of such features must adhere to the
        Submissions Guidelines, which may be updated from time to time, and we
        will remove materials that violate our Submission Guidelines if we
        become aware of them. Please use the Report a Concern feature if you see
        materials that do not adhere to the Submissions Guidelines. Except where
        prohibited by law, you hereby grant dapps a worldwide, royalty-free,
        perpetual, nonexclusive licence to use the materials you submit in
        connection with the Services and related marketing, as well as for dapps
        internal purposes. Dapps may monitor submitted content and decide to
        remove or edit it.
      </TermsText>
      <SubText>
        Submissions Guidelines: You may not use the Services to:
      </SubText>
      <ul>
        <List>
          post any materials that (i) you do not have permission, right or
          license to use, or (ii) infringe on the rights of any third party
        </List>
        <List>
          post objectionable, offensive, unlawful, deceptive, inaccurate, or
          harmful content
        </List>
        <List>
          post personal, private or confidential information belonging to others
        </List>
        <List>request personal information from a minor</List>
        <List>
          impersonate or misrepresent your affiliation with another person, or
          entity
        </List>
        <List>
          post or transmit spam, including but not limited to unsolicited or
          unauthorized advertising, promotional materials, or informational
          announcements
        </List>
        <List>
          post, modify, or remove a rating or review in exchange for any kind of
          compensation or incentive
        </List>
        <List>
          post a dishonest, abusive, harmful, misleading, or bad-faith rating or
          review, or a rating or review that is irrelevant to the Content being
          reviewed
        </List>
        <List>
          plan or engage in any illegal, fraudulent, or manipulative activity
        </List>
      </ul>

      <SubHead>LICENSE OF APP STORE CONTENT</SubHead>
      <SubText>
        Dapps or a third-party developer App Provider provides you with app
        licences. Dapps operates the dapps Store as an agent for App/Website
        Providers and is not a party to the sales contract or user agreement
        between you and the App Provider
      </SubText>
      <SubText>
        IN-APP PURCHASES Apps may include content, services, or functionality
        that can only be accessed through the app (In-App Purchases). In-App
        Purchases made while using the App (for example, virtual gems) cannot be
        transferred between devices and can only be downloaded once. Before
        making In-App Purchases, you must authenticate your account by entering
        your password or using Touch ID or Face ID, which is separate from any
        authentication required to obtain other Content.
      </SubText>

      <SubHead>APP MAINTENANCE AND SUPPORT</SubHead>
      <SubText>
        Dapps is responsible for providing maintenance and support for Dapps
        Apps only, or as required under applicable law. App Providers are
        responsible for providing maintenance and support for Third Party Apps.
      </SubText>

      <SubHead>APP BUNDLES</SubHead>
      <SubText>
        Some Apps may be sold as a package (App Bundle). The price shown with an
        App Bundle is the price you will be charged when you buy the App Bundle.
        The price of the App Bundle may be reduced to account for Apps you have
        already purchased or obtained, but there may be a minimum charge to
        complete the App Bundle.
      </SubText>

      <SubHead>SEARCH RESULTS AND RANKINGS</SubHead>
      <SubText>
        When determining how to order the Apps in search results, the main
        parameters considered are the data provided by App Providers, how
        customers have engaged with the dapps and the dapps Store, and the
        popularity of the dapps in the dapps Store. These main parameters
        deliver the most relevant results to customer search queries.
      </SubText>

      <SubHead>LICENSED APPLICATION END USER LICENSE AGREEMENT</SubHead>
      <SubText>
        Apps made available through the Dapps Store are licensed, not sold, to
        you. Your license to each App is subject to your prior acceptance of
        either this Licensed Application End User License Agreement or a custom
        end user license agreement between you and the App Provider if one is
        provided.
      </SubText>
      <ul>
        <List>
          Scope of License: The Licensor grants you a nontransferable license to
          use the Licensed Application on any dapps-branded products that you
          own or control and as permitted by the Usage Rules. The terms of this
          Standard will govern any content, materials, or services accessible
          from or purchased within the Licensed Application as well as upgrades
          provided by Licensor that replace or supplement the original Licensed
          Application, unless such upgrade is accompanied by a Custom. Except as
          provided in the Usage Rules, you may not distribute or make the
          Licensed Application available over a network where it could be used
          by multiple devices at the same time. You may not transfer,
          redistribute or sublicense the Licensed Application except as
          expressly permitted in this Agreement
        </List>
        <List>
          Consent to Use of Data: You agree that Licensor may collect and use
          technical data and related information—including but not limited to
          technical information about your device, system and application
          software, and peripherals—that is gathered periodically to facilitate
          the provision of software updates, product support, and other services
          to you (if any) related to the Licensed Application. Licensor may use
          this information, as long as it is in a form that does not personally
          identify you, to improve its products or to provide services or
          technologies to you.
        </List>
        <List>
          NO WARRANTY: YOU EXPRESSLY ACKNOWLEDGE AND AGREE THAT USE OF THE
          LICENSED APPLICATION IS AT YOUR SOLE RISK. TO THE MAXIMUM EXTENT
          PERMITTED BY APPLICABLE LAW, THE LICENSED APPLICATION AND ANY SERVICES
          PERFORMED OR PROVIDED BY THE LICENSED APPLICATION ARE PROVIDED AS IS
          AND AS AVAILABLE, WITH ALL FAULTS AND WITHOUT WARRANTY OF ANY KIND,
          AND LICENSOR HEREBY DISCLAIMS ALL WARRANTIES AND CONDITIONS WITH
          RESPECT TO THE LICENSED APPLICATION AND ANY SERVICES, EITHER EXPRESS,
          IMPLIED, OR STATUTORY, INCLUDING, BUT NOT LIMITED TO, THE IMPLIED
          WARRANTIES AND/OR CONDITIONS OF MERCHANTABILITY, OF SATISFACTORY
          QUALITY, OF FITNESS FOR A PARTICULAR PURPOSE, OF ACCURACY, OF QUIET
          ENJOYMENT, AND OF NONINFRINGEMENT OF THIRD-PARTY RIGHTS. NO ORAL OR
          WRITTEN INFORMATION OR ADVICE GIVEN BY LICENSOR OR ITS AUTHORIZED
          REPRESENTATIVE SHALL CREATE A WARRANTY. SHOULD THE LICENSED
          APPLICATION OR SERVICES PROVE DEFECTIVE, YOU ASSUME THE ENTIRE COST OF
          ALL NECESSARY SERVICING, REPAIR, OR CORRECTION. SOME JURISDICTIONS DO
          NOT ALLOW THE EXCLUSION OF IMPLIED WARRANTIES OR LIMITATIONS ON
          APPLICABLE STATUTORY RIGHTS OF A CONSUMER, SO THE ABOVE EXCLUSION AND
          LIMITATIONS MAY NOT APPLY TO YOU.
        </List>
        <List>
          Limitation of Liability. TO THE EXTENT NOT PROHIBITED BY LAW, IN NO
          EVENT SHALL LICENSOR BE LIABLE FOR PERSONAL INJURY OR ANY INCIDENTAL,
          SPECIAL, INDIRECT, OR CONSEQUENTIAL DAMAGES WHATSOEVER, INCLUDING,
          WITHOUT LIMITATION, DAMAGES FOR LOSS OF PROFITS, CORRUPTION OR LOSS OF
          DATA, FAILURE TO TRANSMIT OR RECEIVE ANY DATA (INCLUDING WITHOUT
          LIMITATION COURSE INSTRUCTIONS, ASSIGNMENTS AND MATERIALS), BUSINESS
          INTERRUPTION, OR ANY OTHER COMMERCIAL DAMAGES OR LOSSES, ARISING OUT
          OF OR RELATED TO YOUR USE OF OR INABILITY TO USE THE LICENSED
          APPLICATION, HOWEVER CAUSED, REGARDLESS OF THE THEORY OF LIABILITY
          (CONTRACT, TORT, OR OTHERWISE) AND EVEN IF LICENSOR HAS BEEN ADVISED
          OF THE POSSIBILITY OF SUCH DAMAGES. SOME JURISDICTIONS DO NOT ALLOW
          THE EXCLUSION OR LIMITATION OF LIABILITY FOR PERSONAL INJURY, OR OF
          INCIDENTAL OR CONSEQUENTIAL DAMAGES, SO THIS LIMITATION MAY NOT APPLY
          TO YOU.
        </List>
      </ul>

      <SubHead>CONTRACT CHANGES</SubHead>
      <SubText>
        Dapps reserves the right at any time to modify this Agreement and to add
        new or additional terms or conditions on your use of the Services. Such
        modifications and additional terms and conditions will be effective
        immediately and incorporated into this Agreement. Your continued use of
        the Services will be deemed acceptance thereof.
      </SubText>
      <SubHead>THIRD-PARTY MATERIALS</SubHead>
      <SubText>
        Dapps is not responsible or liable for third party materials included
        within or linked from the Content or the Services.
      </SubText>
      <SubHead>INTELLECTUAL PROPERTY</SubHead>
      <SubText>
        You acknowledge and agree that the Services, including but not limited
        to Content, graphics, user interface, audio clips, video clips,
        editorial content, and the scripts and software used to implement the
        Services, contain proprietary information and material owned by Dapps,
        its licensors, and/or Content providers and protected by applicable
        intellectual property and other laws, including but not limited to
        copyright. You agree not to use such proprietary information or
        materials in any way other than for personal, commercial purposes in
        accordance with this Agreement. Except as expressly permitted by this
        Agreement, no portion of the Content or Services may be transferred or
        reproduced in any form or by any means. You agree not to modify, rent,
        or loan the property.You may not sell, share, or distribute the Services
        or Content in any way, and you may not use the Services in any way that
        is not expressly authorised.
      </SubText>
      <SubHead>COPYRIGHT</SubHead>
      <SubText>
        Unless otherwise noted, Services and Content provided by Dapps are
        copyrights of Company name and its subsidiaries.
      </SubText>
      <SubText>
        If you believe that any Content available through the Services infringe
        a copyright claimed by you, please contact Dapps Via below mentioned
        addresses
      </SubText>
      <SubText>
        This is the fastest way for us to process your notification of claimed
        infringement. The slower method to reach our designated agent and to
        process your notification is to send a proper and complete notification
        of claimed infringement to the mailing address listed below.
      </SubText>
      <SubText>
        Dapps has adopted a policy of disabling and/or terminating the accounts
        of users who are repeatedly found to infringe or are repeatedly claimed
        to infringe the copyrights of others. Dapps may suspend, disable, and/or
        terminate the accounts of users who have been identified as repeatedly
        engaging in infringing activities or for other related reasons as part
        of implementing such policy.
      </SubText>

      <SubHead>TERMINATION AND SUSPENSION OF SERVICES</SubHead>
      <SubText>
        If you fail, or Dapps suspects that you have failed, to comply with any
        of the provisions of this Agreement, Dapps may, without notice to you:
        (i) terminate this Agreement and you will remain liable for all amounts
        due under your wallet up to and including the date of termination;
        and/or (ii) terminate your license to the software; and/or (iii)
        preclude your access to the Services.
      </SubText>
      <SubText>
        Dapps further reserves the right to modify, suspend, or discontinue the
        Services (or any part or Content thereof) at any time with or without
        notice to you, and Dapps will not be liable to you or to any third party
        should it exercise such rights.
      </SubText>

      <SubHead>DISCLAIMER OF WARRANTIES; LIABILITY LIMITATION</SubHead>
      <SubText>
        DAPPS DOES NOT GUARANTEE, REPRESENT, OR WARRANT THAT YOUR USE OF THE
        SERVICES WILL BE UNINTERRUPTED OR ERROR-FREE, AND YOU AGREE THAT FROM
        TIME TO TIME DAPPS MAY REMOVE THE SERVICES FOR INDEFINITE PERIODS OF
        TIME, CANCEL THE SERVICES AT ANY TIME, OR OTHERWISE LIMIT OR DISABLE
        YOUR ACCESS TO THE SERVICES WITHOUT NOTICE TO YOU.
      </SubText>
      <SubText>
        YOU EXPRESSLY AGREE THAT YOUR USE OF, OR INABILITY TO USE, OR ACTIVITY
        IN CONNECTION WITH THE SERVICES IS AT YOUR SOLE RISK. THE SERVICES AND
        ALL CONTENT DELIVERED TO YOU THROUGH THE SERVICES ARE (EXCEPT AS
        EXPRESSLY STATED BY DAPPS) PROVIDED AS IS AND AS AVAILABLE FOR YOUR USE,
        WITHOUT WARRANTIES OF ANY KIND, EITHER EXPRESS OR IMPLIED, INCLUDING ALL
        IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE,
        TITLE, AND NONINFRINGEMENT. BECAUSE SOME JURISDICTIONS DO NOT ALLOW THE
        EXCLUSION OF IMPLIED WARRANTIES, THE ABOVE EXCLUSION OF IMPLIED
        WARRANTIES MAY NOT APPLY TO YOU.
      </SubText>
      <SubText>
        YOU AGREE THAT UNLESS REQUIRED BY APPLICABLE LAW, DAPPS HAS NO
        RESPONSIBILITY TO CONTINUE MAKING CONTENT AVAILABLE TO YOU THROUGH OUR
        SERVICES, AND DAPPS WILL NOT BE LIABLE TO YOU IF CONTENT, INCLUDING
        PURCHASED CONTENT, BECOMES UNAVAILABLE FOR DOWNLOAD, REDOWNLOAD, OR
        STREAMING.
      </SubText>
      <SubText>
        IN NO CASE SHALL DAPPS, ITS DIRECTORS, OFFICERS, EMPLOYEES, AFFILIATES,
        AGENTS, CONTRACTORS, OR LICENSORS BE LIABLE FOR ANY DIRECT, INDIRECT,
        INCIDENTAL, PUNITIVE, SPECIAL, OR CONSEQUENTIAL DAMAGES ARISING FROM
        YOUR USE OF ANY OF THE SERVICES OR FOR ANY OTHER CLAIM RELATED IN ANY
        WAY TO YOUR USE OF THE SERVICES AND/OR CONTENT, INCLUDING, BUT NOT
        LIMITED TO, ANY ERRORS OR OMISSIONS IN ANY CONTENT, OR ANY INJURY, LOSS
        OR DAMAGE OF ANY KIND INCURRED AS A RESULT OF THE USE OF ANY CONTENT
        POSTED, TRANSMITTED, OR OTHERWISE MADE AVAILABLE VIA THE SERVICES, EVEN
        IF ADVISED OF THEIR POSSIBILITY. BECAUSE SOME COUNTRIES, STATES OR
        JURISDICTIONS DO NOT ALLOW THE EXCLUSION OR THE LIMITATION OF LIABILITY
        FOR CONSEQUENTIAL OR INCIDENTAL DAMAGES, IN SUCH COUNTRIES, STATES OR
        JURISDICTIONS, Dapps LIABILITY SHALL BE LIMITED TO THE EXTENT SUCH
        LIMITATION IS PERMITTED BY LAW.
      </SubText>
      <SubText>
        DAPPS SHALL USE REASONABLE EFFORTS TO PROTECT INFORMATION SUBMITTED BY
        YOU IN CONNECTION WITH THE SERVICES, BUT YOU AGREE THAT YOUR SUBMISSION
        OF SUCH INFORMATION IS AT YOUR SOLE RISK, AND YOU HEREBY RELEASE DAPPS
        FROM ANY AND ALL LIABILITY TO YOU FOR ANY LOSS OR LIABILITY RELATING TO
        SUCH INFORMATION IN ANY WAY.
      </SubText>
      <SubText>
        DAPPS DOES NOT REPRESENT OR GUARANTEE THAT THE SERVICES WILL BE FREE
        FROM LOSS, CORRUPTION, ATTACK, VIRUSES, INTERFERENCE, HACKING, OR OTHER
        SECURITY INTRUSION, AND YOU HEREBY RELEASE DAPPS FROM ANY LIABILITY
        RELATING THERETO. YOU SHALL BE RESPONSIBLE FOR BACKING UP YOUR OWN
        SYSTEM, INCLUDING ANY CONTENT PURCHASED OR ACQUIRED THROUGH THE
        SERVICES.
      </SubText>
      <SubText>
        DAPPS IS NOT RESPONSIBLE FOR DATA CHARGES YOU MAY INCUR IN CONNECTION
        WITH YOUR USE OF THE SERVICES.
      </SubText>

      <SubHead>WAIVER AND INDEMNITY</SubHead>

      <SubText>
        BY USING THE SERVICES, YOU AGREE, TO THE EXTENT PERMITTED BY LAW, TO
        INDEMNIFY AND HOLD DAPPS, ITS DIRECTORS, OFFICERS, EMPLOYEES,
        AFFILIATES, AGENTS, CONTRACTORS, AND LICENSORS HARMLESS WITH RESPECT TO
        ANY CLAIMS ARISING OUT OF YOUR BREACH OF THIS AGREEMENT, YOUR USE OF THE
        SERVICES, OR ANY ACTION TAKEN BY DAPPS AS PART OF ITS INVESTIGATION OF A
        SUSPECTED VIOLATION OF THIS AGREEMENT OR AS A RESULT OF ITS FINDING OR
        DECISION THAT A VIOLATION OF THIS AGREEMENT HAS OCCURRED. YOU AGREE THAT
        YOU SHALL NOT SUE OR RECOVER ANY DAMAGES FROM DAPPS, ITS DIRECTORS,
        OFFICERS, EMPLOYEES, AFFILIATES, AGENTS, CONTRACTORS, AND LICENSORS AS A
        RESULT OF ITS DECISION TO REMOVE OR DENY ACCESS TO ANY INFORMATION OR
        CONTENT, TO SUSPEND OR TERMINATE YOUR ACCESS TO THE SERVICES, OR TO TAKE
        ANY OTHER ACTION DURING THE INVESTIGATION OF A SUSPECTED VIOLATION OR AS
        A RESULT OF DAPPS CONCLUSION THAT A VIOLATION OF THIS AGREEMENT HAS
        OCCURRED. THIS WAIVER AND INDEMNITY PROVISION APPLIES TO ALL VIOLATIONS
        DESCRIBED IN OR CONTEMPLATED BY THIS AGREEMENT.
      </SubText>
      <SubHead>STATUTORY EXCEPTIONS FOR PUBLIC INSTITUTIONS</SubHead>
      <SubText>
        If you are a qualified public educational or government institution and
        any part of this Agreement, such as, by way of example, all or part of
        the indemnification section, is invalid or unenforceable against you
        because of applicable local, national, state or federal law, then that
        portion shall be deemed invalid or unenforceable, as the case may be,
        and instead construed in a manner most consistent with applicable
        governing law.
      </SubText>
      <SubHead>GOVERNING LAW</SubHead>
      <SubText>
        Except to the extent expressly provided in the following paragraph or to
        the extent required by applicable law, this Agreement and the
        relationship between you and Dapps, and all Transactions on the Services
        shall be governed by the laws of the Country Name, excluding its
        conflicts of law provisions. You and dapps agree to submit to the
        personal and exclusive jurisdiction of the courts located within the
        county of
      </SubText>
      <SubHead>OTHER PROVISIONS</SubHead>
      <SubText>
        This Agreement constitutes the entire agreement between you and Dapps
        and governs your use of the Services, superseding any prior agreements
        with respect to the same subject matter between you and Dapps. You also
        may be subject to additional terms and conditions that may apply when
        you make Transactions or use affiliate services, third-party content,
        third-party software, or additional services such as the Volume Purchase
        Program. If any part of this Agreement is held invalid or unenforceable,
        that portion shall be construed in a manner consistent with applicable
        law to reflect, as nearly as possible, the original intentions of the
        parties, and the remaining portions shall remain in full force and
        effect. Dapps failure to enforce any right or provisions in this
        Agreement will not constitute a waiver of such or any other provision.
        dapps will not be responsible for failures to fulfill any obligations
        due to causes beyond its control.
      </SubText>
      <SubText>
        You agree to comply with all local, state, federal, and national laws,
        statutes, ordinances, and regulations that apply to your use of the
        Services. Your use of the Services may also be subject to other laws.
        Risk of loss for all electronically delivered Transactions pass to the
        acquirer upon electronic transmission to the recipient. No Dapps
        employee or agent has the authority to vary this Agreement.
      </SubText>
      <SubText>
        Dapps may notify you with respect to the Services by sending an email
        message to your email address or a letter via postal mail to your
        mailing address, or by a posting on the Services. Notices shall become
        effective immediately. Dapps may also contact you by email or push
        notification to send you additional information about the Services.
      </SubText>
      <SubText>
        You hereby grant dapps the right to take steps dapps believes are
        reasonably necessary or appropriate to enforce and/or verify compliance
        with any part of this Agreement. You agree that dapps has the right,
        without liability to you, to disclose any data and/or information to law
        enforcement authorities, government officials, and/or a third party, as
        dapps believes is reasonably necessary or appropriate to enforce and/or
        verify compliance with any part of this Agreement (including but not
        limited to dapps right to cooperate with any legal process relating to
        your use of the Services and/or Content, and/or a third-party claim that
        your use of the Services and/or Content is unlawful and/or infringes
        such third partys rights).
      </SubText>
    </TermWrapper>
  );
};

export default Terms;
