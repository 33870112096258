import React from "react";
import { LoadingWrapper } from "./Loading.style";
import Dapps from "assets/images/dappsLoading.png";
const LoadingCompoent = () => {
  return (
    <LoadingWrapper>
      <img src={Dapps} width={120} className="blink_text" />
    </LoadingWrapper>
  );
};
export default LoadingCompoent;
