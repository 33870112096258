import { Button, TextField } from "@mui/material";
import styled from "styled-components";

import { LandingIpad, LandingIpad2 } from "./MediaQuery/Query";

export const HeaderWraper = styled.div`
  display: flex;
  flex-direction: row;

  align-items: center;
  padding: 24px 152px;
  height: 99px;
  background: #060606;
  width: 100%;
  @media (max-width: 768px) {
    padding: 16px;
  }
  @media (${LandingIpad}) {
    padding: 24px;
  }
`;
export const HeaderContent = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0px;
  // gap: 846px;
  height: 51px;
  width: 100%;
  @media (max-width: 768px) {
    justify-content: center;
  }
`;

export const HeroWrapper = styled.div`
  height: 100%;

  background: #060606;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 4rem;
  padding-top: 4rem;
  @media (max-width: 768px) {
    padding: 24px 16px;
    flex-direction: column;
    height: auto;
    gap: 32px;
  }
  @media (${LandingIpad}) {
    padding: 64px 24px;
    flex-direction: column;
    height: auto;
    gap: 64px;
  }
`;
export const ContentWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 40px;
  padding-left: 152px;
  @media (max-width: 768px) {
    padding-left: 0px;
    width: auto;
    gap: 16px;
    height: auto;
    align-items: center;
  }
  @media (${LandingIpad}) {
    padding-left: 0px;
    align-items: center;
  }

  & .MuiTypography-subtitle1 {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 600;
    font-size: 4vw;
    line-height: 4.6vw;
    letter-spacing: 1.2px;

    /* Primary/Light */

    color: #ffffff;
    & > b {
      color: #30bcc2;
    }
    @media (max-width: 768px) {
      font-family: "Montserrat";
      font-style: normal;
      font-weight: 600;
      font-size: 32px;
      line-height: 44px;
      text-align: center;

      /* Primary/Light */

      color: #ffffff;
    }
    @media (${LandingIpad}) {
      text-align: center;
      font-size: 7.1vw;
      line-height: 8.6vw;
    }
  }
  & .MuiTypography-subtitle2 {
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 24px;
    letter-spacing: 0.2px;

    /* Secondary/Light */

    color: #9eabb1;
    @media (max-width: 768px) {
      font-family: "Inter";
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      /* or 150% */

      text-align: center;

      /* Secondary/Light */

      color: #9eabb1;
    }
    @media (${LandingIpad}) {
      text-align: center;
    }
  }
`;

export const ImageDiv = styled.div`
  width: 80%;
`;
export const NotificationSectionWrap = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 176px 152px;
  gap: 10px;
  // height: 1414px;

  /* Secondary/Dark */

  background: #141414;
  @media (max-width: 768px) {
    padding: 48px 40px;
    gap: 10px;
    height: 100%;
  }
  @media (${LandingIpad}) {
    padding: 64px 113px;
    gap: 10px;
    height: 100%;
  }
`;
export const AlertContent = styled.div`
  height: auto;
  width: auto;
  @media (max-width: 768px) {
    width: 100%;
    height: 100%;
  }
  @media (${LandingIpad2}) {
    height: auto;
    width: 100%;
  }
  @media (${LandingIpad}) {
    width: 100%;
    height: 100%;
  }
`;
export const AlertDiv1 = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: end;
  align-items: center;
  gap: 16px;
  @media (max-width: 768px) {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0px;
    gap: 16px;
  }
  @media (${LandingIpad}) {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0px;
    gap: 16px;
  }
`;
export const AlertDiv1Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 32px;

  width: 464px;
  height: 478px;
  @media (max-width: 768px) {
    width: 100%;
    gap: 20px;
    height: auto;
  }
  @media (${LandingIpad}) {
    width: 100%;
    gap: 20px;
    height: auto;
  }
  & .MuiTypography-subtitle1 {
    font-family: "Inter";
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 30px;
    /* identical to box height, or 188% */

    letter-spacing: 0.1em;
    text-transform: uppercase;

    /* Secondary/Light */

    color: #9eabb1;
    @media (max-width: 768px) {
      font-weight: 600;
      font-size: 12px;
      line-height: 16px;
      /* identical to box height, or 133% */

      letter-spacing: 0.1em;
      text-transform: uppercase;
    }
  }
  & .MuiTypography-h6 {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 600;
    font-size: 48px;
    line-height: 59px;
    text-transform: capitalize;

    /* Primary/Light */

    color: #ffffff;
    @media (max-width: 768px) {
      font-weight: 600;
      font-size: 24px;
      line-height: 29px;
      text-transform: capitalize;

      /* Primary/Light */

      color: #ffffff;
    }
    & .words-wrapper {
      color: #30bcc2 !important;
      font-family: "Montserrat";
      font-style: normal !important;
      font-weight: 600 !important;
      font-size: 48px !important;
      line-height: 59px !important;
      text-transform: capitalize !important;
      @media (max-width: 768px) {
        font-weight: 600 !important;
        font-size: 24px !important;
        line-height: 29px !important;
        text-transform: capitalize !important;

        /* Primary/Light */
      }
    }
  }
  & .MuiTypography-subtitle2 {
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 30px;
    /* or 188% */

    /* Secondary/Light */

    color: #9eabb1;
    @media (max-width: 768px) {
      font-weight: 400;
      font-size: 12px;
      line-height: 20px;
    }
  }
`;
export const CheckWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 8px 0px;
  gap: 16px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  text-transform: capitalize;

  /* Primary/Light */

  color: #ffffff;
  @media (max-width: 768px) {
    font-size: 11px;
    line-height: 19px;
    text-transform: capitalize;
  }
  & > img {
    @media (max-width: 768px) {
      width: 19px;
    }
  }
`;
export const CheckMain = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 8px;
`;

export const AlertDiv1Image = styled.div`
  width: 560px;
  @media (max-width: 768px) {
    width: 288px;
  }
`;
export const AlertDiv2 = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: center;
  gap: 16px;
  @media (max-width: 768px) {
    flex-direction: column-reverse;
    justify-content: center;
    align-items: center;
    padding: 0px;
    gap: 16px;
  }
  @media (${LandingIpad}) {
    flex-direction: column-reverse;
    justify-content: center;
    align-items: center;
    padding: 0px;
    gap: 16px;
  }
`;
export const SpaceConainers = styled.div`
  height: 136px;
  width: 100%;
  @media (max-width: 768px) {
    height: auto;
    padding-top: 48px;
  }
`;

interface isbuttonProps {
  Size: "sm" | "md";
}
export const LandingPageButton = styled(Button)<isbuttonProps>`
width:${(props) =>
  props.Size == "sm"
    ? "159px !important;"
    : props.Size == "md"
    ? "240px !important;"
    : null}
height: ${(props) =>
  props.Size == "sm"
    ? "51px !important;"
    : props.Size == "md"
    ? "72px !important;"
    : null}

/* Primary/Brand

Accent and CTA
*/
background: #30bcc2 !important;
border-radius: 100px !important;
font-family: "Inter" !important;
font-style: normal !important;
font-weight: 600 !important;
transition: 0.5s !important;
font-size: ${(props) =>
  props.Size == "sm"
    ? "16px !important;"
    : props.Size == "md"
    ? "20px !important;"
    : null}
  
line-height: 19px !important;
display: flex !important;
align-items: center !important;
text-transform: capitalize !important;

&:hover{
  background:#CAFAFC !important; 
}


/* Primary/Dark */

color: #060606 !important;
@media(max-width:430px){
  width: 100% !important;
  height:51px !important;
  font-size:16px !important;
}
`;

export const ButtonWrapper = styled.div`
  display: flex;
  gap: 24px;
  width: 100%;
  @media (${LandingIpad}) {
    justify-content: center;
  }
  @media (max-width: 768px) {
    justify-content: center;
  }
  @media (max-width: 430px) {
    flex-direction: column;
  }
`;

export const ExploreButton = styled(Button)<isbuttonProps>`

width:${(props) =>
  props.Size == "sm"
    ? "159px !important;"
    : props.Size == "md"
    ? "240px !important;"
    : null}
height: ${(props) =>
  props.Size == "sm"
    ? "51px !important;"
    : props.Size == "md"
    ? "72px !important;"
    : null}

  display: flex;
  flex-direction: row;
  justify-content: center;
  transition: 0.5s !important;
  align-items: center;
  
  gap: 10px !important;
  background: #060606 !important;
  border: 1px solid #30bcc2 !important;
  border-radius: 100px !important;
  font-family: "Inter" !important;
  font-style: normal !important;
  font-weight: 600 !important;
  cursor: pointer !important;
  font-size: ${(props) =>
    props.Size == "sm"
      ? "16px !important;"
      : props.Size == "md"
      ? "20px !important;"
      : null}
  line-height: 24px !important;
  color: #30bcc2 !important;
  display: flex;
  align-items: center;
  text-transform: capitalize !important;

  &:hover {
    background: #30bcc2 !important;
    color:black!important;
  }

  @media(max-width:430px){
    
    width: 100% !important;
    height:51px !important;
    font-size:16px !important;
  }
`;

export const ListWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 120px 152px;
  gap: 10px;
  height: auto;

  /* Primary/Dark */

  background: #060606;
  @media (max-width: 768px) {
    padding: 48px 16px;
    width: 100%;
    height: 100%;
  }
  @media (${LandingIpad}) {
    padding: 64px 24px;
    gap: 10px;

    height: 100%;
  }
`;

export const ListContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0px;
  gap: 64px;

  width: 100%;
  height: auto;
  @media (max-width: 768px) {
    width: 100%;
    height: auto;
    gap: 46px;
  }
  @media (${LandingIpad}) {
    height: auto;
  }
`;
export const ListContent1 = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0px;
  gap: 32px;

  width: 751px;
  height: 286px;
  @media (max-width: 768px) {
    width: 100%;
    height: 100%;
    gap: 24px;
  }
  @media (${LandingIpad}) {
    height: 100%;
  }
  & .MuiTypography-subtitle1 {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 600;
    font-size: 48px;
    line-height: 59px;
    text-align: center;

    /* Primary/Light */

    color: #ffffff;
    @media (max-width: 768px) {
      font-size: 24px;
      line-height: 29px;
      text-align: center;
      text-transform: capitalize;

      /* Primary/Light */

      color: #ffffff;
    }
  }
  & .MuiTypography-subtitle2 {
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 24px;
    text-align: center;
    letter-spacing: 0.2px;

    /* Secondary/Light */

    color: #9eabb1;
    @media (max-width: 768px) {
      font-size: 16px;
      line-height: 24px;
      /* or 150% */

      text-align: center;

      /* Secondary/Light */

      color: #9eabb1;
    }
  }
`;
export const ListImage = styled.div`
  width: 80%;

  @media (max-width: 768px) {
    width: 100%;
    height: auto;
  }
  @media (${LandingIpad}) {
    height: auto;
  }
`;

export const DiscoverWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0px;
  gap: 10px;
  height: 920px;

  /* Secondary/Dark */

  background: #141414;
  @media (max-width: 768px) {
    width: 100%;
    height: auto;
    padding-bottom: 48px;
  }
  @media (${LandingIpad}) {
    width: 100%;
    height: auto;
    padding-bottom: 64px;
    padding-top: 64px;
  }
`;
export const DiscoverContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0px;
  gap: 56px;
  height: 680px;
  width: 100%;
  @media (max-width: 768px) {
    height: 100%;
    gap: 48px;
  }
`;
export const Discover1 = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0px;
  gap: 32px;

  width: 751px;
  height: 286px;
  @media (max-width: 768px) {
    gap: 24px;
    width: 100%;
    height: auto;
    padding: 48px 16px;

    padding-bottom: 0px;
  }
  & .MuiTypography-subtitle1 {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 600;
    font-size: 48px;
    line-height: 59px;
    text-align: center;

    /* Primary/Light */

    color: #ffffff;
    @media (max-width: 768px) {
      font-size: 24px;
      line-height: 29px;
      text-align: center;
      text-transform: capitalize;

      /* Primary/Light */

      color: #ffffff;
    }
  }
  & .MuiTypography-subtitle2 {
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 24px;
    text-align: center;
    letter-spacing: 0.2px;

    /* Secondary/Light */

    color: #9eabb1;
    @media (max-width: 768px) {
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      /* or 150% */

      text-align: center;

      /* Secondary/Light */

      color: #9eabb1;
    }
  }
`;
export const DappsWrapper = styled.div`
  height: 338px;
  width: 100%;
  gap: 10px;
  display: flex;
  flex-direction: column;
  @media (max-width: 768px) {
    height: auto;
  }
`;
export const Farme = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0px;
  gap: 14.8px;
  height: 105px;
  width: 100%;
  overflow: hidden;
  @media (max-width: 768px) {
    height: 75px;
    gap: 8.8px;
  }
`;
export const DappsDiv = styled.div`
  width: 102px;
  height: 102px;
  overflow: hidden;
  background: #ffffff;
  margin-right: 1rem;
  @media (max-width: 768px) {
    width: 72px;
    height: 72px;
  }
  border-radius: 16px;
  & > img {
    border-radius: 16px;
    margin-top: -1px;
    width: 102px;
    height: 104px;
    @media (max-width: 768px) {
      width: 72px;
      height: 74px;
    }
  }
`;

export const TrustWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 120px 152px;
  gap: 10px;
  height: 859px;
  background: #060606;
  @media (max-width: 768px) {
    padding: 48px 16px;
    width: 100%;
    height: auto;
  }
  @media (${LandingIpad}) {
    padding: 64px 24px;
    width: 100%;
    height: auto;
  }
`;
export const TrustContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0px;
  gap: 40px;

  width: 752px;
  height: 619px;
  @media (max-width: 768px) {
    gap: 24px;
    width: 100%;
    height: auto;
  }
  @media (${LandingIpad}) {
    width: 100%;
    height: auto;
  }
`;
export const TrustRoot1 = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0px;
  gap: 56px;

  width: 752px;
  height: 507px;
  @media (max-width: 768px) {
    width: 100%;
    height: auto;
    gap: 28px;
  }
  @media (${LandingIpad}) {
    width: 100%;
    height: auto;
  }
`;
export const TrustdHead = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0px;
  gap: 16px;

  width: 751px;
  height: 99px;
  @media (max-width: 768px) {
    width: 100%;
    height: auto;
    gap: 8px;
  }
  @media (${LandingIpad}) {
    width: 100%;
    height: auto;
  }
  & .MuiTypography-subtitle1 {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 600;
    font-size: 48px;
    line-height: 59px;
    /* identical to box height */

    text-align: center;

    /* Primary/Light */

    color: #ffffff;
    @media (max-width: 768px) {
      font-size: 24px;
      line-height: 29px;
      text-align: center;
      text-transform: capitalize;

      /* Primary/Light */

      color: #ffffff;
    }
  }
  & .MuiTypography-subtitle2 {
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 24px;
    text-align: center;
    letter-spacing: 0.2px;

    /* Secondary/Light */

    color: #9eabb1;
    @media (max-width: 768px) {
      font-size: 16px;
      line-height: 24px;
      /* identical to box height, or 150% */

      text-align: center;

      /* Secondary/Light */

      color: #9eabb1;
    }
  }
`;
export const PatnersWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0px;
  gap: 16px;

  width: 752px;
  height: 352px;
  flex-flow: wrap;
  @media (max-width: 768px) {
    width: 100%;
    height: 100%;
    gap: 8px;
    justify-content: center;
  }
  @media (${LandingIpad}) {
    width: 100%;
    height: 100%;
    justify-content: center;
  }
`;
export const Patner = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  padding: 32px 24px;
  gap: 10px;

  width: 368px;
  height: 168px;

  /* Tertiary/Dark */

  background: #202020;
  border-radius: 16px;
  @media (max-width: 768px) {
    width: 140px;
    height: 64px;
    padding: 8px;
  }
  @media (${LandingIpad}) {
    padding: 32px 24px;
    width: 340px;
    height: 168px;
  }
`;
export const Bnb2Bg = styled.div`
  width: 224px;
  height: 72px;

  background: transparent;
  mix-blend-mode: luminosity;
  @media (max-width: 768px) {
    width: 124px;
    height: 48px;
    display: flex;
    align-items: center;
  }
  @media (${LandingIpad}) {
    width: 189.33px;
    height: 56px;
    display: flex;
    align-items: center;
  }
`;
export const BnbBg = styled.div`
  width: 320px;
  height: 104px;

  background: transparent;
  mix-blend-mode: luminosity;
  @media (max-width: 768px) {
    width: 124px;
    height: 48px;
    display: flex;
    align-items: center;
  }
  @media (${LandingIpad}) {
    width: 292px;
    height: 104px;
    display: flex;
    align-items: center;
  }
`;
export const FaqWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 120px 152px;
  gap: 10px;

  height: auto;

  /* Primary/Dark */

  background: #060606;
  @media (max-width: 768px) {
    height: auto;
    width: 100%;
    padding: 48px 16px;
  }
  @media (${LandingIpad}) {
    padding: 64px 64px;
    width: 100%;
    height: auto;
  }
`;
export const FaqContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0px;
  gap: 56px;

  width: auto;
  height: auto;
  @media (max-width: 768px) {
    width: 100%;
    height: 100%;
    gap: 32px;
  }
`;
export const FaqHead = styled.div`
  height: 59px;

  /* Heading/H2 */

  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 48px;
  width: 751px;
  line-height: 59px;
  /* identical to box height */

  text-align: center;

  /* Primary/Light */

  color: #ffffff;
  @media (max-width: 768px) {
    width: 100%;
    height: auto;
    font-weight: 600;
    font-size: 24px;
    line-height: 29px;
    text-align: center;
    text-transform: capitalize;

    /* Primary/Light */

    color: #ffffff;
  }
`;
export const FaqCollapsContent = styled.div`
  width: 100%;
  height: auto;
  @media (max-width: 768px) {
    width: 100%;
    height: auto;
  }
  & .row-title {
    height: 102px !important;
    @media (max-width: 768px) {
      height: 56px !important;
    }
  }
  & .styles_faq-row-wrapper__3vA1D .styles_faq-row__2YF3c {
    border-bottom: 1px solid #202020 !important;
  }
  & .icon-wrapper {
    position: relative !important;
    max-height: unset !important;
    @media (max-width: 768px) {
      top: 0px !important;
    }
  }
`;
export const BlogWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 120px 148px;
  gap: 10px;
  height: auto;
  background: #060606;
  @media (max-width: 768px) {
    padding: 48px 16px;
    height: auto;
  }
  @media (${LandingIpad}) {
    height: auto;
    padding: 64px 24px;
  }
`;
export const ViewLink = styled.a`
  text-decoration: none;
  color: rgb(6, 6, 6);

  @media (max-width: 430px) {
    width: 100% !important;
  }
`;
export const BlogContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0px;
  gap: 39px;

  width: 100%;
  height: auto;
  @media (max-width: 768px) {
    width: 100%;
    height: auto;
  }
`;
export const BlogContent1 = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0px;
  gap: 52px;

  width: 100%;
  height: auto;
  @media (max-width: 768px) {
    width: 100%;
    height: auto;
    gap: 32px;
  }
`;
export const BlogContent2 = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0px;
  gap: 36px;

  width: 100%;
  height: auto;
  flex-flow: wrap;
  justify-content: center;
  @media (max-width: 768px) {
    width: 100%;
    height: auto;
    flex-flow: wrap;
    justify-content: center;
  }
  @media (${LandingIpad}) {
    justify-content: space-between;
  }
`;
export const BloagHead = styled.div`
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 48px;
  line-height: 59px;
  /* identical to box height */

  text-align: center;

  /* Primary/Light */

  color: #ffffff;
  @media (max-width: 768px) {
    font-size: 24px;
    line-height: 29px;
    text-align: center;
    text-transform: capitalize;

    /* Primary/Light */

    color: #ffffff;
  }
`;

export const BlogCard = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 16px;
  gap: 16px;
  transition: 0.5s;
  width: 368px;
  height: 380px;

  /* Tertiary/Dark */

  background: #202020;
  border-radius: 16px;

  &:hover {
    background: #141414;
  }

  @media (max-width: 768px) {
    width: 100%;
    height: auto;
  }
  @media (${LandingIpad}) {
    width: 47%;
  }
  :hover {
    cursor: pointer;
  }
`;
export const BlogImage = styled.div`
  width: 336px;
  height: 190px;

  background: transparent;
  border-radius: 8px;
  @media (max-width: 768px) {
    width: 100%;
    height: auto;
    background: transparent;
  }
  & > img {
    width: 100%;
    height: 100%;
    margin-top: -1px;
  }
  @media (${LandingIpad}) {
    width: 100%;
    & > img {
      width: 100%;
      margin-top: -1px;
    }
  }
`;
export const BlogData = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 8px;

  width: 336px;
  height: 132px;
  @media (max-width: 768px) {
    width: 100%;
    height: auto;
  }
  & .MuiTypography-subtitle1 {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 19px;
    display: flex;
    align-items: center;

    /* Secondary/Light */

    color: #9eabb1;
    @media (max-width: 768px) {
      font-size: 12px;
      line-height: 20px;
      /* identical to box height, or 167% */

      display: flex;
      align-items: center;

      /* Secondary/Light */

      color: #9eabb1;
    }
  }
`;
export const BlogTitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  padding: 0px;
  // gap: 16px;

  width: 336px;
  height: 105px;
  @media (max-width: 768px) {
    width: 100%;
    height: auto;
  }
  & .MuiTypography-subtitle2 {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 23px;

    display: flex;
    align-items: center;

    /* Primary/Light */

    color: #ffffff;
    @media (max-width: 768px) {
      font-size: 20px;
      line-height: 27px;
      display: flex;
      align-items: center;

      /* Primary/Light */

      color: #ffffff;
    }
  }
  & .MuiTypography-h6 {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
    /* identical to box height */
    position: relative;

    display: flex;
    align-items: center;

    /* Primary/Brand */

    color: #30bcc2;

    @media (max-width: 768px) {
      font-size: 14px;
      line-height: 16px;
      /* identical to box height, or 114% */

      display: flex;
      align-items: center;

      /* Primary/Brand */
    }
  }
`;

export const LearnMore = styled.a`
  text-deoration: none;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  color: #30bcc2;
  position: relative;

  &::after {
    content: "";
    position: absolute;
    width: 100%;
    height: 2px;
    bottom: 0px;
    left: 0;
    background-color: #30bcc2;
    transform-origin: bottom left;
    transition: transform 0.25s ease-out;
    transform: scaleX(0);
  }

  &:hover::after {
    transform: scaleX(1);
  }

  @media (max-width: 768px) {
    font-size: 14px;
    line-height: 16px;
    /* identical to box height, or 114% */

    display: flex;
    align-items: center;

    /* Primary/Brand */
  }
`;
export const ContactUsWrap = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: left;
  padding: 0px;
  gap: 10px;
  height: 712px;

  /* Primary/Silver */

  background: #d2e0e7;
  padding-left: 152px;
  padding-top: 120px;
  @media (max-width: 768px) {
    padding: 48px 16px;
    width: 100%;
    height: auto;
  }
  @media (${LandingIpad}) {
    padding: 48px 16px;
    width: 100%;
    height: auto;
  }
`;
export const ContactUsContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  padding: 0px;
  gap: 40px;
  width: 752px;
  height: 472px;
  @media (max-width: 768px) {
    width: 100%;
    height: auto;
  }
  @media (${LandingIpad}) {
    width: 100%;
    height: auto;
    align-items: center;
  }
`;
export const Contact1 = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 24px;

  width: 752px;
  height: 360px;
  @media (max-width: 768px) {
    width: 100%;
    height: auto;
    align-items: center;
  }
  @media (${LandingIpad}) {
    width: 100%;
    height: auto;
    align-items: center;
  }
  & .MuiTypography-subtitle1 {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 600;
    font-size: 64px;
    line-height: 78px;
    letter-spacing: 1.2px;

    color: #060606;
    @media (max-width: 768px) {
      font-weight: 600;
      font-size: 32px;
      line-height: 44px;
      text-align: center;

      color: #060606;
    }
    @media (${LandingIpad}) {
      text-align: center;
    }
  }
  & .MuiTypography-subtitle2 {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 24px;
    letter-spacing: 0.2px;

    color: #060606;
    @media (max-width: 768px) {
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      /* identical to box height, or 150% */

      text-align: center;

      color: #060606;
    }
  }
`;

export const ContactImage = styled.div`
  width: 461.19px;
  height: auto;
  display: flex;
  align-items: end;
`;
export const TextBoxWrapper = styled.div`
  display: flex;
  align-items: center;
  column-gap: 10px;
  @media (max-width: 767px) {
    flex-direction: column;
    width: 100%;
    row-gap: 20px;
  }
  @media (${LandingIpad}) {
    display: flex;
    align-items: center;
    column-gap: 10px;
  }
`;
export const SubscriberButton = styled(Button)`
  width: 240px !important;
  height: 72px !important;

  /* Primary/Dark */

  background: #060606 !important;
  border-radius: 100px !important;
  font-family: "Montserrat" !important;
  font-style: normal !important;
  font-weight: 600 !important;
  font-size: 20px !important;
  line-height: 24px !important;
  display: flex !important;
  align-items: center !important;
  text-transform: capitalize !important;
  color: #ffffff !important;
  transition: 0.5s !important;
  &:hover {
    background: #565e62 !important;
  }

  @media (max-width: 767px) {
    width: 100% !important;
  }
`;
export const CustomeTextField = styled(TextField)`
  width: 400px;
  height: 72px;
  @media (max-width: 768px) {
    width: 100%;
    height: auto;
  }
  & .MuiOutlinedInput-root {
    border: 1px solid #000000;
    border-radius: 100px;
    width: 400px;
    height: 72px;
    @media (max-width: 768px) {
      width: 100%;
      height: auto;
    }
  }
`;
export const ErrorText = styled.div`
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
  letter-spacing: 0.2px;

  /* Error */

  color: #b00000;
  margin-top: -35px;
  margin-left: 22px;
  @media (${LandingIpad}) {
    width: 100%;
    padding-left: 84px;
  }
`;
export const FooterWrapper = styled.div`
  height: 548px;
  background: #060606;
  padding-top: 120px;
  padding-left: 152px;
  padding-right: 152px;
  position: relative;

  @media (max-width: 768px) {
    padding: 48px 16px 24px;
    height: auto;
    display: flex;
    flex-direction: column;
    gap: 32px;
    align-items: center;
  }
  @media (${LandingIpad}) {
    padding: 64px 24px 40px;
    justify-content: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 32px;
    height: auto;
  }
`;

export const FooterImage = styled.img`
  position: absolute;
  bottom: 0px;
  right: 0px;
  width: 130px;
  height: 130px;
`;
export const FooterContent = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  padding: 0px;
  gap: 48px;

  width: 100%;
  height: auto;
  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
    padding: 0px;
    gap: 48px;
    width: 100%;
  }
  @media (${LandingIpad}) {
    flex-direction: column;
    align-items: center;
    padding: 0px;
    gap: 48px;
    width: 100%;
  }
`;

export const FooterData = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 24px;

  width: 224px;
  height: auto;
  margin-top: 3.5rem;
  margin-bottom: 3.5rem;
  @media (max-width: 768px) {
    width: 100%;
    align-items: center;
    margin: 0px;
  }
  @media (${LandingIpad}) {
    width: 100%;
    align-items: center;
    margin: 0px;
  }
  & .MuiTypography-subtitle1 {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 28px;
    /* identical to box height, or 140% */

    /* White */

    color: #ffffff;
  }
`;
export const LinkWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 12px;

  width: 205px;
  height: 148px;
  @media (max-width: 768px) {
    width: 100%;
    align-items: center;
    margin: 0px;
    height: auto;
  }
  @media (${LandingIpad}) {
    width: 100%;
    align-items: center;
    margin: 0px;
    height: auto;
  }
  & .MuiTypography-subtitle2 {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    cursor: pointer;
    /* identical to box height, or 125% */
    transition: 0.5s;
    color: #9eabb1;
  }
  & .MuiTypography-subtitle2:hover {
    color: #30bcc2;
  }
`;
export const FooterShareWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  padding: 0px;
  gap: 12px;

  width: 100%;
  height: 25.12px;
  @media (max-width: 768px) {
    width: 100%;
    justify-content: center;
  }
  @media (${LandingIpad}) {
    width: 100%;
    justify-content: center;
  }
`;

export const FooterBottomWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  @media (max-width: 768px) {
    gap: 16px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  @media (${LandingIpad}) {
    gap: 16px;
    flex-direction: column;
    align-items: center;
  }
`;

export const FooterBottom = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: start;

  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  /* identical to box height, or 125% */

  /* White */

  color: #ffffff;
  cursor: pointer;
  transition: 0.5s;
  &:hover {
    color: #30bcc2 !important;
  }

  @media (max-width: 768px) {
    justify-content: center;
    text-align: center;
  }
`;

export const HelloWrap = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 16px 152px;
  position: sticky;
  top: 0;
  z-index: 999;
  gap: 8px;

  width: 100%;
  height: auto;
  background: #9eabb1;
  @media (max-width: 768px) {
    padding: 16px 16px;
  }
`;
export const TitleHello = styled.span`
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  font-family: "Montserrat";
  /* Primary/Dark */

  color: #060606;
`;
export const LinkHello = styled.div`
  font-style: normal;
  font-family: "Montserrat";
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  text-decoration-line: underline;

  /* Primary/Dark */

  color: #060606;
`;
