import { configureStore } from "@reduxjs/toolkit";
import { save, load } from "redux-localstorage-simple";
import {
  application,
  user,
  messages,
  spamProtection,
  inputMessage,
  all_dapps,
} from "./reducer";

import transactionSlice from "./transactionSlice";

const PERSISTED_KEYS: string[] = [
  "user",
  "lists",
  "messages",
  "spamProtection",
  "inputMessage",
  "all_dapps",
];
const store = configureStore({
  reducer: {
    application,
    user,
    messages,
    spamProtection,
    inputMessage,
    all_dapps,
    transactionSlice,
  },
  middleware: [save({ states: PERSISTED_KEYS, namespace: "testnet" })],
  preloadedState: load({
    states: PERSISTED_KEYS,
    namespace: "testnet",
  }),
  devTools: true,
});

export type AppState = ReturnType<typeof store.getState>;

export type AppDispatch = typeof store.dispatch;

export default store;
