import walletRegistry from "./wallet_registry_abis/WalletRegistry.json";

// eslint-disable-next-line no-undef
const environment = process.env.REACT_APP_ENVIRONMENT;

const get_walletRegistryABI = (environment) => {
  switch (environment) {
    case "DAPPS_NEW_PRODUCTION":
      return walletRegistry;

    default:
      return walletRegistry;
  }
};
const walletRegistryABI = get_walletRegistryABI(environment);

export default walletRegistryABI;
