import React, { useEffect } from "react";
import { GrapesjsReact } from "grapesjs-react";
import "grapesjs-preset-newsletter";
import "grapesjs-plugin-forms";
import thePlugin from "grapesjs-plugin-export";
import pluginTooltip from "grapesjs-tooltip";
import "grapesjs/dist/css/grapes.min.css";
import "grapesjs/dist/grapes.min.js";
const PageBuilder = () => {
  return (
    <div style={{ marginTop: "6rem" }}>
      <GrapesjsReact
        id="grapesjs-react"
        plugins={[
          "gjs-preset-newsletter",
          "gjs-blocks-basic",
          "grapesjs-plugin-export",
          (editor) => thePlugin(editor, {}),
          (editor) =>
            pluginTooltip(editor, {
              /* options */
            }),
        ]}
        //   onInit={(editor) => {
        //     window.editor = editor;
        //   }}
      />
    </div>
  );
};
export default PageBuilder;
