import { getGraphqlClient } from "utilities/graphql";
import { FROM_MESSAGE_QUERY, TO_MESSAGE_QUERY } from "utilities/graphql/query";
import { Message } from "types/message";
import _ from "lodash";

export const fetchMessages = async (
  chainId: number,
  fromAddress: string
): Promise<Message[]> => {
  const client = getGraphqlClient(parseInt(String(chainId)));
  const fromData = client.query({
    query: FROM_MESSAGE_QUERY,
    variables: {
      from: fromAddress,
    },
  });

  const toData = client.query({
    query: TO_MESSAGE_QUERY,
    variables: {
      to: fromAddress,
    },
  });

  const [fromResponse, toResponse] = await Promise.all([fromData, toData]).then(
    (value) => value
  );

  const concatMessages = _.concat(
    fromResponse?.data?.messageSents,
    toResponse?.data?.messageSents
  ) as Message[];

  return concatMessages;
};
