import { createAction } from "@reduxjs/toolkit";
import { Dapps, PopUpTypes, SpamProtection, UserInputMessage } from "./reducer";
import { ActionScreenInterface, SearchResult } from "./reducer";
import { Message } from "types/message";

export const setPopUpOpen = createAction<{ openPopUp: PopUpTypes }>(
  "app/setPopUpOpen"
);

export const setPopUpClose = createAction<{ openPopUp: null }>(
  "app/setPopUpClose"
);

export const setActionScreenType = createAction<{
  actionScreen: ActionScreenInterface;
}>("app/setActionScreenType");

export const setAppChainId = createAction<{ appChainId: number }>(
  "user/setAppChainId"
);

export const addRecentSearch = createAction<{ searchResult: SearchResult }>(
  "user/setAppChainId"
);

export const setOneToOneMessage = createAction<Message[]>(
  "message/setOneToOneMessage"
);

export const updateOneToOneSentMessage = createAction<Message[]>(
  "message/updateOneToOneMessage"
);

export const setSpamProtection = createAction<{
  spamProtection: SpamProtection;
}>("spamProtection/setSpamProtection");

export const setUserInputMessage = createAction<{
  userInputs: UserInputMessage;
}>("message/userInput");

export const setDappsData = createAction<{
  dappsList: Dapps[];
}>("dapps/allDapps");
